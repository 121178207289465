import React, { useState } from 'react';
import { Container, Navbar } from 'react-bootstrap';
import './../../Style/Navbar.css';
import { useNavigate } from 'react-router-dom';
import { navbarDataType, useUserNavbarData, useUserStore } from '../../Store/GlobalState';
import { MdMenu } from 'react-icons/md';
import { FaAngleDown, FaRegBookmark } from "react-icons/fa";
import { MdOutlineClose } from "react-icons/md";

export interface categoriesType {
  slug: string,
  name: string,
  image: string,
  subcategory: {
    slug: string,
    image: string,
    name: string
  }[]
}
export default function MyNavbar() {

  const navbarData: navbarDataType = useUserNavbarData((state: any) => state.navbarData);

  const [selectCategory, setSelectCategory] = useState<{ slug: string, name: string } | null>(null);
  const [isMobile, showSideBar, setShowSideBar] = useUserStore((state: any) => [state.isMobile, state.showSideBar, state.setShowSideBar]);
  const [showMenu, setShowMenu] = useState(false);

  const handleMouseOver = (category: { slug: string, name: string }) => {
    const div = document.getElementById(`nav-categpry-${category.slug}`);
    if (div) div.style.display = 'block';
    setSelectCategory(category);
  };

  const handleMouseOut = () => {
    const div = document.getElementById(`nav-categpry-${selectCategory?.slug}`);
    if (div) div.style.display = 'none';
    setSelectCategory(null);
  };

  const navigate = useNavigate();

  const HandleMenu = () => {
    if (showMenu) {
      setShowMenu(false);
      setTimeout(() => {
        const div = document.getElementById('nav-menu');
        if (div) {
          div.style.display = 'none';
        }
      }, 450);
    } else {
      setShowMenu(true);
      const div = document.getElementById('nav-menu');
      if (div) {
        div.style.display = 'block';
      }
    }
  }

  return (
    <>
      <Navbar style={{ height: "75px", backgroundColor: 'var(--primary-main)' }} className="shadow-sm w-100" >
        <Container>

          <div className="d-flex justify-content-center align-items-center w-100">

            {isMobile ?
              !showSideBar ? <button className='bg-transparent border-0' style={{ fontSize: '24px' }} type="button" onClick={() => setShowSideBar(true)}> <MdMenu /></button>
                :
                <button className='bg-transparent border-0' style={{ fontSize: '24px' }} type="button" onClick={() => setShowSideBar(false)}><MdOutlineClose /></button>
              : null}

            {/* Logo */}
            {
              navbarData?.logo && navbarData?.logo?.type === 'image' ? <img loading='lazy'
                className='cursor-pointer'
                src={navbarData?.logo?.url}
                onClick={() => navigate('/')}
                alt="app-logo"
                style={{ width: "50px", height: "50px" }}
              /> : null
            }
            {
              navbarData?.logo && navbarData?.logo?.type === 'text' ? <div className='logo-box'>
                <p className='cursor-pointer logo-text' onClick={() => navigate('/')} >{navbarData?.logo?.name}</p> </div> : null
            }

            {/* Category */}
            {!isMobile && <ul className='d-flex align-items-center mb-0' style={{ listStyleType: 'none', fontSize: "16px" }}>
              {
                navbarData?.menu ? navbarData?.menu.map((item, i) => {
                  if (i >= 4) return (<React.Fragment key={i}></React.Fragment>)
                  return (<li className='mx-3' key={i} onMouseOver={() => handleMouseOver({ slug: item.slug, name: item.name })} onMouseOut={handleMouseOut} >
                    <p className="m-0 nav-text nav-tabs-name underline-animation text-dark first-letter-capital d-inline-block" 
                    onClick={() => navigate(item?.type === "page" ? `/page/${item?.slug}`:`/post/list/category?slug=${item.slug}&type=${item.type === "sub-category" ? "sub_category" : item.type}&category_name=${item.name}`)}>{item.name}</p>

                    {item?.category?.subcategory && item?.category?.subcategory[0] ? <>
                      <FaAngleDown className='ml-1 d-inline-block' />
                      <div className='bg-transparent p-2' style={{ position: 'absolute', zIndex: 999, transform: 'translate(-5%, 0px)', width: '600px' }}>
                        <div id={`nav-categpry-${item.slug}`} className='shadow rounded bg-white my-2' style={{ display: 'none', animation: "downToUp 0.2s ease-in" }}>
                          <div className='row p-2 m-2 py-3'>

                            <div className='col-4 p-2'>
                              <div className='font-weight-bold w-100 text-dark first-letter-capital d-flex justify-content-between' style={{ borderBottom: '1px solid var(--gray)', color: 'var(--dark-gray)' }}>
                                <label className='first-letter-capital'>{item?.name}</label>
                              </div>
                              <div className='w-100 shadow my-2' style={{ height: '150px', borderRadius: '16px' }}>
                                <img loading='lazy' className='w-100 h-100' style={{ borderRadius: '16px' }} src={`${process.env.REACT_APP_CATEGORY_URL_SM}/${item?.category?.image}`} alt={item?.name} />
                              </div>
                            </div>

                            <div className='col-8 p-2'>
                              <div className='font-weight-bold w-100 text-dark first-letter-capital d-flex justify-content-between' style={{ borderBottom: '1px solid var(--gray)', color: 'var(--dark-gray)' }}>
                                <label>Sub Categories</label>
                              </div>
                              {item?.category?.subcategory.map((subCat, i) => <div className='row my-2' key={i}>
                                <div className='col-6'><p className='mb-1 cursor-pointer' onClick={() => navigate(`/post/list/category?slug=${subCat?.slug}&type=sub_category&category_name=${subCat?.name}`)}>{subCat?.name}</p></div>
                              </div>)}
                            </div>
                          </div>
                        </div>
                      </div>
                    </> : <></>}
                  </li>)
                })
                  :
                  null
              }
              <li>
                <p className="m-0 mx-2 nav-text nav-tabs-name underline-animation text-dark first-letter-capital" onClick={() => navigate(`bookmark`)}><FaRegBookmark className='d-inline-block' /> Bookmarks</p>
              </li>
              <li>
                <p className="m-0 mx-2 nav-text nav-tabs-name underline-animation text-dark first-letter-capital" onClick={() => navigate(`/story/list`)}>Stories</p>
              </li>
            </ul>}

            <div style={{ flexGrow: 1 }}></div>

            {/* Search Bar */}
            <div className='border-0 p-2 mx-3' style={{ backgroundColor: 'var(--gray)', fontSize: '20px', borderRadius: '8px', maxWidth: '200px' }} onClick={HandleMenu}>
              <input placeholder='Search here' type='text' className='mx-1  bg-transparent' style={{ width: '80%', border: 'none', outline: 'none', color: 'var(--dark-gray)' }} />
              <i className="fas fa-search d-sm-inline-block d-none" style={{ color: 'var(--dark-gray)' }} />
            </div>

          </div>
        </Container>
      </Navbar>
      <Container style={{ position: 'relative' }}>

      </Container>
    </>
  );
}
