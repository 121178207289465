import React, { useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';
import { useQuery } from 'react-query';
import { UserStoryListApi } from '../../Api/User';
import useGlobalLoader, { useUserStore } from '../../Store/GlobalState';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { MdOutlineSearchOff } from "react-icons/md";
import { Container } from 'react-bootstrap';
import PostListView2 from '../Components/PostListView2';

export interface storyDataType {
    name: string,
    created_at: string,
    slug: string,
    image: string,
}
interface storyType {
    total_page: number,
    story: storyDataType[]
}
export default function UserStoryList() {
    const navigate = useNavigate();
    const search = useUserStore((state: any) => state.search);
    const setIsLoading = useGlobalLoader((state: any) => state.setIsLoading);
    const [page, setPage] = useState<number>(1);
    const [storyData, setStoryData] = useState<storyType>();
    const [searchParams] = useSearchParams();
    const category_id = searchParams.get('category_id');
    const sub_category_id = searchParams.get('sub_category_id')
    const { refetch } = useQuery({
        queryKey: ['user-story-list', search, page, 12, category_id, sub_category_id],
        queryFn: UserStoryListApi,
        onSuccess: (resp) => {
            setIsLoading(false);
            setStoryData(resp?.data);
            if (resp.data.total_page < page) {
                setPage(1);
            }
        },
        onError: () => setIsLoading(false),
        enabled: false
    });

    useEffect(() => {
        refetch();
        setIsLoading(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [category_id, sub_category_id]);


    return (
        <Container>
            <div className='mt-3'>
                <div className='px-2 mx-2 mb-3' style={{ borderWidth: '0px 4px', borderColor: 'red', borderStyle: 'solid', transform: 'skew(-25deg)', width: 'fit-content', display: 'inline-block' }}>
                    <p className='mb-0' style={{ transform: 'skew(25deg', fontSize: '18px' }}>All Story</p>
                </div>
            </div>
            {storyData?.story[0] ? <div className='row'>
                {
                    storyData?.story?.map((item, i) => (
                        <div className='col-md-3 col-sm-6 col-12 mb-2 p-2' key={i}>
                            <PostListView2 data={{ slug: item?.slug, image: item.image, title: item?.name, description: '', date: item?.created_at, category: 'Technology' }} onClick={() => navigate(`/story/view/${item.slug}`)} />
                        </div>
                    ))

                }
            </div>
                : storyData && (
                    <div className='d-flex justify-content-center align-items-center flex-column' >
                        <MdOutlineSearchOff style={{ fontSize: '8vw' }} />
                        <span style={{ fontSize: '5vw' }}>No Data Found</span>
                    </div>
                )
            }
            <div className='my-2 bottom-0'>
                {storyData?.total_page ?
                    <ReactPaginate
                        previousLabel={"<<"}
                        nextLabel={">>"}
                        breakLabel={"..."}
                        pageCount={storyData?.total_page}
                        marginPagesDisplayed={1}
                        pageRangeDisplayed={1}
                        forcePage={page - 1}
                        onPageChange={(pageClicked) => {
                            setPage(pageClicked.selected + 1);
                        }}
                        containerClassName={"pagination justify-content-center"}
                        pageClassName={"page-item mx-2"}
                        pageLinkClassName={"page-link"}
                        previousClassName={"page-item"}
                        previousLinkClassName={"page-link"}
                        nextClassName={"page-item"}
                        nextLinkClassName={"page-link"}
                        breakClassName={"page-item"}
                        breakLinkClassName={"page-link"}
                        activeClassName={"active"}
                    /> : null}
            </div>
        </Container>
    );
}
