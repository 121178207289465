import React, { useEffect, useState } from 'react';
import { FormateDate } from '../../Helper';
import Select, { components } from 'react-select';
import { useMutation, useQuery } from 'react-query';
import { GetSelectedEditorPickApi, PostDropDownListApi, UpdateEditorPick } from '../../Api/Admin';
import useGlobalLoader from '../../Store/GlobalState';
import { toast } from 'sonner';

export default function EditorPicks() {
  const [selectedOption, setSelectedOption] = useState<any[]>([]);
  const [option, setOption] = useState<{ value: number, label: any }[]>();
  const [search, setSearch] = useState<string>("");
  const [limit, setLimit] = useState<number>(10);
  const [totalPage, setTotalPage] = useState<number>(1);
  const setIsLoading = useGlobalLoader((state: any) => state.setIsLoading);

  const { mutate } = useMutation(UpdateEditorPick, {
    mutationKey: "update-editor-picks",
    onSuccess: (data: any) => {
      setIsLoading(false);
      toast.success(data.data.message);
    },
    onError: (data: any) => {
      setIsLoading(false);
      toast.error(data.response.data.message);
    },
  });

  const { refetch, isFetching } = useQuery({
    queryKey: ["post-dropdown-list", search, 1, limit],
    queryFn: PostDropDownListApi,
    onSuccess: (resp) => {
      setTotalPage(resp?.data?.total_page);
      setOption(resp?.data?.post);
    },
    enabled: false
  });

  const query = useQuery({
    queryKey: ["post-editor-selected-list", search, 1, limit],
    queryFn: GetSelectedEditorPickApi,
    onSuccess: (resp) => {
      setIsLoading(false);
      setSelectedOption(resp?.data?.editor_pick);
    },
    enabled: false
  })

  useEffect(() => {
    setIsLoading(true);
    query.refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setOption([]);
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limit, search]);

  const CustomMenu = ({ children, ...props }: any) => {
    return <components.Menu {...props}>
      <div className='p-2' style={{ maxHeight: '300px', overflowY: 'scroll' }}>
        {
          props?.options[0] ? props?.options?.map((item: any, i: number) => {
            if (selectedOption.find(items => items.id === item.id)) return (<React.Fragment key={i}></React.Fragment>)
            return (<div className='d-flex w-100 my-3 cursor-pointer post-option' style={{ height: '50px' }} key={i} onClick={() => setSelectedOption([...selectedOption, item])}>
              <img className='mr-3' src={`${process.env.REACT_APP_POST_URL_SM}/${item.image}`} style={{ borderRadius: '12px', height: '50px', width: '50px' }} alt="Options" />
              <div className='d-flex flex-column text-truncate'>
                <span className="text-truncate">{item?.heading}</span>
                <span className="text-break">{FormateDate(item?.created_at)}</span>
              </div>
            </div>
            )
          }) :
            isFetching ? (
              <center>
                <i
                  className=" my-2 fa fa-spinner fa-spin"
                  style={{ fontSize: "30px", color: "black" }}
                ></i>
              </center>
            ) : (<div>
              <center>
                <h4>Not Found</h4>
              </center>
            </div>)
        }
        {totalPage > 1 ? <button className='btn btn-secondary w-100' onClick={() => setLimit(limit + 10)}>Load more</button> : null}
      </div>
    </components.Menu>;
  };

  return (
    <div className="content-wrapper">
      <section className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col">
              <h1>Editor's Pick</h1>
            </div>
          </div>
        </div>
      </section>
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <div className='mb-3'>
                    <div className='d-flex justify-content-between flex-wrap'>
                      <p className='font-weight-bold'>Add Top 5 Editor's pick</p>
                    </div>
                    <div className='row mt-1 mb-3'>
                      <div className='col-lg-6 col-12'>
                        <div className="form-group my-1">
                          {!query.isFetching && <Select
                            styles={{
                              option: (styles) => {
                                return {
                                  ...styles,
                                  backgroundColor: 'white',
                                  color: '#000',
                                  ':hover': {
                                    backgroundColor: '#f5f5f5',
                                  },
                                };
                              },
                              menu: provided => ({
                                ...provided,
                                zIndex: 9999,
                              })
                            }}
                            components={{ Menu: CustomMenu }}
                            value={selectedOption}
                            options={option}
                            isSearchable={true}
                            getOptionLabel={(option) => option?.heading}
                            getOptionValue={(option) => option?.id}
                            inputValue={search}
                            onChange={(e: any) => setSelectedOption(e)}
                            isMulti
                            onInputChange={(search) => setSearch(search)}
                            placeholder="Select Post" />}
                        </div>
                        {selectedOption.length > 5 ? (
                          <div className="mb-2 mx-1 text-danger">You can select only 5 post</div>
                        ) : <></>}
                      </div>
                    </div>
                    <div className="my-2">
                      <button type="submit" className="btn btn-primary" onClick={() => {
                        if (selectedOption.length <= 5) {
                          setIsLoading(true);
                          mutate({ select_post: JSON.stringify(selectedOption.map((item: any) => item.slug)) });
                        }
                      }}>
                        Save Changes
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
