import axios from "axios";

const getToken = () => {
    const token = localStorage.getItem('user_token');
    return token
}

const onRequest = (config:any) => {
    const access_token = getToken();
    if (access_token) {
        config.headers["token"] = access_token;
    }
    return config;
};

const onRequestError = (error:any) => {
    return Promise.reject(error);
};

const onResponse = (response:any) => {
    return response;
};

const onResponseError = async (error:any) => {
    if (error.response) {
        if (error.response.status === 401) {
            localStorage.removeItem("user_token");
            window.location.replace('/');
        }
    }
    return Promise.reject(error);
};
const setupInterceptorsTo = (axiosInstance:any) => {
    axiosInstance.interceptors.request.use(onRequest, onRequestError);
    axiosInstance.interceptors.response.use(onResponse, onResponseError);
    return axiosInstance;
};

const HttpService = setupInterceptorsTo(
    axios.create({
      baseURL: process.env.REACT_APP_BASE_URL,
    })
  );
  
  export default HttpService;
  