import React from "react";
import useGlobalLoader from "../Store/GlobalState";

export default function GlobalLoader() {
  const isLoading = useGlobalLoader((state: any) => state.isLoading);

  return (
    <>
      {isLoading && (
        <div className="spinner">
          <div className="spinner-container">
            <div className="spinner-loader"></div>
          </div>
        </div>
      )}
    </>
  );
}
