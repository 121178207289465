import React from 'react';

interface propsType {
    value: string,
    setValue: (e: any) => void
}
export default function SlugInput({ value, setValue }: propsType) {
    return (
        <div className="form-group my-1">
            <label htmlFor="name">Add Slug</label>
            <input
                type="text"
                value={value}
                onChange={setValue}
                className="form-control"
                id="slug"
                placeholder="Add Slug"
            />
        </div>
    );
}
