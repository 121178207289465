import React, { useState, useEffect } from 'react';
import { useMutation, useQuery } from 'react-query';
import { QuickLinksApi, UserPagesList, UserSignup } from '../../Api/User';
import { Container } from 'react-bootstrap';
import { FaFacebookF, FaInstagram, FaYoutube } from 'react-icons/fa';
import { FaXTwitter } from 'react-icons/fa6';
import { useNavigate } from 'react-router-dom';
import useGlobalLoader, { navbarDataType, useUserNavbarData } from '../../Store/GlobalState';
import { useFormik } from 'formik';
import * as Yup from "yup";
import { toast } from 'sonner';

interface pagesListType {
  slug: string,
  name: string
}
export default function Footer() {
  const [pages, setPages] = useState<pagesListType[]>();
  const [links, setLinks] = useState<{ name: string, link: string }[]>();
  const navbarData: navbarDataType = useUserNavbarData((state: any) => state.navbarData);
  const setIsLoading = useGlobalLoader((state: any) => state.setIsLoading);
  const navigate = useNavigate();

  const { refetch } = useQuery({
    queryKey: ['user-pages'],
    queryFn: UserPagesList,
    onSuccess: (resp) => setPages(resp?.data?.pages),
    enabled: false
  });

  const quickLinks = useQuery({
    queryKey: ['quick-links'],
    queryFn: QuickLinksApi,
    onSuccess: (resp) => setLinks(resp?.data?.quickLinks),
    enabled: false
  });

  const { mutate } = useMutation(UserSignup, {
    mutationKey: "media-add",
    onSuccess: (data: any) => {
      toast.success(data.data.message);
      setIsLoading(false);
      resetForm();
    },
    onError: (data: any) => {
      setIsLoading(false);
      toast.error(data.response.data.message);
    },
  })

  const { handleSubmit, values, errors, handleChange, touched, resetForm } = useFormik({
    initialValues: {
      email: '',
      policy: true
    },
    validationSchema: Yup.object({
      email: Yup.string().email("Enter Valid E-mail.").required("E-mail is Required."),
      policy: Yup.boolean().required("Click On terms & conditions."),
    }),
    onSubmit: (data) => {
      setIsLoading(true);
      mutate({ email: data.email })
    }
  })

  useEffect(() => {
    refetch();
    quickLinks.refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <footer className="my-footer flex-column pt-3" style={{ backgroundColor: 'var(--primary-gray)' }}>
      <Container>
        <div className='row mt-3 my-2'>
          <div className='col-lg-3 col-12 my-2' style={{ fontSize: '16px' }}>

          </div>
          <div className='col-lg-5 col-md-6 col-12 my-2'>
            <div className='row my-2'>
              <div className='col-6 col-lg-4'>
                <div className='d-flex flex-column'>
                  <h5 style={{ color: 'var(--primary-dark)' }}>Quick Link</h5>
                  {links ? links.map((item, i) => (<p className='mb-1 underline-animation' onClick={() => window.open(item?.link)} style={{ fontSize: '14px', width: 'fit-content' }} key={i}>{item.name}</p>)) : null}
                </div>
              </div>
              <div className='col-6 col-lg-4'>
                <div className='d-flex flex-column'>
                  <h5 style={{ color: 'var(--primary-dark)' }}>Top Categories</h5>
                  {navbarData?.menu ? navbarData?.menu?.map((item, i) => (<p className='mb-1 underline-animation' style={{ fontSize: '14px', width: 'fit-content' }} key={i} onClick={() => navigate(`/post/list/category?slug=${item?.slug}&type=catgeory`)}>{item?.name}</p>)) : null}
                </div>
              </div>
            </div>
          </div>
          <div className='col-lg-4 col-md-6 col-12 mb-4'>
            <form onSubmit={handleSubmit}>
              <div className='m-2'>
                <div className='d-flex align-self-center flex-column'>
                  <h5 style={{ color: 'var(--primary-dark)' }}>Sign up</h5>
                  <label className='mb-0' style={{ fontSize: '14px', color: 'var(--dark-gray)' }}>Subscribe to our website to get our newest post instantly!</label>
                </div>
                <div className='my-2'>
                  <div className='row align-items-center mt-2 mt-lg-0 justify-content-center h-100'>
                    <div className='col-12 col-lg-8 px-2'>
                      <input type='text' className='w-100 p-1' value={values.email} onChange={handleChange} name='email' id='email' style={{ borderRadius: '4px', border: 'none', outline: 'none' }} placeholder='Enter E-mail' />
                    </div>
                    <div className='col-12 col-lg-4 px-2 mt-2 mt-lg-0'>
                      <button className='w-100 btn btn-danger' type='submit'>Sign up</button>
                    </div>
                    <div className='col-12 px-2 d-flex'>
                      <div className="custom-control custom-checkbox">
                        <input className="custom-control-input custom-control-input-danger " type="checkbox" id="checkbox-1" checked={values.policy} name='policy' onChange={handleChange} />
                        <label className="custom-control-label" htmlFor="checkbox-1" style={{ color: 'var(--dark-gray)' }}>I have read and agree to the terms & conditions</label>
                      </div>
                    </div>
                    {touched.email && errors.email ? (
                      <div className="col-12 mb-2 mx-1 text-danger">
                        {errors.email}
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div className='d-flex justify-content-between my-2 flex-wrap w-100'>
          {/* Logo */}
          {
            navbarData?.logo && navbarData?.logo?.type === 'image' ? <img loading='lazy'
              className='cursor-pointer'
              src={navbarData?.logo?.url}
              onClick={() => navigate('/')}
              alt="app-logo"
              style={{ width: "50px", height: "50px" }}
            /> : null
          }
          {
            navbarData?.logo && navbarData?.logo?.type === 'text' ? <div className='logo-box'>
              <p className='cursor-pointer logo-text' onClick={() => navigate('/')} >{navbarData?.logo?.name}</p> </div> : null
          }
          <div className='d-flex flex-wrap justify-content-center flex-nowrap align-items-center'>
            <label className='font-weight-bold mr-2 mb-0'>Follow us</label>
            <FaInstagram className='mr-2 cursor-pointer hover-with-effect' style={{ fontSize: '20px' }} />
            <FaFacebookF className='mr-2 cursor-pointer hover-with-effect' style={{ fontSize: '20px' }} />
            <FaXTwitter className='mr-2 cursor-pointer hover-with-effect' style={{ fontSize: '20px' }} />
            <FaYoutube className='mr-2 cursor-pointer hover-with-effect' style={{ fontSize: '20px' }} />
          </div>
        </div>
        <div className='row w-100 my-2' style={{ borderTop: '1px solid black' }}>
          <div className='col-12 col-md-4 order-1 order-md-0 d-flex flex-wrap justify-content-center justify-content-md-start' style={{ fontSize: '14px' }}>
            <p className='mb-0 mx-2'>Copyright © 2024</p>
          </div>
          <div className='col-12 col-md-8 order-0 order-md-1 d-flex justify-content-md-end justify-content-center flex-wrap'>
            {pages ? pages.map((item, i) => (<p className='mx-2 underline-animation' style={{ fontSize: '14px', width: 'fit-content' }} onClick={() => navigate(`page/${item?.slug}`)} key={i}>{item.name}</p>)) : null}
          </div>
        </div>
      </Container>
    </footer >
  );
}
