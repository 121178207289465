import React from 'react';
import { Modal } from 'react-bootstrap';
import { AiOutlineClose } from 'react-icons/ai';

interface ImageModelType {
    show?: boolean,
    setShow: (show: boolean) => void,
    image: string
}

export default function ImageModal({ show, setShow, image }: ImageModelType) {
    return (
        <Modal show={show} size='lg' contentClassName='imageModal' aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Body className='d-flex'>
                <div style={{ width: "100%", height: "100%" }}>
                    <img style={{ width: "100%", height: "100%", objectFit: 'cover', maxHeight: '90vh', maxWidth: '90vw' }} src={image} alt="img" onError={()=>setShow(false)}/>
                </div>
                <div>
                    <AiOutlineClose size={30} onClick={() => setShow(false)} style={{ cursor: 'pointer' }} />
                </div>
            </Modal.Body>
        </Modal>
    );
}
