import React, { useState } from "react";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { useMutation, useQuery } from "react-query";
import { toast } from "sonner";
import { PostSchema } from "../../../Validation";
import useGlobalLoader from "../../../Store/GlobalState";
import { CategoryListApi, MediaListApi, PostAddApi, SubCategoryListApi } from "../../../Api/Admin";
import CustomEditor from "../../../Components/CkEditor";
import Select from 'react-select';
import LoadmoreButton from "../../../Components/ReactSelectLoadmore";
import { IoIosCloseCircleOutline } from "react-icons/io";
import { FileUploader } from "react-drag-drop-files";
import SlugInput from "../../../Components/SlugInput";
import { Modal } from "react-bootstrap";
import { mediaDataType } from "../Media";

export const SelectCategory = ({ value, setFieldValue }: {
    value: number | undefined,
    setFieldValue: (key: string, value: number) => void
}) => {

    const [option, setOption] = useState<{ value: number, label: any }[]>();
    const [search, setSearch] = useState<string>("");
    const [limit, setLimit] = useState<number>(10);
    const [totalPage, setTotalPage] = useState<number>(1);

    useQuery({
        queryKey: ["category-list", search, 1, limit],
        queryFn: CategoryListApi,
        onSuccess: (resp) => {
            setTotalPage(resp?.data?.total_page);
            const data = resp?.data?.categories?.map((item: any) => ({ value: item.id, label: item.name }))
            setOption(data);
        },
    });

    return (
        <Select
            styles={{
                option: (styles) => {
                    return {
                        ...styles,
                        backgroundColor: 'white',
                        color: '#000',
                        ':hover': {
                            backgroundColor: '#f5f5f5',
                        },
                    };
                },
                menu: provided => ({
                    ...provided,
                    zIndex: 9999,
                })
            }}
            components={{ MenuList: (props) => LoadmoreButton({ ...props, setLimit, limit, totalPage }) }}
            value={option?.find(item => item.value === value)}
            options={option}
            isSearchable={true}
            inputValue={search}
            onChange={(e: any) => {
                setFieldValue('category_id', e.value)
            }}
            onInputChange={(search) => setSearch(search)}
            placeholder="Select Category" />
    )
}

export const SelectSubCategory = ({ value, setFieldValue }: {
    value: number | undefined,
    setFieldValue: (key: string, value: number) => void
}) => {

    const [option, setOption] = useState<{ value: number, label: any }[]>();
    const [search, setSearch] = useState<string>("");
    const [limit, setLimit] = useState<number>(10);
    const [totalPage, setTotalPage] = useState<number>(1);

    useQuery({
        queryKey: ["subcategory-list", search, 1, limit],
        queryFn: SubCategoryListApi,
        onSuccess: (resp) => {
            setTotalPage(resp?.data?.total_page);
            const data = resp?.data?.sub_categories?.map((item: any) => ({ value: item.id, label: item.name }))
            setOption(data);
        },
    });

    return (
        <Select
            styles={{
                option: (styles) => {
                    return {
                        ...styles,
                        backgroundColor: 'white',
                        color: '#000',
                        ':hover': {
                            backgroundColor: '#f5f5f5',
                        },
                    };
                },
                menu: provided => ({
                    ...provided,
                    zIndex: 9999,
                })
            }}
            components={{ MenuList: (props) => LoadmoreButton({ ...props, setLimit, limit, totalPage }) }}
            value={option?.find(item => item.value === value)}
            options={option}
            isSearchable={true}
            inputValue={search}
            onChange={(e: any) => {
                setFieldValue('sub_category_id', e.value)
            }}
            onInputChange={(search) => setSearch(search)}
            placeholder="Select Sub category" />
    )
}

export default function PostAdd() {
    const navigate = useNavigate();
    const setIsLoading = useGlobalLoader((state: any) => state.setIsLoading);
    const [image, setImage] = useState<File | null>(null);
    const [showModal, setShowModal] = useState(false);
    const [media, setMedia] = useState<{ media_count: number, media: mediaDataType[], total_page: number } | null>();

    const { mutate } = useMutation(PostAddApi, {
        mutationKey: "post-add",
        onSuccess: (data: any) => {
            setIsLoading(false);
            toast.success(data.data.message);
            navigate("/admin/post/list");
        },
        onError: (data: any) => {
            setIsLoading(false);
            toast.error(data.response.data.message);
        },
    });
    const { handleSubmit, handleChange, errors, values, touched, setFieldValue } = useFormik({
        initialValues: {
            isImage: true,
            heading: "",
            description: "",
            image: null,
            status: 1,
            category_id: undefined,
            sub_category_id: undefined,
            meta_title: "",
            meta_description: "",
            meta_keyword: "",
            meta_link: "",
            short_description: "",
            slug: ""
        },
        enableReinitialize: true,
        validationSchema: PostSchema,
        onSubmit: (data) => {
            setIsLoading(true);
            mutate({
                slug: data?.slug,
                image: data.image,
                heading: data.heading,
                description: data.description,
                status: data.status,
                category_id: data.category_id!,
                sub_category_id: data.sub_category_id!,
                meta_title: data.meta_title,
                meta_description: data.meta_description,
                meta_keyword: data.meta_keyword,
                meta_link: data.meta_link,
                short_description: data.short_description
            });
        },
    });

    const AddImage = (url: string, type:string, file:mediaDataType) => {
        let description = values.description;
        if(type === "Image") description += `<figure class="image"><img src="${url}"></figure>`;
        if(type === "Video") description += `<div class="raw-html-embed"><video class="w-100 h-100" controls>
        <source class="w-100 h-100" src="${url}" type="${file.type}" />
    </video></div>`
        setFieldValue("description", description);
        setShowModal(false);
    }

    const query = useQuery({
        queryKey: ["media-list"],
        queryFn: MediaListApi,
        onSuccess: (resp) => {
            setIsLoading(false);
            setMedia(resp?.data);
        },
        onError: () => setIsLoading(false),
    });

    return (
        <div className="content-wrapper">
            <Modal onHide={() => setShowModal(false)} show={showModal} size="xl" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Select Media
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="d-flex flex-wrap">
                        {media && media?.media.map((file, i) => {
                            if (file.type.split("/")[0] === 'video') return (<div className='showImages mr-3 my-3 cursor-pointer' key={i} onClick={()=>AddImage(file.media, "Video",file)}>
                                <video className="w-100 h-100" controls>
                                    <source className="w-100 h-100" src={file.media} type={file.type} />
                                </video>
                            </div>)
                            if (file.type.split("/")[0] === 'image') return (<div className='showImages mr-3 my-3 cursor-pointer' key={i} onClick={()=>AddImage(file.media, "Image",file)}>
                                <img style={{ width: "100%", height: "100%" }} src={file.media} alt="Product img" />
                            </div>)
                            return (<React.Fragment key={i}></React.Fragment>)
                        })}
                    </div>
                    {query.isFetching ? (
                        <center>
                            <i
                                className=" my-2 fa fa-spinner fa-spin"
                                style={{ fontSize: "30px", color: "black" }}
                            ></i>
                        </center>
                    ) : null}
                </Modal.Body>
                <Modal.Footer>
                </Modal.Footer>
            </Modal>
            <section className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col">
                            <h1>Add Post</h1>
                        </div>
                        <div className="col">
                            <ol className="breadcrumb float-right">
                                <li className="breadcrumb-item">
                                    <button
                                        className="btn btn-info"
                                        onClick={() => navigate("/admin/post/list")}
                                    >
                                        <i className="fa fa-arrow-left mx-1" aria-hidden="true"></i>
                                        Back
                                    </button>
                                </li>
                            </ol>
                        </div>
                    </div>
                </div>
            </section>
            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <form onSubmit={handleSubmit}>
                                    <div className="card-body">
                                        <div className="row">

                                            {/* Post Name */}

                                            <div className="col-lg-6 col-12">
                                                <div className="form-group my-1">
                                                    <label htmlFor="heading">Heading</label>
                                                    <input
                                                        type="text"
                                                        value={values.heading}
                                                        onChange={handleChange}
                                                        className="form-control"
                                                        id="heading"
                                                        placeholder="Post Heading"
                                                    />
                                                </div>
                                                {touched.heading && errors.heading ? (
                                                    <div className="mb-2 mx-1 text-danger">
                                                        {errors.heading}
                                                    </div>
                                                ) : null}
                                            </div>

                                            {/* Slug */}
                                            <div className="col-lg-6 col-12">
                                                <SlugInput value={values.slug!} setValue={(e: any) => setFieldValue('slug', e.target.value)} />
                                            </div>

                                            {/*Category*/}
                                            <div className="col-lg-6 col-12">
                                                <div className="form-group my-1">
                                                    <label htmlFor="category_id">Category</label>
                                                    <SelectCategory value={values.category_id} setFieldValue={setFieldValue} />
                                                </div>
                                                {touched.category_id && errors.category_id ? (
                                                    <div className="mb-2 mx-1 text-danger">{errors.category_id}</div>
                                                ) : null}
                                            </div>

                                            {/* Sub - Category*/}
                                            <div className="col-lg-6 col-12">
                                                <div className="form-group my-1">
                                                    <label htmlFor="sub_category_id">Sub Category</label>
                                                    <SelectSubCategory value={values.sub_category_id} setFieldValue={setFieldValue} />
                                                </div>
                                                {touched.sub_category_id && errors.sub_category_id ? (
                                                    <div className="mb-2 mx-1 text-danger">{errors.sub_category_id}</div>
                                                ) : null}
                                            </div>

                                            {/* short Description */}
                                            <div className="col-12">
                                                <div className="form-group my-1">
                                                    <label htmlFor="short_description">Short Description</label>
                                                    <textarea
                                                        style={{ resize: 'none', height: '100px' }}
                                                        value={values.short_description}
                                                        onChange={handleChange}
                                                        className="form-control"
                                                        id="short_description"
                                                        placeholder="description"
                                                        name="short_description"
                                                    />
                                                </div>
                                                {touched.short_description && errors.short_description ? (
                                                    <div className="mb-2 mx-1 text-danger">
                                                        {errors.short_description}
                                                    </div>
                                                ) : null}
                                            </div>

                                            {/* Media */}
                                            <div className="col-12 col-lg-6">
                                                <div className="form-group my-1 d-flex flex-column">
                                                    <label htmlFor="media">Media</label>
                                                    <button onClick={() => setShowModal(true)} type="button" id="media" className="btn btn-info font-weight-bolder">Select Media</button>
                                                </div>
                                            </div>

                                            {/* Post Description */}
                                            <div className="col-12">
                                                <div className="form-group my-1">
                                                    <label htmlFor="description">Description</label>
                                                    <CustomEditor value={values.description} setValue={setFieldValue} valueKey="description" />
                                                </div>
                                                {touched.description && errors.description ? (
                                                    <div className="mb-2 mx-1 text-danger">
                                                        {errors.description}
                                                    </div>
                                                ) : null}
                                            </div>

                                            {/* Meta title */}
                                            <div className="col-lg-6 col-12">
                                                <div className="form-group my-1">
                                                    <label htmlFor="meta_title">Meta Title</label>
                                                    <input
                                                        type="text"
                                                        value={values.meta_title}
                                                        onChange={handleChange}
                                                        className="form-control"
                                                        id="meta_title"
                                                        placeholder="Add title"
                                                    />
                                                </div>
                                                {touched.meta_title && errors.meta_title ? (
                                                    <div className="mb-2 mx-1 text-danger">
                                                        {errors.meta_title}
                                                    </div>
                                                ) : null}
                                            </div>

                                            {/* Meta description */}
                                            <div className="col-lg-6 col-12">
                                                <div className="form-group my-1">
                                                    <label htmlFor="meta_description">Meta Description</label>
                                                    <input
                                                        type="text"
                                                        value={values.meta_description}
                                                        onChange={handleChange}
                                                        className="form-control"
                                                        id="meta_description"
                                                        placeholder="Add description"
                                                    />
                                                </div>
                                                {touched.meta_description && errors.meta_description ? (
                                                    <div className="mb-2 mx-1 text-danger">
                                                        {errors.meta_description}
                                                    </div>
                                                ) : null}
                                            </div>


                                            {/* Meta Link */}
                                            <div className="col-lg-6 col-12">
                                                <div className="form-group my-1">
                                                    <label htmlFor="meta_link">Meta Link</label>
                                                    <input
                                                        type="text"
                                                        value={values.meta_link}
                                                        onChange={handleChange}
                                                        className="form-control"
                                                        id="meta_link"
                                                        placeholder="add link"
                                                    />
                                                </div>
                                                {touched.meta_link && errors.meta_link ? (
                                                    <div className="mb-2 mx-1 text-danger">
                                                        {errors.meta_link}
                                                    </div>
                                                ) : null}
                                            </div>


                                            {/* Meta Keyword */}
                                            <div className="col-lg-6 col-12">
                                                <div className="form-group my-1">
                                                    <label htmlFor="meta_keyword">Meta Keyword</label>
                                                    <input
                                                        type="text"
                                                        value={values.meta_keyword}
                                                        onChange={handleChange}
                                                        className="form-control"
                                                        id="meta_keyword"
                                                        placeholder="Add keyword"
                                                    />
                                                </div>
                                                {touched.meta_keyword && errors.meta_keyword ? (
                                                    <div className="mb-2 mx-1 text-danger">
                                                        {errors.meta_keyword}
                                                    </div>
                                                ) : null}
                                            </div>


                                            {/* Image */}
                                            <div className="col-lg-6 col-12">
                                                {image ? (
                                                    <div className="showImages">
                                                        <IoIosCloseCircleOutline
                                                            color="black"
                                                            className="my-1 removeImageBtn"
                                                            onClick={() => {
                                                                setFieldValue("image", "");
                                                                setImage(null);
                                                            }}
                                                        />
                                                        <img
                                                            style={{ width: "100%", height: "100%" }}
                                                            src={URL.createObjectURL(image)}
                                                            alt="Category img"
                                                        />
                                                    </div>
                                                ) : (
                                                    <div className="form-group images_drag my-1 w-100">
                                                        <label htmlFor="image">Image</label>
                                                        <FileUploader
                                                            handleChange={(FileList: any) => {
                                                                setFieldValue("image", FileList);
                                                                setImage(FileList);
                                                            }}
                                                            name="image"
                                                            types={["JPG", "PNG", "JPEG"]}
                                                        />
                                                    </div>
                                                )}
                                                {touched.image && errors.image ? (
                                                    <div className="mb-2 mx-1 text-danger">
                                                        {errors.image}
                                                    </div>
                                                ) : null}
                                            </div>
                                            {/* Status */}
                                            <div className="col-12">
                                                <label htmlFor="status">Status</label>
                                                <div className="form-group d-flex">
                                                    <div className="custom-control custom-radio mr-2">
                                                        <input
                                                            className="custom-control-input"
                                                            checked={values.status === 1 ? true : false}
                                                            onChange={(e) =>
                                                                setFieldValue(
                                                                    "status",
                                                                    e.target.checked ? 1 : 0
                                                                )
                                                            }
                                                            type="radio"
                                                            id="customRadio1"
                                                            name="status"
                                                        />
                                                        <label
                                                            htmlFor="customRadio1"
                                                            className="custom-control-label"
                                                        >
                                                            Active
                                                        </label>
                                                    </div>
                                                    <div className="custom-control custom-radio">
                                                        <input
                                                            className="custom-control-input"
                                                            checked={values.status === 0 ? true : false}
                                                            onChange={(e) =>
                                                                setFieldValue(
                                                                    "status",
                                                                    e.target.checked ? 0 : 1
                                                                )
                                                            }
                                                            type="radio"
                                                            id="customRadio2"
                                                            name="customRadio1"
                                                        />
                                                        <label
                                                            htmlFor="customRadio2"
                                                            className="custom-control-label"
                                                        >
                                                            Deactive
                                                        </label>
                                                    </div>
                                                </div>
                                                {touched.status && errors.status ? (
                                                    <div className="mb-2 mx-1 text-danger">
                                                        {errors.status}
                                                    </div>
                                                ) : null}
                                            </div>
                                        </div>
                                    </div>
                                    {/* /.card-body */}
                                    <div className="card-footer">
                                        <button type="submit" className="btn btn-primary">
                                            Add Post
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}
