import React, { useEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { GetLogoApi, GetTopMenuListApi, LogoUpdateAPi, PageAndCategoryAPi, UpdateTopMenuApi } from '../../../Api/Admin';
import { toast } from 'sonner';
import useGlobalLoader from '../../../Store/GlobalState';
import { Accordion } from 'react-bootstrap';
import { FaPlus } from 'react-icons/fa';
import Sortable from 'sortablejs';
import { LogoSchema } from '../../../Validation';
import { useFormik } from 'formik';
import { FileUploader } from 'react-drag-drop-files';
import { IoIosCloseCircleOutline } from 'react-icons/io';

interface TopMenuType { slug: string, type: string, name: string };

export default function TopMenu() {

    const setIsLoading = useGlobalLoader((state: any) => state.setIsLoading);
    const [menuItem, setMenuItem] = useState<{ pageList: { slug: string, name: string }[], categoryList: { slug: string, name: string, subcategory: { name: string, slug: string }[] }[] }>();
    const [TopMenu, setTopMenu] = useState<TopMenuType[]>([]);
    const [selectedPage, setSelectedPage] = useState<TopMenuType[]>([]);
    const [selectedCategory, setSelectedCategory] = useState<TopMenuType[]>([]);
    const [selectedSubCategory, setSelectedSubCategory] = useState<TopMenuType[]>([]);
    const [image, setImage] = useState<File | string>("");

    const { refetch } = useQuery({
        queryKey: ["page-and-category-list"],
        queryFn: PageAndCategoryAPi,
        onSuccess: (resp) => {
            setMenuItem(resp?.data);
        },
        enabled: false
    });

    const getMenu = useQuery({
        queryKey: ["getMenu"],
        queryFn: GetTopMenuListApi,
        onSuccess: (resp) => {
            setTopMenu(resp?.data?.menu);
            setSelectedPage(resp?.data?.menu?.filter((item: any) => item.type === "page"));
            setSelectedCategory(resp?.data?.menu?.filter((item: any) => item.type === "category"));
            setSelectedSubCategory(resp?.data?.menu?.filter((item: any) => item.type === "sub-category"));
            setIsLoading(false);
        },
        enabled: false
    })

    const { mutate } = useMutation(UpdateTopMenuApi, {
        mutationKey: 'update-top-menu',
        onSuccess: (data: any) => {
            setIsLoading(false);
            toast.success(data.data.message);
        },
        onError: (data: any) => {
            setIsLoading(false);
            toast.error(data.response.data.message);
        },
    });

    const LogoUpdate = useMutation(LogoUpdateAPi, {
        mutationKey: 'update-logo',
        onSuccess: (data: any) => {
            setIsLoading(false);
            toast.success(data.data.message);
        },
        onError: (data: any) => {
            setIsLoading(false);
            toast.error(data.response.data.message);
        },
    });

    const getLogo = useQuery({
        queryKey: ["getLogo"],
        queryFn: GetLogoApi,
        onSuccess: (resp) => {
            setIsLoading(false);
        },
        enabled: false
    })

    useEffect(() => {
        setIsLoading(true);
        refetch();
        getMenu.refetch();
        getLogo.refetch();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const dropItems = document.getElementById('drop-items');
        if (dropItems) {
            new Sortable(dropItems, {
                animation: 350,
                chosenClass: "sortable-chosen",
                dragClass: "sortable-drag",
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [TopMenu]);

    const { handleSubmit, handleChange, errors, values, touched, setFieldValue } = useFormik({
        initialValues: {
            name: getLogo.data?.data?.logo?.type === "text" ? getLogo.data?.data?.logo?.name : "",
            slug: "",
            logo: getLogo.data?.data?.logo?.type === "text" ? "" : getLogo.data?.data?.logo?.name,
            type: getLogo.data?.data?.logo?.type || "text"
        },
        enableReinitialize: true,
        validationSchema: LogoSchema,
        onSubmit: () => HandleSave(),
    });

    const HandleSave = () => {
        const dropItems: any = document.getElementById('drop-items');
        setIsLoading(true);
        if (dropItems && dropItems?.children[0]) {
            const sortedArray = [...TopMenu].sort((a: any, b: any) => {
                const indexA = [...dropItems?.children].findIndex((item: any) => item.id.split(" ")[0] === a.slug && item.id.split(" ")[1] === a.type);
                const indexB = [...dropItems?.children].findIndex((item: any) => item.id.split(" ")[0] === b.slug && item.id.split(" ")[1] === b.type);
                return indexA - indexB;
            });
            mutate({ menu: sortedArray });
            LogoUpdate.mutate({ name: values.type === "text" ? values.name : values.logo });
        } else {
            mutate({ menu: TopMenu });
            LogoUpdate.mutate({ name: values.type === "text" ? values.name : values.logo });
        }
    }

    const handleSelectedPage = (object: { slug: string, type: string, name: string }) => {
        const index = selectedPage.map(item => item.slug).indexOf(object.slug);
        if (index > -1) {
            const newArray = [...selectedPage];
            newArray.splice(index, 1)
            setSelectedPage(newArray);
        } else {
            setSelectedPage([...selectedPage, object]);
        }
    }

    const handleSelectedCategory = (object: { slug: string, type: string, name: string }) => {
        const index = selectedCategory.map(item => item.slug).indexOf(object.slug);
        if (index > -1) {
            const newArray = [...selectedCategory];
            newArray.splice(index, 1)
            setSelectedCategory(newArray);
        } else {
            setSelectedCategory([...selectedCategory, object]);
        }
    }

    const handleSelectedSubCategory = (object: { slug: string, type: string, name: string }) => {
        const index = selectedSubCategory.map(item => item.slug).indexOf(object.slug);
        if (index > -1) {
            const newArray = [...selectedSubCategory];
            newArray.splice(index, 1)
            setSelectedSubCategory(newArray);
        } else {
            setSelectedSubCategory([...selectedSubCategory, object]);
        }
    }

    const handleAddPage = () => {
        const newMenu: TopMenuType[] = [];
        const newTopMenu = TopMenu.filter((menu) => {
            if (menu.type === "page") {
                if (selectedPage.includes(menu)) return true;
                return false;
            } else return true;
        });
        selectedPage.forEach((menu) => { if (!TopMenu.includes(menu)) newMenu.push(menu); });
        setTopMenu([...newTopMenu, ...newMenu]);
    }

    const handleAddCategory = () => {
        const newMenu: TopMenuType[] = [];
        const newTopMenu = TopMenu.filter((menu) => {
            if (menu.type === "category") {
                if (selectedCategory.includes(menu)) return true;
                return false;
            } else if (menu.type === "sub-category") {
                if (selectedSubCategory.includes(menu)) return true;
                return false;
            } else return true;
        });
        selectedCategory.forEach((menu) => { if (!TopMenu.includes(menu)) newMenu.push(menu); });
        selectedSubCategory.forEach((menu) => { if (!TopMenu.includes(menu)) newMenu.push(menu); });
        setTopMenu([...newTopMenu, ...newMenu]);
    }

    return (
        <div className="content-wrapper">
            <section className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col">
                            <h1>Top Menu Setting</h1>
                        </div>
                    </div>
                </div>
            </section>
            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-body">
                                    <div className='row'>
                                        <p className='h4'>Set Menu Item :-</p>
                                        <div className='col-lg-6 col-xl-4 col-12 mb-4'>
                                            <Accordion defaultActiveKey="0">
                                                <Accordion.Item eventKey="0">
                                                    <Accordion.Header>Page</Accordion.Header>
                                                    <Accordion.Body className='p-0'>
                                                        <div className='py-2 px-4'>
                                                            {
                                                                menuItem ? menuItem.pageList.map((item, i) => <div className="form-check" key={i}>
                                                                    <input className="form-check-input" type="checkbox" checked={selectedPage.map(item => item.slug).includes(item.slug)} id={`item-${i}`} onChange={() => handleSelectedPage({ slug: item?.slug, type: "page", name: item?.name })} />
                                                                    <label className="form-check-label" htmlFor={`item-${i}`}>{item?.name}</label>
                                                                </div>) : null
                                                            }
                                                        </div>
                                                        <div className='d-flex justify-content-end bg-light w-100'>
                                                            <button className='btn btn-primary m-3' style={{ minWidth: "100px" }} onClick={handleAddPage}>
                                                                <FaPlus className='mx-1' />
                                                                Add
                                                            </button>
                                                        </div>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                                <Accordion.Item eventKey="1">
                                                    <Accordion.Header>Category</Accordion.Header>
                                                    <Accordion.Body className='p-0'>
                                                        <div className='py-2 px-4'>
                                                            {
                                                                menuItem ? menuItem.categoryList.map((item, i) => <div className="form-check" key={i}>
                                                                    <input className="form-check-input" type="checkbox" checked={selectedCategory.map(item => item.slug).includes(item.slug)} id={`category-${i}`} onChange={() => handleSelectedCategory({ slug: item?.slug, type: "category", name: item?.name })} />
                                                                    <label className="form-check-label" htmlFor={`category-${i}`}>{item?.name}</label>
                                                                    {
                                                                        item?.subcategory[0] ? item?.subcategory.map((subCategory, index) => <div className="form-check" key={index}>
                                                                            <input className="form-check-input" type="checkbox" checked={selectedSubCategory.map(item => item.slug).includes(subCategory.slug)} id={`sub-category-${index}-${i}`} onChange={() => handleSelectedSubCategory({ slug: subCategory?.slug, type: "sub-category", name: subCategory?.name })} />
                                                                            <label className="form-check-label" htmlFor={`sub-category-${index}-${i}`}>{subCategory?.name}</label>
                                                                        </div>) : null
                                                                    }

                                                                </div>) : null
                                                            }
                                                        </div>
                                                        <div className='d-flex justify-content-end bg-light w-100'>
                                                            <button className='btn btn-primary m-3' style={{ minWidth: "100px" }} onClick={handleAddCategory}>
                                                                <FaPlus className='mx-1' />
                                                                Add
                                                            </button>
                                                        </div>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            </Accordion>
                                        </div>
                                        <div className='col-lg-6 col-xl-4 col-12 mb-4'>
                                            <div style={{ border: '1px solid black' }} className=' rounded-3 px-3' id="drop-items">
                                                {
                                                    TopMenu.map((menu, i) => <div className="shadow p-3 my-3 rounded-3" id={`${menu.slug} ${menu.type}`} key={i} style={{ cursor: "grab" }}>
                                                        <p className="mb-0">{menu?.name}</p>
                                                    </div>)
                                                }
                                            </div>
                                        </div>
                                        <div className='col-12'>
                                            <p className='h4'>Set Logo :-</p>
                                            {/* Type */}
                                            <div className="col-lg-6 col-12">
                                                <div className="form-group my-1">
                                                    <label htmlFor="name">Logo Type</label>
                                                    <select value={values.type} name='type' onChange={handleChange} className="form-control">
                                                        <option value="text">Text</option>
                                                        <option value="image">Image</option>
                                                    </select>
                                                </div>
                                            </div>

                                            {values.type === "text" ? <div className="col-lg-6 col-12">
                                                <div className="form-group my-1">
                                                    <label htmlFor="name">Add Name</label>
                                                    <input
                                                        type="text"
                                                        value={values.name}
                                                        onChange={handleChange}
                                                        className="form-control"
                                                        id="name"
                                                        placeholder="Add Name"
                                                    />
                                                </div>
                                                {touched.name && errors.name ? (
                                                    <div className="mb-2 mx-1 text-danger">
                                                        {`${errors.name}`}
                                                    </div>
                                                ) : null}
                                            </div> : <div className="col-lg-6 col-12">
                                                {image || values?.logo ? (
                                                    <div className="showImages">
                                                        <IoIosCloseCircleOutline
                                                            color="black"
                                                            className="my-1 removeImageBtn"
                                                            onClick={() => {
                                                                setFieldValue("logo", "");
                                                                setImage("");
                                                            }}
                                                        />
                                                        <img
                                                            style={{ width: "100%", height: "100%" }}
                                                            src={typeof image === "string" ? values.logo : URL.createObjectURL(image)}
                                                            alt="Category img"
                                                        />
                                                    </div>
                                                ) : (
                                                    <div className="form-group images_drag my-1 w-100">
                                                        <label htmlFor="image">Add Logo Image</label>
                                                        <FileUploader
                                                            handleChange={(FileList: any) => {
                                                                setFieldValue("logo", FileList);
                                                                setImage(FileList);
                                                            }}
                                                            name="image"
                                                            types={["JPG", "PNG", "JPEG"]}
                                                        />
                                                    </div>
                                                )}
                                                {touched.logo && errors.logo ? (
                                                    <div className="mb-2 mx-1 text-danger">
                                                        {`${errors.logo}`}
                                                    </div>
                                                ) : null}
                                            </div>}
                                        </div>
                                    </div>
                                </div>
                                <div className="card-footer">
                                    <button type="submit" className="btn btn-primary" onClick={() => handleSubmit()}>
                                        Save
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}