import React from 'react';
import { FormateDate } from '../../Helper';
import { postType } from '../Page/Home';
import { useNavigate } from 'react-router-dom';
import BookmarkIcon from './BookmarkIcon';
import Skeleton from 'react-loading-skeleton'


interface propsType {
    subMain?: boolean,
    topPost?: postType[]
}

export default function Main({ subMain = true, topPost }: propsType) {
    const navigate = useNavigate();
    return (
        <>
            {topPost ? <div className='row'>

                {topPost[0] && <div className='col-lg-8 col-12 mb-2 p-2' style={{ maxHeight: '500px', height: '100vw' }}>
                    <div className='h-100 w-100' style={{ position: 'relative' }}>
                        <div className='h-100 w-100' style={{ objectFit: 'cover', borderRadius: '4px', backgroundSize: 'cover' }}>
                            <img loading='lazy' decoding='async' className='w-100 h-100' style={{ borderRadius: '4px', objectFit: 'cover' }} src={`${process.env.REACT_APP_POST_URL_MD}/${topPost[0]?.image}`} alt='' />
                            <div className='px-2 w-100 text-white' style={{ position: 'absolute', bottom: '0px', borderRadius: '4px', background: 'linear-gradient(to top,black 0,rgba(0,0,0,0.9) calc(100% - 150px),rgba(0,0,0,0.5) 100%' }}>
                                <div
                                    className='px-2 mx-2 my-3'
                                    style={{
                                        backgroundColor: topPost[0]?.category?.color || 'var(--primary-category-bg)',
                                        transform: 'skew(-25deg)',
                                        width: 'fit-content',
                                        display: 'inline-block',
                                    }}
                                >
                                    <p className='mb-0' style={{ transform: 'skew(25deg', fontSize: '18px' }}>{topPost[0]?.category?.name}</p>
                                </div>
                                <h3 className='cursor-pointer blog-titel text-truncate' style={{ fontSize: '25px', textTransform: 'uppercase' }} onClick={() => navigate(`/post/view/${topPost[0]?.slug}`)}>
                                    <strong>
                                        {topPost[0]?.heading.slice(0, 100) +
                                            (topPost[0]?.heading?.length > 100 ? "..." : "")}
                                    </strong>
                                </h3>
                                <p className='d-none d-lg-block' style={{ color: 'var(--decription-color)' }}>
                                    {topPost[0]?.short_description?.slice(0, 200) +
                                        (topPost[0]?.short_description?.length > 200 ? "..." : "")}
                                </p>
                                <div className=' d-flex justify-content-between flex-wrap p-2'>
                                    <p className='mb-2 description-text' style={{ fontSize: '12px' }}>{FormateDate(topPost[0]?.created_at)}</p>
                                    <BookmarkIcon slug={topPost[0]?.slug} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>}

                <div className='col-lg-4 col-12  mb-2 p-2' style={{ height: '500px' }}>

                    {topPost[1] && topPost?.map((item, i) => {
                        if (i >= 1 && i < 3) return (<div className='h-50 w-100 pb-1' key={i}>
                            <div className='h-100 w-100' style={{ position: 'relative' }}>
                                <div className='h-100 w-100' style={{ objectFit: 'cover', borderRadius: '4px' }}>
                                    <img loading='lazy' className='w-100 h-100' style={{ borderRadius: '4px', objectFit: 'cover' }} src={`${process.env.REACT_APP_POST_URL_MD}/${item?.image}`} alt='' />
                                    <div className='px-2 w-100 text-white' style={{ position: 'absolute', bottom: '0px', borderRadius: '4px', background: 'linear-gradient(to top,black 0,rgba(0,0,0,0.9) calc(100% - 150px),rgba(0,0,0,0.5) 100%' }}>
                                        <div className='px-2 mx-2 my-3' style={{ backgroundColor: item?.category?.color || 'var(--primary-category-bg)', transform: 'skew(-25deg)', width: 'fit-content', display: 'inline-block' }}>
                                            <p className='mb-0' style={{ transform: 'skew(25deg', fontSize: '18px' }}>{item?.category?.name}</p>
                                        </div>
                                        <h3 className='cursor-pointer blog-titel text-truncate' style={{ fontSize: '18px', textTransform: 'uppercase' }} onClick={() => navigate(`/post/view/${item?.slug}`)}>
                                            <strong>
                                                {item?.heading.slice(0, 100) +
                                                    (item?.heading?.length > 100 ? "..." : "")}
                                            </strong>
                                        </h3>

                                        <div className=' d-flex justify-content-between flex-wrap p-2'>
                                            <p className='mb-2 description-text' style={{ fontSize: '12px' }}>{FormateDate(item?.created_at)}</p>
                                            <BookmarkIcon slug={item?.slug} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>)
                        return (<React.Fragment key={i}></React.Fragment>)
                    })}

                </div>

                {subMain && <div className='col-12 shadow' style={{ borderRadius: '4px' }}>
                    <div className='row'>
                        {topPost[3] && topPost?.map((item, i) => {
                            if (i >= 3 && i < 5) return (<div className='col-lg-6 col-12' key={i}>
                                <div className='row h-100'>
                                    <div className='col-7 d-flex flex-column py-2 px-3'>
                                        <div className='px-2 mx-2 mb-3 d-none d-md-block' style={{ backgroundColor: item?.category?.color || 'var(--primary-category-bg)', color: 'white', transform: 'skew(-25deg)', width: 'fit-content', display: 'inline-block' }}>
                                            <p className='mb-0' style={{ transform: 'skew(25deg', fontSize: '18px' }}>{item?.category?.name}</p>
                                        </div>
                                        <h3 className='cursor-pointer blog-titel text-truncate' style={{ fontSize: '15px', textTransform: 'uppercase' }} onClick={() => navigate(`/post/view/${item?.slug}`)}>
                                            <strong>
                                                {item?.heading.slice(0, 100) +
                                                    (item?.heading?.length > 100 ? "..." : "")}
                                            </strong>
                                        </h3>
                                        <div className=' d-flex justify-content-between flex-wrap'>
                                            <p className='mb-2 description-text' style={{ fontSize: '12px' }}>{FormateDate(item?.created_at)}</p>
                                            <BookmarkIcon slug={item?.slug} />
                                        </div>
                                    </div>
                                    <div className='col-5'>
                                        <div className='w-100 h-100 p-2 d-flex justify-content-end'>
                                            <img loading='lazy' className='w-100 h-100' style={{ borderRadius: '4px', maxHeight: '100px', maxWidth: '100px', objectFit: 'cover' }} src={`${process.env.REACT_APP_POST_URL_SM}/${item.image}`} alt='' />
                                        </div>
                                    </div>
                                </div>
                            </div>)
                            return (<React.Fragment key={i}></React.Fragment>)
                        })}
                    </div>
                </div>}
            </div>
                :
                <div className='row'>
                    <div className='col-lg-8 col-12 mb-2 p-2' style={{ maxHeight: '500px', height: '100vw' }}>
                        <Skeleton className='w-100 h-100' />
                    </div>
                    <div className='col-lg-4 col-12  mb-2 p-2' style={{ height: '500px' }}>
                        <Skeleton className='w-100 h-100' />
                    </div>
                    <div className='col-12' style={{ borderRadius: '4px', height: '150px' }}>
                        <Skeleton className='w-100 h-100' />
                    </div>
                </div>
            }
        </>
    );
}
