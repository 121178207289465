import React from 'react';

export default function StayUpToDate() {
  return (
    <div className='w-100 p-2' style={{ background: 'linear-gradient(100deg, rgb(173,216,230), rgb(247,93,89))', borderRadius: '8px', }}>
      <div className='row m-2' style={{ minHeight: '200px' }}>
        <div className='col-12 col-lg-6 d-flex align-self-center flex-column my-3'>
          <h3 style={{ color: 'var(--primary-dark)' }}>Stay Up to Date</h3>
          <p className='mb-0'>Subscribe to our website to get our newest post instantly!</p>
        </div>
        <div className='col-12 col-lg-6 d-flex align-self-center flex-column mb-3'>
          <div className='row align-items-center mt-2 mt-lg-0 '>
            <div className='col-12 col-lg-8 px-2'>
              <input type='text' className='w-100 p-1' style={{ borderRadius: '4px', border: 'none', outline: 'none' }} placeholder='Enter E-mail' />
            </div>
            <div className='col-12 col-lg-4 px-2 mt-2 mt-lg-0'>
              <button className='w-100 btn btn-danger'>Sign up</button>
            </div>
            <div className='col-12 px-2 d-flex'>
              <div className="custom-control custom-checkbox">
                <input className="custom-control-input custom-control-input-danger cursor-pointer" type="checkbox" id="checkbox-1" defaultChecked />
                <label className="custom-control-label" htmlFor="checkbox-1">I have read and agree to the terms & conditions</label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
  