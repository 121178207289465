import React, { useState } from 'react';
import { IoIosCloseCircleOutline } from 'react-icons/io';
import { FileUploader } from "react-drag-drop-files";
import { MdDelete } from "react-icons/md";

interface ModalType {
    setValue: (key: string, data: any) => void,
    index: number,
    value: {
        id?: number,
        description: string,
        image: File | undefined,
        link: string,
        isImage?: boolean
    }[],
    valueKey: string,
    errors: any,
    touched: any,
    oldImage?: string,
    setDeleteStory?: (value: number[]) => void,
    deleteStory?: number[]
}

export default function StoryInput({ setValue, index, value, valueKey, errors, touched, oldImage, setDeleteStory, deleteStory }: ModalType) {
    const fileTypes = ["JPG", "PNG", "JPEG"];
    const [oldImages, setOldImages] = useState<string>(oldImage ? oldImage : "");

    const HandleRemove = () => {
        setValue(valueKey, value.filter((item, i) => i !== index));
        if (setDeleteStory && value[index]?.id) setDeleteStory([...deleteStory!, value[index]?.id!]);
    }

    return (
        <>
            <MdDelete style={{ transform: 'translate(-100%, 100%)', zIndex: 9999, position: 'absolute' }} display={index !== 0 ? "block" : "none"} className="my-1 text-danger removeImageBtn" onClick={HandleRemove} />
            <div className='row shadow-sm p-3 mb-3 bg-light rounded' style={{ borderRadius: "10px" }}>
                <div className='col-lg-6 col-12'>
                    {
                        !value[index].image && !oldImages ?
                            <div className="form-group images_drag my-1 w-100">
                                <label htmlFor="image">Image</label>
                                <FileUploader handleChange={(FileList: any) => {
                                    setValue(`story[${index}].image`, FileList[0]);
                                }} name="image" multiple types={fileTypes} />
                            </div>
                            :
                            <div className='showImages'>
                                <IoIosCloseCircleOutline
                                    color='black'
                                    className="my-1 removeImageBtn"
                                    onClick={() => {
                                        oldImage = ""
                                        setValue(`story[${index}].image`, undefined);
                                        setValue(`story[${index}].isImage`, true);
                                        setOldImages("");
                                    }} />
                                <img style={{ width: "100%", height: "100%" }} src={oldImages ? oldImages : URL.createObjectURL(value[index]?.image!)} alt="img" />
                            </div>
                    }
                    {touched && errors && errors[index] ? (
                        <div className="mb-2 mx-1 text-danger">
                            {errors[index]?.image}
                        </div>
                    ) : null}
                    <div className="form-group my-1 w-100">
                        <label htmlFor="description">Add Link</label>
                        <input type="text" value={value[index].link} onChange={(e) => setValue(`story[${index}].link`, e.target.value)} className="form-control" placeholder="Add Link" />
                    </div>
                </div>
                <div className='col-lg-6 col-12'>
                    <div className="form-group my-1 h-100 d-flex flex-column w-100 align-items-start">
                        <label htmlFor="description">Description</label>
                        <textarea style={{ resize: "none" }} value={value[index].description} onChange={(e) => setValue(`story[${index}].description`, e.target.value)} className="form-control flex-grow-1" placeholder="Add Description" />
                    </div>

                </div>

            </div>
        </>
    );
}
