import * as Yup from "yup";

export const LoginSchema = Yup.object({
  email: Yup.string().email("Invalid email address").required("E-mail is required"),
  password: Yup.string().required("Password is required"),
});

export const ChangePasswordSchema = Yup.object({
  old_password: Yup.string().required("Old password is required"),
  new_password: Yup.string().required("New password is required"),
  confirm_password: Yup.string()
    .oneOf([Yup.ref("new_password")], "Password does not match")
    .required("Confirm password is required"),
});

export const CategorySchema = Yup.object({
  name: Yup.string().required("Category name is required"),
  status: Yup.number().required("Status is required."),
  slug: Yup.string(),
  isImage: Yup.boolean(),
  image: Yup.mixed().when("isImage", {
    is: true,
    then: (schema) => schema.required("Image is required"),
  }),
  description: Yup.string().required("Description is required"),
});

export const SubCategorySchema = Yup.object({
  name: Yup.string().required("Category name is required"),
  status: Yup.number().required("Status is required."),
  category_id: Yup.number().required("Category is required."),
  slug: Yup.string(),
});

export const ProfileSchema = Yup.object({
  email: Yup.string().email("Invalid email address").required("E-mail is required"),
  userName: Yup.string().required("Username is required"),
  image: Yup.mixed(),
  number: Yup.string().required("Number is required.")
});

export const PostSchema = Yup.object({
  isImage: Yup.boolean(),
  slug: Yup.string(),
  image: Yup.mixed().when("isImage", {
    is: true,
    then: (schema) => schema.required("Image is required"),
  }),
  heading: Yup.string().required("Heading is required."),
  category_id: Yup.number().required("Category is required."),
  sub_category_id: Yup.number().required("Sub-Category is required."),
  description: Yup.string().required("Description is required."),
  status: Yup.number().required("Status is required."),
  meta_title: Yup.string().required("Meta Title is required"),
  meta_description: Yup.string().required("Meta Description is required"),
  meta_keyword: Yup.string().required("Meta Keyword is required"),
  meta_link: Yup.string().required("Meta Link is required"),
  short_description: Yup.string().required("Short description is required.")
})

export const WebStorySchema = Yup.object({
  name: Yup.string().required("Name is required."),
  isImage: Yup.boolean().default(true),
  slug: Yup.string(),
  image: Yup.mixed().when("isImage", {
    is: true,
    then: (schema) => schema.required("Image is required"),
  }),
  story: Yup.array().min(1, "Add at least one stroy.").of(Yup.object({
    id: Yup.number(),
    isImage: Yup.boolean().default(true),
    image: Yup.mixed().when("isImage", {
      is: true,
      then: (schema) => schema.required("Image is required"),
    }),
    description: Yup.string(),
    link: Yup.string(),
  })),
  meta_title: Yup.string().required("Meta Title is required"),
  meta_description: Yup.string().required("Meta Description is required"),
  meta_keyword: Yup.string().required("Meta Keyword is required"),
  meta_link: Yup.string().required("Meta Link is required"),
});

export const PagesSchema = Yup.object({
  name: Yup.string().required("Name is required."),
  description: Yup.string().required("Description is required."),
  status: Yup.number().required("Status is required."),
  slug: Yup.string(),
})

export const LogoSchema = Yup.object({
  type: Yup.string().required(),
  name: Yup.string().when("type", {
    is: "text",
    then: (schema) => schema.required("Logo Name is required"),
  }),
  logo: Yup.mixed().when("type", {
    is: "image",
    then: (schema) => schema.required("Logo is required"),
  }),
  slug: Yup.string(),
});