import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import { useNavigate, useParams } from "react-router-dom";
import { useMutation, useQuery } from "react-query";
import { toast } from "sonner";
import { PagesSchema } from "../../../Validation";
import useGlobalLoader from "../../../Store/GlobalState";
import { PagesUpdateApi, PagesViewApi } from "../../../Api/Admin";
import { pagesDataType } from "./pages";
import CustomEditor from "../../../Components/CkEditor";
import SlugInput from "../../../Components/SlugInput";


export default function PagesEdit() {
    const { slug } = useParams();
    const [pages, setPages] = useState<pagesDataType>();
    const navigate = useNavigate();
    const setIsLoading = useGlobalLoader((state: any) => state.setIsLoading);

    const { refetch } = useQuery({
        queryKey: ["pages-view"],
        queryFn: () => PagesViewApi(slug!),
        onSuccess: (resp) => {
            setIsLoading(false);
            setPages(resp?.data?.page);
        },
        onError: (err) => {
            setIsLoading(false);
        },
        enabled: false,
    });
    const { mutate } = useMutation(PagesUpdateApi, {
        mutationKey: "pages-add",
        onSuccess: (data: any) => {
            setIsLoading(false);
            toast.success(data.data.message);
            navigate("/admin/pages/list");
        },
        onError: (data: any) => {
            setIsLoading(false);
            toast.error(data.response.data.message);
        },
    });
    const { handleSubmit, handleChange, errors, values, touched, setFieldValue } = useFormik({
        initialValues: {
            name: pages?.name,
            status: pages?.status,
            description: pages?.description ? pages?.description : "",
            meta_title: pages?.meta_title ? pages?.meta_title : "",
            meta_description: pages?.meta_description ? pages?.meta_description : "",
            meta_keyword: pages?.meta_keyword ? pages?.meta_keyword : "",
            meta_link: pages?.meta_link ? pages?.meta_link : "",
            slug: pages?.slug
        },
        enableReinitialize: true,
        validationSchema: PagesSchema,
        onSubmit: (data) => {
            setIsLoading(true);
            mutate({
                slug: slug!,
                newSlug: data?.slug,
                name: data.name!,
                status: data.status!,
                description: data.description!,
                meta_title: data.meta_title,
                meta_description: data.meta_description,
                meta_keyword: data.meta_keyword,
                meta_link: data.meta_link
            });
        },
    });


    useEffect(() => {
        setIsLoading(true);
        refetch();
        // eslint-disable-next-line
    }, []);
    return (
        <div className="content-wrapper">
            <section className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col">
                            <h1>Edit Pages</h1>
                        </div>
                        <div className="col">
                            <ol className="breadcrumb float-right">
                                <li className="breadcrumb-item">
                                    <button
                                        className="btn btn-info"
                                        onClick={() => navigate("/admin/pages/list")}
                                    >
                                        <i className="fa fa-arrow-left mx-1" aria-hidden="true"></i>
                                        Back
                                    </button>
                                </li>
                            </ol>
                        </div>
                    </div>
                </div>
            </section>
            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <form onSubmit={handleSubmit}>
                                    <div className="card-body">
                                        <div className="row">

                                            {/* Pages Name */}
                                            <div className="col-lg-6 col-12">
                                                <div className="form-group my-1">
                                                    <label htmlFor="name">Pages Name</label>
                                                    <input
                                                        type="text"
                                                        value={values.name}
                                                        onChange={handleChange}
                                                        className="form-control"
                                                        id="name"
                                                        placeholder="Pages name"
                                                    />
                                                </div>
                                                {touched.name && errors.name ? (
                                                    <div className="mb-2 mx-1 text-danger">
                                                        {errors.name}
                                                    </div>
                                                ) : null}
                                            </div>

                                            {/* Slug */}
                                            <div className="col-lg-6 col-12">
                                                <SlugInput value={values.slug!} setValue={(e: any) => setFieldValue('slug', e.target.value)} />
                                            </div>

                                            {/* Description */}
                                            <div className="col-12">
                                                <div className="form-group my-1">
                                                    <label htmlFor="description">Description</label>
                                                    <CustomEditor value={values.description} setValue={setFieldValue} valueKey="description" />
                                                </div>
                                                {touched.description && errors.description ? (
                                                    <div className="mb-2 mx-1 text-danger">
                                                        {errors.description}
                                                    </div>
                                                ) : null}
                                            </div>

                                            {/* Meta title */}
                                            <div className="col-lg-6 col-12">
                                                <div className="form-group my-1">
                                                    <label htmlFor="meta_title">Meta Title</label>
                                                    <input
                                                        type="text"
                                                        value={values.meta_title}
                                                        onChange={handleChange}
                                                        className="form-control"
                                                        id="meta_title"
                                                        placeholder="Add title"
                                                    />
                                                </div>
                                                {touched.meta_title && errors.meta_title ? (
                                                    <div className="mb-2 mx-1 text-danger">
                                                        {errors.meta_title}
                                                    </div>
                                                ) : null}
                                            </div>

                                            {/* Meta description */}
                                            <div className="col-lg-6 col-12">
                                                <div className="form-group my-1">
                                                    <label htmlFor="meta_description">Meta Description</label>
                                                    <input
                                                        type="text"
                                                        value={values.meta_description}
                                                        onChange={handleChange}
                                                        className="form-control"
                                                        id="meta_description"
                                                        placeholder="Add description"
                                                    />
                                                </div>
                                                {touched.meta_description && errors.meta_description ? (
                                                    <div className="mb-2 mx-1 text-danger">
                                                        {errors.meta_description}
                                                    </div>
                                                ) : null}
                                            </div>


                                            {/* Meta Link */}
                                            <div className="col-lg-6 col-12">
                                                <div className="form-group my-1">
                                                    <label htmlFor="meta_link">Meta Link</label>
                                                    <input
                                                        type="text"
                                                        value={values.meta_link}
                                                        onChange={handleChange}
                                                        className="form-control"
                                                        id="meta_link"
                                                        placeholder="add link"
                                                    />
                                                </div>
                                                {touched.meta_link && errors.meta_link ? (
                                                    <div className="mb-2 mx-1 text-danger">
                                                        {errors.meta_link}
                                                    </div>
                                                ) : null}
                                            </div>


                                            {/* Meta Keyword */}
                                            <div className="col-lg-6 col-12">
                                                <div className="form-group my-1">
                                                    <label htmlFor="meta_keyword">Meta Keyword</label>
                                                    <input
                                                        type="text"
                                                        value={values.meta_keyword}
                                                        onChange={handleChange}
                                                        className="form-control"
                                                        id="meta_keyword"
                                                        placeholder="Add keyword"
                                                    />
                                                </div>
                                                {touched.meta_keyword && errors.meta_keyword ? (
                                                    <div className="mb-2 mx-1 text-danger">
                                                        {errors.meta_keyword}
                                                    </div>
                                                ) : null}
                                            </div>

                                            {/* Status */}
                                            <div className="col-lg-6 col-12">
                                                <label htmlFor="status">Status</label>
                                                <div className="form-group d-flex">
                                                    <div className="custom-control custom-radio mr-2">
                                                        <input
                                                            className="custom-control-input"
                                                            checked={values.status === 1 ? true : false}
                                                            onChange={(e) =>
                                                                setFieldValue(
                                                                    "status",
                                                                    e.target.checked ? 1 : 0
                                                                )
                                                            }
                                                            type="radio"
                                                            id="customRadio1"
                                                            name="status"
                                                        />
                                                        <label
                                                            htmlFor="customRadio1"
                                                            className="custom-control-label"
                                                        >
                                                            Active
                                                        </label>
                                                    </div>
                                                    <div className="custom-control custom-radio">
                                                        <input
                                                            className="custom-control-input"
                                                            checked={values.status === 0 ? true : false}
                                                            onChange={(e) =>
                                                                setFieldValue(
                                                                    "status",
                                                                    e.target.checked ? 0 : 1
                                                                )
                                                            }
                                                            type="radio"
                                                            id="customRadio2"
                                                            name="customRadio1"
                                                        />
                                                        <label
                                                            htmlFor="customRadio2"
                                                            className="custom-control-label"
                                                        >
                                                            Deactive
                                                        </label>
                                                    </div>
                                                </div>
                                                {touched.status && errors.status ? (
                                                    <div className="mb-2 mx-1 text-danger">
                                                        {errors.status}
                                                    </div>
                                                ) : null}
                                            </div>
                                        </div>
                                    </div>
                                    {/* /.card-body */}
                                    <div className="card-footer">
                                        <button type="submit" className="btn btn-primary">
                                            Edit Page
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}
