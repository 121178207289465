import React, { useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';
import { useQuery } from 'react-query';
import { UserPostListApi } from '../../Api/User';
import useGlobalLoader, { useUserStore } from '../../Store/GlobalState';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import Main from '../Components/Main';
import PostListView1 from '../Components/PostListView1';
import { postType } from './Home';


export default function UserPostList() {
    const navigate = useNavigate();
    const search = useUserStore((state: any) => state.search);
    const setIsLoading = useGlobalLoader((state: any) => state.setIsLoading);
    const [page, setPage] = useState<number>(1);
    const [postData, setPostData] = useState<{ total_page: number, latest_posts: postType[], top_posts: postType[] }>();
    const [searchParams] = useSearchParams();
    const category_id = searchParams.get('category_id');
    const sub_category_id = searchParams.get('sub_category_id');

    const { refetch } = useQuery({
        queryKey: ['user-post-list', search, page, 12, category_id, sub_category_id],
        queryFn: UserPostListApi,
        onSuccess: (resp) => {
            setIsLoading(false);
            setPostData(resp?.data);
            if (resp.data.total_page < page) {
                setPage(1);
            }
        },
        onError: () => setIsLoading(false),
        enabled: false
    });

    useEffect(() => {
        refetch();
        setIsLoading(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [category_id, sub_category_id, page, searchParams]);
    return (
        <Container>
            <Main subMain={false} topPost={postData?.top_posts} />
            <div className='row'>
                <div className='col-lg-8 col-12'>
                    {postData?.latest_posts?.map((item, i) => <div className='w-100 mb-0' style={{maxHeight:'100vw', height:'750px'}} key={i}>
                        <PostListView1 data={{slug:item?.slug, date: item?.created_at, image: `${process.env.REACT_APP_POST_URL_SM}/${item.image}`, title: item.heading, description: item?.short_description || '', category: item?.category?.name, categoryColor: item?.category?.color }} onClick={() => navigate(`/post/view/${item.slug}`)} />
                    </div>)}
                </div>
            </div>
            <div className='my-2 bottom-0'>
                {postData?.total_page ?
                    <ReactPaginate
                        previousLabel={"<<"}
                        nextLabel={">>"}
                        breakLabel={"..."}
                        pageCount={postData?.total_page}
                        marginPagesDisplayed={1}
                        pageRangeDisplayed={1}
                        forcePage={page - 1}
                        onPageChange={(pageClicked) => {
                            setPage(pageClicked.selected + 1);
                        }}
                        containerClassName={"pagination justify-content-center"}
                        pageClassName={"page-item mx-2"}
                        pageLinkClassName={"page-link"}
                        previousClassName={"page-item"}
                        previousLinkClassName={"page-link"}
                        nextClassName={"page-item"}
                        nextLinkClassName={"page-link"}
                        breakClassName={"page-item"}
                        breakLinkClassName={"page-link"}
                        activeClassName={"active"}
                    /> : null}
            </div>
        </Container>
    );
}
