import HttpService from "./interceptors";
import { PostType, CategoryType, ProfileType, changePassword, loginType, WebStoryType, SubCategoryType, PagesType, EditorPicksType, ImageSizeType, QuickLinksType, UpdateTopMenuType, UpdateLogoType } from "./Types";

export const LoginAPI = async (values: loginType) => await HttpService.post("/api/admin/login", values);
export const ChangePasswordApi = async (values: changePassword) => await HttpService.post("/api/admin/change/password", values);
export const AdminDashboardApi = async () => await HttpService.get("/api/admin/dashboard");

// Category
export const CategoryListApi = async ({ queryKey }: any) => {
    // eslint-disable-next-line
    const [_, search, page, limit] = queryKey;
    return await HttpService.get(`/api/admin/category/list?search=${search}&page=${page}&limit=${limit ? limit : 10}`)
};
export const CategoryViewApi = async (slug: string) => await HttpService.get(`/api/admin/category/edit/${slug}`);
export const CategoryDeleteApi = async (slug: string) => await HttpService.delete(`/api/admin/category/delete/${slug}`);
export const CategoryAddApi = async (values: CategoryType) => {
    const formData = new FormData();
    formData.append('name', values?.name);
    formData.append('slug', values?.slug!);
    formData.append('image', values?.image!);
    formData.append('status', values?.status.toString());
    formData.append('description', values?.description);
    formData.append('color', values?.color);

    return await HttpService.post("/api/admin/category/add", formData);
}
export const CategoryUpdateApi = async (values: CategoryType) => {
    const formData = new FormData();
    formData.append('name', values?.name);
    formData.append('slug', values?.newSlug!)
    formData.append('image', values?.image!);
    formData.append('status', values?.status.toString());
    formData.append('description', values?.description);
    formData.append('color', values?.color);
    return await HttpService.post(`/api/admin/category/update/${values.slug}`, formData);
}


// SubCategory
export const SubCategoryListApi = async ({ queryKey }: any) => {
    // eslint-disable-next-line
    const [_, search, page, limit] = queryKey;
    return await HttpService.get(`/api/admin/sub-category/list?search=${search}&page=${page}&limit=${limit ? limit : 10}`)
};
export const SubCategoryViewApi = async (slug: string) => await HttpService.get(`/api/admin/sub-category/edit/${slug}`);
export const SubCategoryDeleteApi = async (slug: string) => await HttpService.delete(`/api/admin/sub-category/delete/${slug}`);
export const SubCategoryAddApi = async (values: SubCategoryType) => {
    const formData = new FormData();
    formData.append('name', values?.name);
    formData.append('slug', values?.slug!)
    formData.append('image', values?.image!);
    formData.append('status', values?.status.toString());
    formData.append('description', values?.description);
    // formData.append('color', values?.color);
    formData.append('category_id', values?.category_id.toString());
    return await HttpService.post("/api/admin/sub-category/add", formData);
}
export const SubCategoryUpdateApi = async (values: SubCategoryType) => {
    const formData = new FormData();
    formData.append('name', values?.name);
    formData.append('slug', values?.newSlug!)
    formData.append('image', values?.image!);
    formData.append('status', values?.status.toString());
    formData.append('description', values?.description);
    // formData.append('color', values?.color);
    formData.append('category_id', values?.category_id.toString());

    return await HttpService.post(`/api/admin/sub-category/update/${values.slug}`, formData);
}


// Profile
export const ProfileViewApi = async () => await HttpService.get(`/api/admin/profile/view`);
export const ProfileUpdateApi = async (values: ProfileType) => {
    const formData = new FormData();
    formData.append('name', values?.name);
    formData.append('username', values?.username);
    formData.append('email', values?.email);
    formData.append('number', values?.number);
    formData.append('image', values?.image);
    return await HttpService.post(`/api/admin/profile`, formData)
};

// Post
export const PostListApi = async ({ queryKey }: any) => {
    // eslint-disable-next-line
    const [_, search, page, limit] = queryKey;
    return await HttpService.get(`/api/admin/post/list?search=${search}&page=${page}&limit=${limit ? limit : 10}`)
};
export const PostViewApi = async (slug: string) => await HttpService.get(`/api/admin/post/edit/${slug}`);
export const PostDeleteApi = async (slug: string) => await HttpService.delete(`/api/admin/post/delete/${slug}`);
export const PostAddApi = async (values: PostType) => {
    const formData = new FormData();
    formData.append('heading', values.heading);
    formData.append('slug', values?.slug!)
    formData.append('status', values.status.toString());
    formData.append('short_description', values?.short_description);
    formData.append('description', values.description);
    formData.append('category_id', values?.category_id.toString());
    formData.append('sub_category_id', values?.sub_category_id.toString());
    formData.append('meta_title', values?.meta_title);
    formData.append('meta_description', values?.meta_description);
    formData.append('meta_keyword', values?.meta_keyword);
    formData.append('meta_link', values?.meta_link);
    formData.append('image', values?.image!);
    return await HttpService.post("/api/admin/post/add", formData);
}
export const PostUpdateApi = async (values: PostType) => {
    const formData = new FormData();
    formData.append('heading', values.heading);
    formData.append('slug', values?.newSlug!)
    formData.append('status', values.status.toString());
    formData.append('short_description', values?.short_description);
    formData.append('description', values.description);
    formData.append('category_id', values?.category_id.toString());
    formData.append('image', values?.image!);
    formData.append('sub_category_id', values?.sub_category_id.toString());
    formData.append('meta_title', values?.meta_title);
    formData.append('meta_description', values?.meta_description);
    formData.append('meta_keyword', values?.meta_keyword);
    formData.append('meta_link', values?.meta_link);
    return await HttpService.post(`/api/admin/post/update/${values.slug}`, formData);
}


// Web-Story
export const WebStoryListApi = async ({ queryKey }: any) => {
    // eslint-disable-next-line
    const [_, search, page, limit] = queryKey;
    return await HttpService.get(`/api/admin/story/list?search=${search}&page=${page}&limit=${limit ? limit : 10}`)
};
export const WebStoryViewApi = async (slug: string) => await HttpService.get(`/api/admin/story/edit/${slug}`);
export const WebStoryDeleteApi = async (slug: string) => await HttpService.delete(`/api/admin/story/delete/${slug}`);
export const WebStoryAddApi = async (values: WebStoryType) => {
    const formData = new FormData();
    formData.append('name', values.name);
    formData.append('slug', values?.slug!)
    formData.append('image', values.image!);
    formData.append('status', values.status.toString());
    formData.append('meta_title', values?.meta_title);
    formData.append('meta_description', values?.meta_description);
    formData.append('meta_keyword', values?.meta_keyword);
    formData.append('meta_link', values?.meta_link);
    for (let index = 0; index < values.story.length; index++) {
        const element = values.story[index];
        formData.append(`story[${index}]['image']`, element.image!);
        formData.append(`story[${index}]['description']`, element.description);
        formData.append(`story[${index}]['link']`, element.link);
    }
    return await HttpService.post("/api/admin/story/add", formData);
}
export const WebStoryUpdateApi = async (values: WebStoryType) => {
    const formData = new FormData();
    formData.append('name', values.name);
    formData.append('slug', values?.newSlug!)
    formData.append('image', values.image!);
    formData.append('status', values.status.toString());
    formData.append('meta_title', values?.meta_title);
    formData.append('meta_description', values?.meta_description);
    formData.append('meta_keyword', values?.meta_keyword);
    formData.append('meta_link', values?.meta_link);
    for (let index = 0; index < values.story.length; index++) {
        const element = values.story[index];
        formData.append(`story[${index}]['slug']`, element?.slug ? element?.slug?.toString() : "");
        formData.append(`story[${index}]['image']`, element.image!);
        formData.append(`story[${index}]['description']`, element.description);
        formData.append(`story[${index}]['link']`, element.link);
    }
    if (values?.story_image_delete) formData.append(`story_image_delete`, JSON.stringify(values?.story_image_delete));

    return await HttpService.post(`/api/admin/story/update/${values.slug}`, formData);
}


// Pages
export const PagesListApi = async ({ queryKey }: any) => {
    // eslint-disable-next-line
    const [_, search, page, limit] = queryKey;
    return await HttpService.get(`/api/admin/page/list?search=${search}&page=${page}&limit=${limit ? limit : 10}`)
};
export const PagesViewApi = async (slug: string) => await HttpService.get(`/api/admin/page/edit/${slug}`);
export const PagesDeleteApi = async (slug: string) => await HttpService.delete(`/api/admin/page/delete/${slug}`);
export const PagesAddApi = async (values: PagesType) => await HttpService.post("/api/admin/page/add", values);
export const PagesUpdateApi = async (values: PagesType) => await HttpService.post(`/api/admin/page/update/${values?.slug}`, values);

export const UpdateEditorPick = async (values: EditorPicksType) => await HttpService.post('/api/admin/update/editor/pick', values);
export const PostDropDownListApi = async ({ queryKey }: any) => await HttpService.get(`/api/admin/all_post/list?search=${queryKey[1]}&page=${queryKey[2]}&limit=${queryKey[3]}`);
export const GetSelectedEditorPickApi = async () => await HttpService.get('api/admin/select/editor_pick');
export const GetImageSizeApi = async () => await HttpService.get('/api/admin/image/height/weight/edit');
export const UpdateImageApi = async (values: ImageSizeType[]) => await HttpService.post('/api/admin/image/height/width/update', values);
export const GetQuickLinks = async () => await HttpService.get('/api/admin/quick/link/edit');
export const UpdateQuickLinks = async (values: QuickLinksType[]) => await HttpService.post('/api/admin/quick/link/update', values);

export const MediaDeleteApi = async (slug: string) => await HttpService.delete(`/api/admin/media/delete/${slug}`);
export const MediaListApi = async ({ queryKey }: any) => await HttpService.get(`/api/admin/media/list?search=${queryKey[1]}&page=${queryKey[2]}&limit=${queryKey[3] ? queryKey[3] : 10}`)

export const MediaAddApi = async (value: File[]) => {
    const formData = new FormData();
    for (let i = 0; i <= value?.length; i++) {
        formData.append('media[]', value[i]);
    }
    return await HttpService.post('/api/admin/media/add', formData);
}


//TopMenu
export const PageAndCategoryAPi = async () => await HttpService.get('/api/admin/menu/list');
export const GetTopMenuListApi = async () => await HttpService.get('/api/admin/select_menu/list');
export const UpdateTopMenuApi = async (value: UpdateTopMenuType) => await HttpService.post('/api/admin/update/menu', value);
//Logo
export const GetLogoApi = async () => await HttpService.get('/api/admin/logo/view');
export const LogoUpdateAPi = async (value: UpdateLogoType) => {
    const formData = new FormData();
    formData.append("name",value.name);
    await HttpService.post('/api/admin/logo/update/logo', formData)
};
