import React from 'react';
import { FormateDate } from '../../Helper';
import BookmarkIcon from './BookmarkIcon';

interface propsType {
    data: {
        image: string,
        title: string,
        date: string,
        slug: string
    },
    onClick?: () => void
}

export default function PostListView4({ data, onClick }: propsType) {
    return (
        <div className='row h-100'>
            <div className='col-4'>
                <div className='w-100 h-100 p-2'>
                    <img loading='lazy' className='w-100 h-100' style={{ borderRadius: '4px', maxHeight: '100px', maxWidth: '100px' }} src={data.image} alt='' />
                </div>
            </div>
            <div className='col-8 d-flex flex-column p-2'>
                <h3 className='cursor-pointer blog-titel  text-break' style={{ fontSize: '15px', textTransform: 'uppercase' }} onClick={onClick}>
                    <strong>
                        {data.title.slice(0, 100) +
                            (data.title?.length > 100 ? "..." : "")}
                    </strong>
                </h3>
                <div className=' d-flex justify-content-between flex-wrap'>
                    <p className='description-text' style={{ fontSize: '12px' }}>{FormateDate(data.date)}</p>
                    <BookmarkIcon slug={data?.slug} />
                </div>
            </div>
        </div>
    );
}
