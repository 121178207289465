import React from 'react';
import Editor from 'ckeditor5-custom-build/build/ckeditor';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import HttpService from '../Api/interceptors';

interface editorPropsType {
    value: string,
    setValue: (key: string, value: string) => void,
    valueKey: string
}



export default function CustomEditor(props: editorPropsType) {
    function uploadAdapter(loader: any) {
        return {
            upload: () => {
                return new Promise((resolve, reject) => {
                    const formData = new FormData();
                    loader.file.then((file: any) => {
                        formData.append('image', file);
                        HttpService.post('/api/admin/post/image/add', formData).then((resp: any) => {
                            resolve({ default: resp?.data?.image_url });
                        }).catch((err: any) => {
                            reject(err);
                        });
                    })
                })
            }
        }
    }

    function uploadPlugin(editor: any) {
        editor.plugins.get('FileRepository').createUploadAdapter = (loader: any) => {
            return uploadAdapter(loader);
        }
    }

    return (
        <CKEditor
            editor={Editor}
            config={{
                extraPlugins: [uploadPlugin],
                mediaEmbed: { previewsInData: true},
                htmlEmbed:{showPreviews:true}
            }}
            onChange={(event: any, editor: any) => {
                const data = editor.getData();
                props.setValue(props.valueKey, data);
            }}
            data={props.value}
        />
    );
}
