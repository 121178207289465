import { toast } from 'sonner';
import { create } from 'zustand';
import { CategoryType } from '../User/Page/Home/Category';
import { postType } from '../User/Page/Home';

const useGlobalLoader = create((set) => ({
  isLoading: false,
  setIsLoading: (value: boolean) => set(() => ({ isLoading: value })),
}));

const setBookmark = (id: number, name: string, method: string) => {
  if (method === "Add") {
    const Bookmark = localStorage.getItem(name);
    if (Bookmark) {
      const data = JSON.parse(Bookmark);
      const newBookmark = [...data, id];
      localStorage.setItem(name, JSON.stringify(newBookmark));
      toast.success("Add To Bookmark Successfully.");
      return newBookmark;
    } else {
      localStorage.setItem(name, JSON.stringify([id]));
      toast.success("Add To Bookmark Successfully.");
      return [id];
    }
  } if (method === "Remove") {
    const newBookmark = JSON.parse(localStorage.getItem(name)!).filter((item: number) => item !== id);
    localStorage.setItem(name, JSON.stringify(newBookmark));
    toast.error("Remove From Bookmark Successfully.")
    return newBookmark;
  }
}

export const useUserStore = create((set) => ({
  search: "",
  setSearch: (value: string) => set(() => ({ search: value })),
  isMobile: window.innerWidth >= 780 ? false : true,
  setIsMobile: (value: boolean) => set(() => ({ isMobile: value })),
  showSideBar: false,
  setShowSideBar: (value: boolean) => set(() => ({ showSideBar: value })),
  showStory: false,
  setShowStory: (value: boolean) => set(() => ({ showStory: value })),

  postBookmark: JSON.parse(localStorage.getItem('post_bookmark') || "[]"),
  addPostInBookmark: (id: number) => set(() => ({ postBookmark: setBookmark(id, 'post_bookmark', "Add") })),
  removePostFromBookmark: (id: number) => set(() => ({ postBookmark: setBookmark(id, 'post_bookmark', "Remove") })),

  categoryBookmark: JSON.parse(localStorage.getItem('category_bookmark') || "[]"),
  addCategoryInBookmark: (id: number) => set(() => ({ postBookmark: setBookmark(id, 'category_bookmark', "Add") })),
  removeCategoryFromBookmark: (id: number) => set(() => ({ postBookmark: setBookmark(id, 'category_bookmark', "Remove") })),

  sybCategoryBookmark: JSON.parse(localStorage.getItem('sub_category_bookmark') || "[]"),
  addSubCategoryInBookmark: (id: number) => set(() => ({ postBookmark: setBookmark(id, 'sub_category_bookmark', "Add") })),
  removeSubCategoryFromBookmark: (id: number) => set(() => ({ postBookmark: setBookmark(id, 'sub_category_bookmark', "Remove") })),

}));

export interface homeDataType {
  categories: {
    categories: CategoryType[],
    current_page: number,
    limit: number,
    total_records: number,
    total_page: number,
  },
  editor_pick: postType[],
  from_the_blog: {
    current_page: number,
    total_records: number,
    from_the_blog: postType[],
    total_page: number
  },
  hot_news: { heading: string, slug: string },
  latest_posts: {
    current_page: number,
    total_records: number,
    latest_posts: postType[],
    total_page: number
  },
  most_read: { posts: postType }[],
  quick_link: { id: number, name: string, link: string }[],
  random_category: {
    color: string,
    id: number,
    name: string,
    posts: postType[],
    slug: string
  },
  random_sub_category: {
    posts: postType[],
    sub_category: {
      category: { slug: string, name: string, color: string },
      slug: string,
      name: string
    },
    total_page: number,
    color: string,
    name: string,
    id: number,
    slug: string
  },
  top_post: postType[],
  watch_to_watch: {
    heading: string,
    created_at: string,
    slug: string,
    video_link: string,
    short_description: string,
    category: {
      slug: string,
      name: string,
      color: string
    }
  }[]
}
export const useUserHomeData = create((set) => ({
  homeData: {},
  setHomeData: (value: homeDataType) => set(() => ({ homeData: value }))
}));

export interface navbarDataType {
  logo: {
    name: string,
    slug: string,
    type: string,
    url?: string
  },
  menu: {
    category: {
      slug: string,
      name: string,
      image: string,
      subcategory: {
        slug: string,
        image: string,
        name: string
      }[]
    }, name: string, slug: string, type: string
  }[]
}
export const useUserNavbarData = create((set) => ({
  navbarData: {},
  setNavbarData: (value: homeDataType) => set(() => ({ navbarData: value }))
}));

export default useGlobalLoader;