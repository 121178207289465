import React, { useEffect, useState, lazy, Suspense } from 'react';
import { BsFire } from "react-icons/bs";
import { Container } from 'react-bootstrap';
import StayUpToDate from './StayUpToDate';
import MetaTags from '../../../Components/MetaTags';
import { useQuery } from 'react-query';
import { UserHomeApi } from '../../../Api/User';
import { useNavigate } from 'react-router-dom';
import { useUserHomeData } from '../../../Store/GlobalState';
import { homeDataType } from '../../../Store/GlobalState';
const Main = lazy(() => import('../../Components/Main'));
const EditorsPick = lazy(() => import('./EditorsPick'));
const MostRead = lazy(() => import('./MostRead'));
const Category = lazy(() => import('./Category'));
const LatestPost = lazy(() => import('./LatestPost'));
const WhatToWatch = lazy(() => import('./WhatToWatch'));
const CategoryTopPost = lazy(() => import('./CategoryTopPost'));
const SubCategoryPost = lazy(() => import('./SubCategoryPost'));
const FromTheBlog = lazy(() => import('./FromTheBlog'));


export interface postType {
    slug: string,
    heading: string,
    image: string,
    category: {
        slug: string,
        name: string,
        color: string
    },
    created_at: string,
    description: string,
    short_description: string,
    meta_description: string,
    meta_title: string,
    meta_keyword: string,
    meta_link: string,
    subcategory: {
        slug: string,
        name: string,
    },
}

const HomeData = [
    {
        component: (topPost: postType[]) => (
            <Container>
                <div className='row'>
                    <div className='col-12 mb-2'>
                        <Suspense fallback={<></>}>
                            <Main topPost={topPost} />
                        </Suspense>
                    </div>
                    <div className='col-12 my-2'>
                        <Suspense fallback={<></>}>
                            <EditorsPick />
                        </Suspense>
                    </div>
                </div>
            </Container>)
    },
    {
        component: () => (<div className='w-100' style={{ backgroundColor: 'var(--primary-blue)' }}>
            <Container className='pb-4'>
                <Suspense fallback={<></>}>
                    <MostRead />
                </Suspense>
            </Container>
        </div>)
    },
    {
        component: () => (<div className='w-100' style={{ backgroundColor: 'var(--primary-gray)' }}>
            <Container className='pb-4'>
                <Suspense fallback={<></>}>
                    <Category />
                </Suspense>
            </Container>
        </div>)
    },
    {
        component: () => (<Container>
            <div className='row'>
                <div className='col-12'>
                    <Suspense fallback={<></>}>
                        <LatestPost />
                    </Suspense>
                </div>
            </div>
        </Container>)
    },
    {
        component: () => (<div className='w-100' style={{ backgroundColor: 'var(--primary-blue)' }}>
            <Container>
                <Suspense fallback={<></>}>
                    <WhatToWatch />
                </Suspense>
            </Container>
        </div>)
    },
    {
        component: () => (<Container>
            <div className='row'>
                <div className='col-12 mb-2'>
                    <Suspense fallback={<></>}>
                        <CategoryTopPost />
                    </Suspense>
                </div>
            </div>
        </Container>)
    },
    {
        component: () => (<Container>
            <Suspense fallback={<></>}>
                <SubCategoryPost />
            </Suspense>
        </Container>)
    },
    {
        component: () => (<Container>
            <StayUpToDate />
        </Container>)
    },
    {
        component: () => (
            <Container>
                <Suspense fallback={<></>}>
                    <FromTheBlog />
                </Suspense>
            </Container>)
    },
]

export default function UserHome() {
    const [show, setShow] = useState<number>(0);
    const [homeData, setHomeData]: [homeDataType, (value: homeDataType) => void] = useUserHomeData((state: any) => [state.homeData, state.setHomeData]);
    const navigate = useNavigate();

    const { refetch } = useQuery({
        queryKey: ['all-in-one-home-api'],
        queryFn: UserHomeApi,
        onSuccess: (resp) => setHomeData(resp?.data),
        enabled: false
    });

    // useQuery({
    //     queryKey: ['hot-news'],
    //     queryFn: HotNewsApi,
    //     onSuccess: (resp) => setHotNews(resp?.data?.latest_post),
    // });

    // useQuery({
    //     queryKey: ['Quick-Links'],
    //     queryFn: QuickLinksApi,
    //     onSuccess: (resp) => setQuickLinks(resp?.data?.quickLinks)
    // });

    useEffect(() => {
        refetch()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function handleScroll() {
        if (window.innerHeight + document.documentElement.scrollTop >= document.documentElement.offsetHeight - 100) setShow(show + 1);
    }

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [show]);
    return (
        <div>
            <MetaTags title='Blog Store' link='http://localhost:3000/' description='Latest Football news are here.' keyWord='Blog, Football, News, Articles, Latest, Popular' />
            <div className='d-none d-lg-block' style={{ backgroundColor: 'var(--primary-gray)' }}>
                <Container>
                    <div className='mb-2 row'>
                        <div className='mt-2 col-lg-8 col-12 d-flex '>
                            <label className='mr-2  shadow-sm bg-white p-1' style={{ fontSize: '14px', borderRadius: '4px', minWidth: 'fit-content' }}><BsFire color='red' /> Hot News</label>
                            <label className='p-1 cursor-pointer hot-news' onClick={() => navigate(`/post/view/${homeData?.hot_news?.slug}`)} style={{ fontSize: '14px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{homeData?.hot_news?.heading}</label>
                        </div>
                        <div className='mt-2 col-lg-4 col-12 d-flex'>
                            <label className='mr-2 p-1' style={{ fontSize: '14px', color: 'var(--dark-gray)' }}>Quick Links</label>
                            {homeData?.quick_link && homeData?.quick_link.map((item: any, i: number) => <label onClick={() => window.open(item?.link)} key={i} className='mr-2  shadow-sm quick-links p-1 cursor-pointer' style={{ fontSize: '14px', borderRadius: '4px', backgroundColor: 'var(--gray)' }}>{item?.name}</label>)}
                        </div>
                    </div>
                </Container>
            </div>

            {HomeData.map((item, i) => {
                if (show >= i) return (
                    <React.Fragment key={i}>
                        {item?.component(homeData?.top_post!)}
                    </React.Fragment>
                )
                return (<React.Fragment key={i}></React.Fragment>)
            })}
        </div>
    );
}