import React from 'react';
import { FormateDate } from '../../Helper';
import BookmarkIcon from './BookmarkIcon';

interface propsType {
    data: {
        image: string,
        title: string,
        description: string,
        date: string,
        category?: string,
        categoryColor?: string,
        slug: string
    },
    order?: number,
    onClick?: () => void
}

export default function PostListView3({ data, order = 0, onClick }: propsType) {
    return (
        <div className='row w-100 h-100 p-0 m-0'>
            <div className={`col-lg-5 col-12 h-50 ${order === 1 ? "" : "order-1 order-lg-2"}`}>
                <div className='w-100 h-100 py-2' style={{ maxHeight: '250px', minHeight: '200px', position: 'relative', color: 'white' }}>
                    <img loading='lazy' className='w-100 h-100' style={{ borderRadius: '4px', objectFit: 'cover', }} src={data.image} alt='' />
                    <div className='px-2 mx-2 my-3' style={{ position: 'absolute', bottom: '0px', left: '-10px', backgroundColor: data?.categoryColor, transform: 'skew(-25deg)', width: 'fit-content', display: 'inline-block' }}>
                        <p className='mb-0' style={{ transform: 'skew(25deg', fontSize: '18px' }}>{data.category}</p>
                    </div>
                </div>
            </div>
            <div className={`col-lg-7 col-12 pt-lg-3 ${order === 1 ? "" : "order-2 order-lg-1"}`} style={{ minHeight: '200px' }}>
                <div className='d-flex flex-column text-break'>
                    <h3 className='cursor-pointer blog-titel ' style={{ fontSize: '24px' }} onClick={onClick}>
                        <strong>
                            {data.title.slice(0, 75) +
                                (data.title?.length > 75 ? "..." : "")}
                        </strong>
                    </h3>
                    <p style={{ color: 'var(--decription-color)' }}>
                        {data.description.slice(0, 100) +
                            (data.description?.length > 100 ? "..." : "")}
                    </p>
                    <div className=' d-flex justify-content-between flex-wrap'>
                        <p className='description-text' style={{ fontSize: '12px' }}>{FormateDate(data.date)}</p>
                        <BookmarkIcon slug={data?.slug} />
                    </div>
                </div>
            </div>
        </div>
    );
}
