import React, { useEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { GetImageSizeApi, UpdateImageApi } from '../../../Api/Admin';
import { toast } from 'sonner';
import useGlobalLoader from '../../../Store/GlobalState';

export default function MediaSettings() {

  const [imageSize, setImageSize] = useState<{ id: number, name: string, height: number, width: number }[]>();
  const setIsLoading = useGlobalLoader((state: any) => state.setIsLoading);
  const { refetch } = useQuery({
    queryKey: ['get-images-size'],
    queryFn: GetImageSizeApi,
    onSuccess: (resp) => {
      setIsLoading(false);
      setImageSize(resp?.data?.image_height_width);
    },
    enabled: false
  });

  const { mutate } = useMutation(UpdateImageApi, {
    mutationKey: 'update-image-size',
    onSuccess: (data: any) => {
      setIsLoading(false);
      toast.success(data.data.message);
    },
    onError: (data: any) => {
      setIsLoading(false);
      toast.error(data.response.data.message);
    },
  });

  useEffect(() => {
    setIsLoading(true)
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="content-wrapper">
      <section className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col">
              <h1>Media Setting</h1>
            </div>
          </div>
        </div>
      </section>
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">

                  {/* Image Sizes */}
                  <div className='mb-3'>
                    <div className='d-flex justify-content-between flex-wrap'>
                      <p><span className='font-weight-bold'>Image Sizes </span> {"(Size will be in pixels)"}</p>
                    </div>
                    <div className='row mt-1 mb-3'>
                      <div className='col-lg-6 col-12'>
                        {imageSize && imageSize[0] &&
                          imageSize.map((_, i) =>
                            <div className='row mb-3' key={i}>
                              <div className='col-lg-4 col-12'>
                                <label htmlFor={`name-${i + 1}`} className='ml-2'>{i === 0 && "Thumbnail"}{i === 1 && "Medium"}{i === 2 && "Large"} Size</label>
                              </div>
                              <div className='col-lg-8 col-12'>
                                <div className='row '>
                                  <div className='col-md-3 col-12'>
                                    <p className='mb-0 ml-2'>Width</p>
                                  </div>
                                  <div className='col-md-9 col-12'>
                                    <div className="form-group my-1 ">
                                      <input
                                        type="number"
                                        defaultValue={`size-${i + 1}`}
                                        value={imageSize[i].width}
                                        onChange={(e) => setImageSize([...imageSize.slice(0, i), { ...imageSize[i], width: Number(e.target.value) }, ...imageSize.slice(i + 1)])}
                                        className="form-control"
                                        id={`width-${i}`}
                                        placeholder="width"
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className='row '>
                                  <div className='col-md-3 col-12'>
                                    <p className='mb-0 ml-2'>Height</p>
                                  </div>
                                  <div className='col-md-9 col-12'>
                                    <div className="form-group my-1 ">
                                      <input
                                        type="number"
                                        value={imageSize[i].height}
                                        onChange={(e) => setImageSize([...imageSize.slice(0, i), { ...imageSize[i], height: Number(e.target.value) }, ...imageSize.slice(i + 1)])}
                                        className="form-control"
                                        id={`height-${i}`}
                                        placeholder="height"
                                      />
                                    </div>
                                  </div>
                                </div>

                              </div>
                            </div>
                          )
                        }
                      </div>
                    </div>
                    <div className="my-2">
                      <button type="submit" className="btn btn-primary" onClick={() => mutate(imageSize!)}>
                        Save Changes
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
