import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from 'sonner';
import useGlobalLoader from "../../../Store/GlobalState";
import { categoryDataType } from "./Category";
import { CategoryViewApi } from "../../../Api/Admin";
import ImageModal from "../../../Components/ImageModal";

export default function CategoryView() {
    const navigate = useNavigate();
    const setIsLoading = useGlobalLoader((state: any) => state.setIsLoading)
    const [categoryData, setCategoryData] = useState<categoryDataType>();
    const { slug } = useParams();
    const [showImage, setShowImage] = useState(false);
    const [image, setImage] = useState("");

    const { isFetching, refetch } = useQuery({
        queryKey: ["category-view"],
        queryFn: () => CategoryViewApi(slug!),
        onSuccess: (resp) => {
            setCategoryData(resp.data.category);
        },
        onError: (data: any) => {
            toast.error(data.response.data.message)
        },
        enabled: false
    });
    useEffect(() => {
        setIsLoading(isFetching);
        // eslint-disable-next-line
    }, [isFetching]);
    useEffect(() => {
        refetch();
        // eslint-disable-next-line
    }, [])
    return (
        <div className="content-wrapper">
            <ImageModal show={showImage} setShow={setShowImage} image={image} />
            <section className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col">
                            <h1>View Category</h1>
                        </div>
                        <div className="col">
                            <ol className="breadcrumb float-right">
                                <li className="breadcrumb-item">
                                    <button
                                        className="btn btn-info"
                                        onClick={() => navigate("/admin/category/list")}
                                    >
                                        <i className="fa fa-arrow-left mx-1" aria-hidden="true"></i>
                                        Back
                                    </button>
                                </li>
                            </ol>
                        </div>
                    </div>
                </div>
            </section>
            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="table-responsive">
                                    <div className="card-body">
                                        <table id="example2" className="table table-bordered">
                                            {
                                                categoryData ?
                                                    <tbody>
                                                        <tr>
                                                            <td className="col-sm-2 font-weight-bold">Name</td>
                                                            <td>{categoryData?.name}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="col-sm-2 font-weight-bold">Description</td>
                                                            <td>{categoryData?.description}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="col-sm-2 font-weight-bold">Status</td>
                                                            <td>{categoryData?.status === 1 ? "Active" : "Deactive"}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="col-sm-2 font-weight-bold">Color</td>
                                                            <td><div style={{ backgroundColor: categoryData?.color, width: '50px', height: '50px' }} /></td>
                                                        </tr>
                                                        <tr>
                                                            <td className="col-sm-2 font-weight-bold">Image</td>
                                                            <td>
                                                                <div className='showImages mr-3 my-3'>
                                                                    <img style={{ width: "100%", height: "100%" }} className="cursor-pointer" src={categoryData?.image} alt="Product img" onClick={() => { setImage(categoryData?.image); setShowImage(true); }} />
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                    : null
                                            }
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}
