import BookMark from '../User/Page/BookMark';
import UserHome from '../User/Page/Home';
import PageView from '../User/Page/PageView';
import UserPostList from '../User/Page/PostList';
import UserPostView from '../User/Page/PostView';
import UserStoryList from '../User/Page/StoryList';
import PostCategoryList from '../User/Page/postCategoryList';

const UserRoute = [
    { path: "", element: <UserHome /> },
    { path: "post/list", element: <UserPostList /> },
    { path: "post/list/category", element: <PostCategoryList /> },
    { path: "post/view/:slug", element: <UserPostView /> },
    { path: "story/list", element: <UserStoryList /> },
    { path: "bookmark", element: <BookMark /> },
    { path: "page/:slug", element: <PageView /> },
];

export default UserRoute;