import { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { BiCategoryAlt, BiSolidDashboard, BiSolidCategory } from "react-icons/bi";
import { FaLock } from "react-icons/fa";
import { RiArticleLine, RiPagesLine } from "react-icons/ri";
import { MdPermMedia, MdWebStories } from "react-icons/md";
import { IoSettings } from "react-icons/io5";
import { PiLinkSimpleBold } from "react-icons/pi";
import { FaFileCircleCheck } from "react-icons/fa6";
import { TfiMenuAlt } from "react-icons/tfi";

const AdminSidebar = [
    {
        name: "Dashboard",
        link: "/admin/dashboard",
        icon: <BiSolidDashboard className="nav-icon" />,
        active: "dashboard",
    },
    {
        name: "Category",
        link: "/admin/category/list",
        icon: <BiCategoryAlt className="nav-icon" />,
        active: "category",
    },
    {
        name: "Sub-Category",
        link: "/admin/sub-category/list",
        icon: <BiSolidCategory className="nav-icon" />,
        active: "sub-category",
    },
    {
        name: "Post",
        link: "/admin/post/list",
        icon: <RiArticleLine className="nav-icon" />,
        active: "post",
    },
    {
        name: "Stroy",
        link: "/admin/story/list",
        icon: <MdWebStories className="nav-icon" />,
        active: "story",
    },
    {
        name: "Media",
        link: "/admin/media/list",
        icon: <MdPermMedia className="nav-icon" />,
        active: "media",
    },
    {
        name: "Pages",
        link: "/admin/pages/list",
        icon: <RiPagesLine className="nav-icon" />,
        active: "pages",
    },
    {
        name: "Editor's Pick",
        link: "/admin/editor-pick",
        icon: <FaFileCircleCheck className="nav-icon" />,
        active: "editor-pick",
    },
    {
        name: "Change Password",
        link: "/admin/change-password",
        icon: <FaLock className="nav-icon" />,
        active: "change-password",
    },
    {
        name: "Settings",
        link: "/admin/settings",
        icon: <IoSettings className="nav-icon" />,
        active: "settings",
        menuItem: (style: any) => <ul style={style} className="nav nav-treeview mx-3">
            <li className="nav-item">
                <Link to="/admin/settings/media-settings" className="nav-link">
                    <MdPermMedia className="nav-icon" />
                    <p>Media</p>
                </Link>
            </li>
            <li className="nav-item">
                <Link to="/admin/settings/top-menu" className="nav-link">
                    <TfiMenuAlt  className="nav-icon" />
                    <p>Top Menu</p>
                </Link>
            </li>
            <li className="nav-item">
                <Link to="/admin/settings/quick-links" className="nav-link">
                    <PiLinkSimpleBold className="nav-icon" />
                    <p>Quick Links</p>
                </Link>
            </li>
        </ul>
    },
];

function Sidebar() {
    const location = useLocation();
    const [activeTab, setActiveTab] = useState('');

    useEffect(() => {
        const path = location.pathname.split('/')[2];
        setActiveTab(path);
    }, [location]);

    return (
        <aside className="main-sidebar sidebar-dark-primary elevation-4">
            <Link to="/admin/dashboard" className="brand-link" style={{ textDecoration: 'none' }}>
                <img src="/dist/img/AdminLTELogo.png" alt="AdminLTE Logo" className="brand-image img-circle elevation-3" />
                <span className="brand-text font-weight-light" >Admin Panel</span>
            </Link>
            {/* Sidebar */}
            <div className="sidebar">

                {/* Sidebar Menu */}
                <nav className="mt-2">
                    <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
                        {AdminSidebar.map((item, i) => (
                            <li className={`nav-item Active`} key={i}>
                                {item?.menuItem ?
                                    <>
                                        <div className={`nav-link  ${activeTab === item.active ? "active" : ""}`}>
                                            {item.icon}
                                            <p>
                                                {item.name}
                                                <i className="fas fa-angle-left right" />
                                            </p>
                                        </div>
                                        {item?.menuItem({ display: activeTab ? "block" : "none" })}
                                    </>
                                    :
                                    <Link to={item.link} className={`nav-link  ${activeTab === item.active ? "active" : ""}`}>
                                        {item.icon}
                                        <p>
                                            {item.name}
                                            {item?.menuItem ? <i className="fas fa-angle-left right" /> : null}
                                        </p>
                                    </Link>}
                            </li>
                        ))
                        }
                    </ul>
                </nav>
            </div>
        </aside>
    )
};
export default Sidebar