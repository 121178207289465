import React, { useState, useEffect } from 'react';
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from 'sonner';
import useGlobalLoader from "../../../Store/GlobalState";
import { WebStoryViewApi } from "../../../Api/Admin";
import { storyDataType } from './WebStoryEdit';
import ImageModal from '../../../Components/ImageModal';

export default function WebStoryView() {
    const navigate = useNavigate();
    const setIsLoading = useGlobalLoader((state: any) => state.setIsLoading)
    const [webStoryData, setWebStoryData] = useState<storyDataType>();
    const [preImage, setPreImage] = useState<string>("");
    const [showImageModal, setShowImageModal] = useState<boolean>(false);

    const { slug } = useParams();
    const { isFetching, refetch } = useQuery({
        queryKey: ["webStory-view"],
        queryFn: () => WebStoryViewApi(slug!),
        onSuccess: (resp) => {
            setWebStoryData(resp?.data?.story);
        },
        onError: (data: any) => {
            toast.error(data?.response?.data?.message)
        },
        enabled: false
    });
    useEffect(() => {
        setIsLoading(isFetching);
        // eslint-disable-next-line
    }, [isFetching]);
    useEffect(() => {
        refetch();
        // eslint-disable-next-line
    }, []);
    return (
        <div className="content-wrapper">
            <ImageModal show={showImageModal} setShow={setShowImageModal} image={preImage} />
            <section className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col">
                            <h1>View WebStory</h1>
                        </div>
                        <div className="col">
                            <ol className="breadcrumb float-right">
                                <li className="breadcrumb-item">
                                    <button
                                        className="btn btn-info"
                                        onClick={() => navigate("/admin/story/list")}
                                    >
                                        <i className="fa fa-arrow-left mx-1" aria-hidden="true"></i>
                                        Back
                                    </button>
                                </li>
                            </ol>
                        </div>
                    </div>
                </div>
            </section>
            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="table-responsive">
                                    <div className="card-body">
                                        <table id="example2" className="table table-bordered">
                                            {
                                                webStoryData ?
                                                    <tbody>
                                                        <tr>
                                                            <td className="col-sm-2 font-weight-bold">Name</td>
                                                            <td>{webStoryData?.name}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="col-sm-2 font-weight-bold">Status</td>
                                                            <td>{webStoryData?.status === 1 ? "Active" : "Deactive"}</td>
                                                        </tr>
                                                        <tr>
                                                            <td colSpan={2}>
                                                                <div className='row'>
                                                                    {
                                                                        webStoryData?.story_images?.map((item, i) => <div className='col-lg-4 col-md-6 col-12 mb-2' key={i}>
                                                                            <div className='d-flex flex-column bg-light p-2 justify-content-center h-100' style={{ borderRadius: '8px' }}>
                                                                                <div className='showImages cursor-pointer' style={{ alignSelf: 'center' }} onClick={() => {
                                                                                    setPreImage(item?.image);
                                                                                    setShowImageModal(true);
                                                                                }}>
                                                                                    <img style={{ width: "100%", height: "100%" }} src={item?.image} alt="img" />
                                                                                </div>
                                                                                <p><strong>Description:-</strong> {item?.description ? item?.description : "--"}</p>
                                                                                <p><strong>Link:-</strong> {item?.link ? item?.link : "--"}</p>
                                                                            </div>
                                                                        </div>)
                                                                    }
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                    : null
                                            }
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}
