import React, { useState } from 'react';
import { postType } from './Home';
import { useNavigate } from 'react-router-dom';
import { useQuery } from 'react-query';
import { FormateDate } from '../../Helper';
import { Container } from 'react-bootstrap';
import { CategoryType } from './Home/Category';
import { useUserStore } from '../../Store/GlobalState';
import { FaMinusCircle } from "react-icons/fa";
import { FaCirclePlus } from "react-icons/fa6";
import BookmarkIcon from '../Components/BookmarkIcon';
import { BookmarkApi } from '../../Api/User';

export default function BookMark() {
  const [post, setPost] = useState<postType[]>();
  const [category, setCategory] = useState<CategoryType[]>();
  const navigate = useNavigate();
  const [postBookmark, categoryBookmark, addCategoryInBookmark, removeCategoryFromBookmark] = useUserStore((state: any) => [state.postBookmark, state.categoryBookmark, state.addCategoryInBookmark, state.removeCategoryFromBookmark]);

  useQuery({
    queryKey: ['editors-picks', { post_slug: JSON.stringify(postBookmark), category_slug: JSON.stringify(categoryBookmark) }],
    queryFn: BookmarkApi,
    onSuccess: (resp) => {
      setPost(resp?.data?.post);
      setCategory(resp?.data?.category);
    }
  });


  return (
    <Container>
      <div className='my-4'>
        <div className='px-2 mx-2 mb-3' style={{ borderWidth: '0px 4px', borderColor: 'red', borderStyle: 'solid', transform: 'skew(-25deg)', width: 'fit-content', display: 'inline-block' }}>
          <p className='mb-0' style={{ transform: 'skew(25deg', fontSize: '18px' }}>Your Post</p>
        </div>
        <div className='col-12'>
          <div className='row'>
            {
              post && post.map((item, i) => (
                <div className='col-12 col-lg-3' key={i}>
                  <div className='row w-100 h-100 mx-0'>
                    <div className='col-5 order-2 order-lg-1 col-lg-12 h-75'>
                      <div className='w-100 h-100 py-2' style={{ maxHeight: '200px', minHeight: '100px', position: 'relative', color: 'white' }}>
                        <img className='w-100 h-100' style={{ borderRadius: '4px', objectFit: 'cover', }} src={`${process.env.REACT_APP_POST_URL_SM}/${item?.image}`} alt='' />
                        <div className='px-2 mx-2 my-3 d-none d-lg-block' style={{ position: 'absolute', bottom: '0px', left: '-10px', backgroundColor: item?.category?.color || 'var(--primary-category-bg)', transform: 'skew(-25deg)', width: 'fit-content', display: 'inline-block' }}>
                          <p className='mb-0' style={{ transform: 'skew(25deg', fontSize: '18px' }}>{item?.category?.name}</p>
                        </div>
                      </div>
                    </div>
                    <div className='col-7 col-lg-12  order-1 order-lg-2 align-self-center'>
                      <div className='d-flex flex-column'>
                        <h3 className='cursor-pointer blog-titel text-truncate' style={{ fontSize: '16px' }} onClick={() => navigate(`/post/view/${item?.slug}`)}>
                          <strong>
                            {item?.heading.slice(0, 100) +
                              (item?.heading?.length > 100 ? "..." : "")}
                          </strong>
                        </h3>
                        <div className=' d-flex justify-content-between flex-wrap'>

                          <p className='mb-2 description-text' style={{ fontSize: '12px' }}>{FormateDate(item?.created_at)}</p>
                          <BookmarkIcon slug={item?.slug} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            }
          </div>

        </div>
        <div>
          <div className='px-2 mx-2  mt-4 mb-3' style={{ borderWidth: '0px 4px', borderColor: 'red', borderStyle: 'solid', transform: 'skew(-25deg)', width: 'fit-content', display: 'inline-block' }}>
            <p className='mb-0' style={{ transform: 'skew(25deg', fontSize: '18px' }}>Your Categories</p>
          </div>
          <div className='row'>
            {
              category && category.map((item, i) => {
                if (i >= 4) return (<React.Fragment key={i}></React.Fragment>);
                return (
                  <div className='col-12 col-lg-3 mb-3' key={i}>
                    <div className='row mx-2 h-100 shadow-sm' style={{ backgroundColor: 'var(--gray)', borderRadius: '10px' }}>
                      <div className='col-4 col-lg-12 p-0'>
                        <div className='w-100' style={{ height: '30vw', maxHeight: '250px', position: 'relative', color: 'white' }}>
                          <img className='w-100 h-100' style={{ borderRadius: '10px', objectFit: 'cover', }} src={`${process.env.REACT_APP_CATEGORY_URL_MD}/${item.image}`} alt='' />
                        </div>
                      </div>
                      <div className='col-8 col-lg-12 mt-2 align-self-center d-flex justify-content-between'>
                        <div className='d-flex flex-column'>
                          <h3 className='cursor-pointer blog-titel text-truncate' style={{ fontSize: '16px' }} onClick={() => navigate(`/post/list/category?slug=${item.slug}&type=category&category_name=${item.name}`)}><strong>{item.name}</strong></h3>
                          <label style={{ color: 'var(--dark-gray)' }}>{item?.posts_count} Articles</label>
                        </div>
                        <div>
                          {categoryBookmark?.includes(item?.slug) ? <FaMinusCircle color='red' className='cursor-pointer' size={25} onClick={() => removeCategoryFromBookmark(item?.slug)} /> : <FaCirclePlus className='cursor-pointer' color='green' size={25} onClick={() => addCategoryInBookmark(item?.slug)} />}
                        </div>
                      </div>
                    </div>
                  </div>
                )
              })
            }
          </div>
        </div>
      </div>
    </Container>
  );
}
