import React, { useState } from "react";
import { BiCategoryAlt, BiSolidCategory } from "react-icons/bi";
import { RiArticleLine } from "react-icons/ri";
import { useQuery } from "react-query";
import { AdminDashboardApi } from "../../Api/Admin";
import { MdWebStories } from "react-icons/md";

type dashboardDataType = {
    post_count: number,
    category_count:number,
    sub_category_count:number,
    story_count:number
}

function AdminDashboard() {
    const [dashboardData, setDashboardData] = useState<dashboardDataType>();
    useQuery({
        queryKey: ['admin-dashboard'],
        queryFn: AdminDashboardApi,
        onSuccess: (resp) => {
            setDashboardData(resp.data);
        },
    });

    return (
        <div className="content-wrapper">

            {/* Header */}
            <div className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1 className="m-0">Dashboard</h1>
                        </div>
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-right">
                                <li className="breadcrumb-item">Home</li>
                                <li className="breadcrumb-item active">Dashboard</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>

            {/* content */}
            <section className="content">
                <div className="container-fluid">
                    {/* Small boxes (Stat box) */}
                    <div className="row">
                        <div className="col-lg-3 col-6">
                            {/* small box */}
                            <div className="small-box bg-info">
                                <div className="inner">
                                    <h3>{dashboardData?.post_count || 0}</h3>
                                    <p>Post</p>
                                </div>
                                <div className="icon">
                                    <RiArticleLine />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-6">
                            {/* small box */}
                            <div className="small-box bg-warning">
                                <div className="inner">
                                <h3>{dashboardData?.category_count || 0}</h3>
                                    <p>Categories</p>
                                </div>
                                <div className="icon">
                                <BiCategoryAlt className="" />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-6">
                            {/* small box */}
                            <div className="small-box bg-success">
                                <div className="inner">
                                <h3>{dashboardData?.sub_category_count || 0}</h3>
                                    <p>Sub Categories</p>
                                </div>
                                <div className="icon">
                                <BiSolidCategory className="" />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-6">
                            {/* small box */}
                            <div className="small-box bg-danger">
                                <div className="inner">
                                <h3>{dashboardData?.story_count || 0}</h3>
                                    <p>Stories</p>
                                </div>
                                <div className="icon">
                                <MdWebStories className="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* /.container-fluid */}

            </section>
        </div>
    )
};

export default AdminDashboard;