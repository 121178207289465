import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import Main from '../Components/Main';
import ReactPaginate from 'react-paginate';
import PostListView3 from '../Components/PostListView3';
import Category from '../Components/Category';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { postType } from './Home';
import { CategoryPostList } from '../../Api/User';
import { CategoryType } from './Home/Category';
import useGlobalLoader from '../../Store/GlobalState';
import MetaTags from '../../Components/MetaTags';

export default function PostCategoryList() {
    const [searchParams] = useSearchParams();
    const [postData, setPostData] = useState<{ total_page: number, sub_category: { slug: string, name: string, description: string, image: string }, categories: CategoryType, latest_posts: postType[], top_posts: postType[] }>();
    const navigate = useNavigate();
    const [page, setPage] = useState<number>(1);
    const setIsLoading = useGlobalLoader((state: any) => state.setIsLoading);

    const { refetch } = useQuery({
        queryKey: ['category-post-list', searchParams.get('slug'), searchParams.get('type'), page],
        queryFn: CategoryPostList,
        onSuccess: (resp) => {
            setIsLoading(false);
            setPostData(resp?.data);
            if (resp.data.total_page < page) {
                setPage(1);
            }
        },
        onError: () => setIsLoading(false),
        enabled: false
    });

    useEffect(() => {
        refetch();
        setIsLoading(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, searchParams]);
    return (
        <div>
            {
                searchParams.get('type') === "category" ?
                    <Category data={{ name: postData?.categories?.name || '', description: postData?.categories?.description || '', image: `${process.env.REACT_APP_CATEGORY_URL_SM}/${postData?.categories?.image}` }} subCategory={postData?.categories?.subcategory || []}
                        track={[{ name: "Home", navigate: "/" }, { name: `${postData?.categories?.name}`, navigate: "" }]} />
                    :
                    searchParams.get('type') === "sub_category" &&
                    <Category data={{ name: postData?.sub_category?.name || '', description: postData?.sub_category?.description || '', image: `${process.env.REACT_APP_CATEGORY_URL_SM}/${postData?.sub_category?.image || ""}` }}
                        track={[{ name: "Home", navigate: "/" }, { name: `${postData?.sub_category?.name}`, navigate: "" }]} />
            }
            <Container>
                <Main subMain={false} topPost={postData?.top_posts} />
                <div className='row'>
                    <div className='col-lg-8 col-12' >
                        <div className='row'>
                        {postData?.latest_posts.map((item, i) => <div className='col-12' key={i}>
                            <PostListView3 data={{ slug: item?.slug, date: Date().toString(), image: `${process.env.REACT_APP_POST_URL_SM}/${item?.image}`, title: item?.heading, description: item?.short_description || '', category: item?.category?.name, categoryColor: item?.category?.color }} onClick={() => navigate(`/post/view/${item?.slug}`)} />
                        </div>)}
                        </div>
                    </div>
                </div>
                <div className='my-2 bottom-0'>
                    {postData?.total_page ? <ReactPaginate
                        previousLabel={"<<"}
                        nextLabel={">>"}
                        breakLabel={"..."}
                        pageCount={postData?.total_page}
                        marginPagesDisplayed={1}
                        pageRangeDisplayed={1}
                        forcePage={1}
                        onPageChange={(pageClicked) => {
                            setPage(pageClicked.selected + 1);
                        }}
                        containerClassName={"pagination justify-content-center"}
                        pageClassName={"page-item mx-2"}
                        pageLinkClassName={"page-link"}
                        previousClassName={"page-item"}
                        previousLinkClassName={"page-link"}
                        nextClassName={"page-item"}
                        nextLinkClassName={"page-link"}
                        breakClassName={"page-item"}
                        breakLinkClassName={"page-link"}
                        activeClassName={"active"}
                    /> : null}
                </div>
            </Container>
        </div>
    );
}
