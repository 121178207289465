import React, { useState, useEffect } from 'react';
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from 'sonner';
import useGlobalLoader from "../../../Store/GlobalState";
import { PagesViewApi } from "../../../Api/Admin";
import { pagesDataType } from './pages';

export default function PagesView() {
  const navigate = useNavigate();
  const setIsLoading = useGlobalLoader((state: any) => state.setIsLoading)
  const [pagesData, setPagesData] = useState<pagesDataType>();
  const { slug } = useParams();
  const { isFetching, refetch } = useQuery({
    queryKey: ["pages-view"],
    queryFn: () => PagesViewApi(slug!),
    onSuccess: (resp) => {
      setPagesData(resp.data.page);
    },
    onError: (data: any) => {
      toast.error(data.response.data.message)
    },
    enabled: false
  });
  useEffect(() => {
    setIsLoading(isFetching);
    // eslint-disable-next-line
  }, [isFetching]);
  useEffect(() => {
    refetch();
    // eslint-disable-next-line
  }, [])
  return (
    <div className="content-wrapper">
      <section className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col">
              <h1>View Pages</h1>
            </div>
            <div className="col">
              <ol className="breadcrumb float-right">
                <li className="breadcrumb-item">
                  <button
                    className="btn btn-info"
                    onClick={() => navigate("/admin/pages/list")}
                  >
                    <i className="fa fa-arrow-left mx-1" aria-hidden="true"></i>
                    Back
                  </button>
                </li>
              </ol>
            </div>
          </div>
        </div>
      </section>
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="table-responsive">
                  <div className="card-body">
                    <table id="example2" className="table table-bordered">
                      {
                        pagesData ?
                          <tbody>
                            <tr>
                              <td className="col-sm-2 font-weight-bold">Name</td>
                              <td>{pagesData?.name}</td>
                            </tr>
                            <tr>
                              <td className="col-sm-2 font-weight-bold">Status</td>
                              <td>{pagesData?.status === 1 ? "Active" : "Deactive"}</td>
                            </tr>
                            <tr>
                              <td colSpan={2}>
                                <div>
                                  <p className='font-weight-bold'>Description:-</p>
                                </div>
                                <div className='container'>
                                  <div className='mt-4'>
                                    <div className='d-flex justify-content-center'>
                                      <h1 className='text-danger'>{pagesData?.name}</h1>
                                    </div>
                                    <div className='ck-content text-break' dangerouslySetInnerHTML={{ __html: pagesData?.description }} />
                                  </div>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                          : null
                      }
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
