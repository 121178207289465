
import React, { useEffect, useState } from "react";
import {useNavigate } from "react-router-dom";

const AdminLogged = (props:any) => {
    const navigate = useNavigate();
    const [checkLoggedIn, setCheckLoggedIn] = useState(false);

    const checkUserToken = () => {
        const userToken = localStorage.getItem('admin_token');
        if (userToken) {
            setCheckLoggedIn(false);
            return navigate('/admin/dashboard');
        }else{
            setCheckLoggedIn(true);
            
        }
    }
    useEffect(() => {
        checkUserToken()
          // eslint-disable-next-line react-hooks/exhaustive-deps
        },[]);
    return (
        <>
            {
                checkLoggedIn ? props.children  : null
            }
        
        </>
    );
}
export default AdminLogged;