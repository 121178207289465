import React from 'react';
import { useNavigate } from 'react-router-dom';

interface propsType {
    track: {
        name: string,
        navigate?: string,
        slug?: string
    }[]
}

export default function Track({ track }: propsType) {
    const navigate = useNavigate();
    return (
        <div className='d-flex my-3 w-100 flex-wrap'>
            {track.map((item, i) => <span key={i} className={`mx-1 text-nowrap ${track.length - 1 === i ? "description-text" : "cursor-pointer blog-titel"}`} style={{ overflow: 'hidden', fontSize: '12px', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }} onClick={() => { if (track.length - 1 > i) navigate(`${item?.navigate}`) }}>{item.name} {track.length - 1 > i ? ">>" : ""}</span>)}
        </div>
    );
}
