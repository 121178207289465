import React, { useEffect } from 'react';
import { Outlet } from "react-router-dom";
import Navbar from './Navbar';
import Footer from './Footer';
import { useUserNavbarData, useUserStore } from '../../Store/GlobalState';
import SideBar from './SideBar';
import ScrollToTop from './ScrollTop';
import { useQuery } from 'react-query';
import { UserNavCategoryApi } from '../../Api/User';

export default function UserLayout() {
    const [setIsMobile, showSideBar] = useUserStore((state: any) => [state.setIsMobile, state.showSideBar]);
    const setNavbarData = useUserNavbarData((state: any) => state.setNavbarData);
    const { refetch } = useQuery({
        queryKey: ['user-navbar'],
        queryFn: UserNavCategoryApi,
        onSuccess: (resp) => setNavbarData(resp?.data),
        enabled: false,
    });

    useEffect(() => {
        refetch();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const update = () => {
            if (window.innerWidth >= 780) {
                setIsMobile(false);
            } else {
                setIsMobile(true);
            }
        }
        window.addEventListener('resize', update);
        return () => window.removeEventListener('resize', update);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className='user-app'>
            <ScrollToTop />
            <Navbar />
            {showSideBar ? <SideBar /> : <div style={{ minHeight: 'calc(100vh - 225px)' }}><Outlet /></div>}
            <Footer />
        </div>
    );
}