import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { MdOutlineKeyboardDoubleArrowUp } from "react-icons/md"

const ScrollToTop = () => {
    const { pathname } = useLocation();
    const [showButton, setShowButton] = useState(false);

    const ScrollTop = () => window.scrollTo(0, 0);
    useEffect(() => {
        ScrollTop();
    }, [pathname]);
    useEffect(() => {
        const update = () => {
            if (window.pageYOffset > 300) {
                setShowButton(true);
            } else {
                setShowButton(false);
            }
        }
        window.addEventListener('scroll', update);
        return () => window.removeEventListener('scroll', update);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (<>{showButton && <div className='cursor-pointer' style={{ position: 'fixed', borderRadius: '8px', backgroundColor: 'var(--primary-dark)', zIndex: 9999, bottom: '25px', right: '20px' }} onClick={ScrollTop}>
        <MdOutlineKeyboardDoubleArrowUp style={{ color: 'white', fontSize: '35px' }} />
    </div>}</>)
}

export default ScrollToTop;