import React, { useState, useRef, useEffect } from 'react';
import { useMutation, useQuery } from 'react-query';
import { toast } from 'sonner';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import { FaUserCircle } from 'react-icons/fa';
import { MdOutlineModeEdit } from 'react-icons/md';
import { AiOutlineClose } from 'react-icons/ai';
import useGlobalLoader from '../../Store/GlobalState';
import { ProfileUpdateApi, ProfileViewApi } from '../../Api/Admin';
import { ProfileSchema } from '../../Validation';
import ImageModal from '../../Components/ImageModal';
import { FaUser } from "react-icons/fa";

interface profileDataType {
    username: string,
    email: string,
    number: string,
    image: string,
    name: string,
}

export default function Profile() {
    const [profileData, setProfileData] = useState<profileDataType>({
        username: "",
        email: "",
        number: "",
        image: "",
        name: ""
    });
    const [showImage, setShowImage] = useState<string>("");
    const [showModal, setShowModal] = useState<boolean>(false);
    const navigate = useNavigate();
    const fileInputRef = useRef<HTMLInputElement>(null);
    const [enableEdit, setEnableEdit] = useState<boolean>(false);
    const setIsLoading = useGlobalLoader((state: any) => state.setIsLoading);
    const [newImage, setNewImage] = useState<File | null>();
    const { refetch } = useQuery({
        queryKey: ['profile-view'],
        queryFn: ProfileViewApi,
        onSuccess: (resp) => {
            setProfileData(resp?.data?.profile);
            setIsLoading(false);
        },
        onError: (data: any) => {
            toast.error(data.response.data.message);
            setIsLoading(false);
        },
        enabled: false
    });
    const { mutate } = useMutation(ProfileUpdateApi, {
        mutationKey: "profile-update",
        onSuccess: (data: any) => {
            toast.success(data.data.message);
            setEnableEdit(false);
            refetch();
        },
        onError: (data: any) => {
            toast.error(data.response.data.message);
        }
    })
    const { handleSubmit, handleChange, errors, values, touched, resetForm, setFieldValue } = useFormik({
        initialValues: {
            userName: profileData?.username,
            email: profileData?.email,
            number: profileData?.number,
            image: undefined,
            name: profileData?.name
        },
        validationSchema: ProfileSchema,
        enableReinitialize: true,
        onSubmit: (data) => {
            mutate({
                name: data.name,
                username: data?.userName,
                email: data?.email,
                number: data?.number,
                image: data?.image!,
            });
        },
    });
    useEffect(() => {
        setTimeout(() => {
            refetch();
        }, 500);
        setIsLoading(true)
        // eslint-disable-next-line
    }, []);
    return (
        <div className="content-wrapper">
            <ImageModal show={showModal} setShow={setShowModal} image={showImage} />
            <section className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col">
                            <h1>View Profile</h1>
                        </div>
                        <div className="col">
                            <ol className="breadcrumb float-right">
                                <li className="breadcrumb-item">
                                    <button
                                        className="btn btn-info"
                                        onClick={() => navigate(-1)}
                                    >
                                        <i className="fa fa-arrow-left mx-1" aria-hidden="true"></i>
                                        Back
                                    </button>
                                </li>
                            </ol>
                        </div>
                    </div>
                </div>
            </section>
            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-body">
                                    <div className='float-right'>
                                        <p className='text-primary'
                                            style={{ cursor: 'pointer' }}
                                            onClick={() => { setEnableEdit(enableEdit ? false : true); resetForm(); setNewImage(null) }}>
                                            {enableEdit ?
                                                <span><AiOutlineClose /> Cancel </span>
                                                :
                                                <span><MdOutlineModeEdit /> Edit </span>
                                            }
                                        </p>
                                    </div>
                                    <form onSubmit={handleSubmit}>
                                        <div className="input-group d-flex justify-content-center">
                                            <div className="profile-container cursor-pointer">

                                                {profileData?.image ?
                                                    <img style={{ objectFit: 'cover' }}
                                                        onClick={() => { setShowImage(newImage ? URL.createObjectURL(newImage) : profileData.image); setShowModal(true) }} className=" rounded-circle profile-img"
                                                        src={newImage ? URL.createObjectURL(newImage) : profileData?.image} alt="profile img" />
                                                    :
                                                    <FaUserCircle className="profile-img" />
                                                }
                                                {enableEdit && <MdOutlineModeEdit className="profile-icon" onClick={() => fileInputRef.current?.click()} />}
                                                <input
                                                    ref={fileInputRef}
                                                    onChange={(e: any) => {
                                                        setFieldValue('image', e.target.files[0]);
                                                        setNewImage(e.target.files[0]);
                                                    }}
                                                    type="file"
                                                    multiple={false}
                                                    hidden
                                                    className="custom-file-input" id="image" />
                                            </div>
                                        </div>
                                        <div className="input-group d-flex justify-content-center" >
                                            <div style={{ width: "500px" }}>
                                                <div className="input-group mt-3">
                                                    <input disabled={enableEdit ? false : true}
                                                        type="text"
                                                        className="form-control"
                                                        name="name"
                                                        value={values.name}
                                                        onChange={handleChange}
                                                        placeholder="Enter name"
                                                    />
                                                    <div className="input-group-append">
                                                        <div className="input-group-text">
                                                            <FaUser />
                                                        </div>
                                                    </div>
                                                </div>
                                                {touched.name && errors.name ? (
                                                    <div className="mb-2 mx-1 text-danger">
                                                        {errors.name}
                                                    </div>
                                                ) : null}
                                                <div className="input-group mt-3">
                                                    <input disabled={enableEdit ? false : true}
                                                        type="text"
                                                        className="form-control"
                                                        name="userName"
                                                        value={values.userName}
                                                        onChange={handleChange}
                                                        placeholder="Enter username"
                                                    />
                                                    <div className="input-group-append">
                                                        <div className="input-group-text">
                                                            <FaUser />
                                                        </div>
                                                    </div>
                                                </div>
                                                {touched.userName && errors.userName ? (
                                                    <div className="mb-2 mx-1 text-danger">
                                                        {errors.userName}
                                                    </div>
                                                ) : null}
                                                <div className="input-group mt-3">
                                                    <input disabled={enableEdit ? false : true}
                                                        type="email"
                                                        className="form-control"
                                                        name="email"
                                                        value={values.email}
                                                        onChange={handleChange}
                                                        placeholder="Enter Email"
                                                    />
                                                    <div className="input-group-append">
                                                        <div className="input-group-text">
                                                            <span className="fas fa-envelope" />
                                                        </div>
                                                    </div>
                                                </div>
                                                {touched.email && errors.email ? (
                                                    <div className="mb-2 mx-1 text-danger">
                                                        {errors.email}
                                                    </div>
                                                ) : null}
                                                <div className="input-group mt-3">
                                                    <input disabled={enableEdit ? false : true}
                                                        type="text"
                                                        className="form-control"
                                                        name="number"
                                                        value={values.number}
                                                        onChange={handleChange}
                                                        placeholder="Enter Number"
                                                    />
                                                    <div className="input-group-append">
                                                        <div className="input-group-text">
                                                            <span className="fas fa-phone-square fa-rotate-90" />
                                                        </div>
                                                    </div>
                                                </div>
                                                {touched.number && errors.number ? (
                                                    <div className="mb-2 mx-1 text-danger">
                                                        {errors.number}
                                                    </div>
                                                ) : null}
                                                {enableEdit && <button type="submit" className="btn btn-primary btn-block my-3">
                                                    Save
                                                </button>}
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}
