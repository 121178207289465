import React, { useState, useEffect } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { UserStoryViewApi } from '../../Api/User';
import './../../Style/AmpStory.css'

interface storyView {
  description: string,
  slug: string,
  image: string,
  link: string,
  story_id: number,
}


export default function UserStoryView() {
  const [storyData, setStoryData] = useState<storyView[]>([]);
  const [story, setStory] = useState<string>('');
  const { slug } = useParams();
  const { refetch } = useQuery({
    queryKey: ['user-story-view', slug],
    queryFn: UserStoryViewApi,
    onSuccess: (resp) => {
      setStoryData(resp?.data?.story?.story_images);
    },
  });

  useEffect(() => {
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setStory(`
          <div class='MyStoryView'>
            <amp-story standalone>
              ${storyData.map((item, i) => `
                <amp-story-page auto-advance-after="9s" id="page${i}">
                  <amp-story-grid-layer template="fill">
                    <h1></h1>
                    <amp-img
                      src="${item?.image}"
                      layout="responsive"
                      object-fit="contain"
                      @media="(max-width: 480px)"
                      style="object-fit: cover;"
                    ></amp-img>
                  </amp-story-grid-layer>
                  <amp-story-grid-layer template="vertical" class="bottom description">
                    <p>${item?.description}</p>
                  </amp-story-grid-layer>
                </amp-story-page>
              `)}
            </amp-story>
          </div>
        `);
  }, [storyData]);

  useEffect(() => {
    const script1 = document.createElement("script");
    script1.src = "https://cdn.ampproject.org/v0.js";
    script1.async = true;
    script1.onload = () => { };
    document.body.appendChild(script1);

    const script2 = document.createElement("script");
    script2.src = "https://cdn.ampproject.org/v0/amp-video-0.1.js";
    script2.async = true;
    script2.setAttribute('custom-element', "amp-video");
    script2.onload = () => { };
    document.body.appendChild(script2);

    const script3 = document.createElement("script");
    script3.src = "https://cdn.ampproject.org/v0/amp-story-1.0.js";
    script3.async = true;
    script3.setAttribute('custom-element', "amp-story");
    script3.onload = () => { };
    document.body.appendChild(script3);
  }, []);

  return (
    <div>
      <div dangerouslySetInnerHTML={{ __html: story }} />
    </div>
  );
};
