
import React, { useEffect, useState } from "react";
import { useNavigate, Outlet } from "react-router-dom";

const AdminProtectedRoute = (props: any) => {
    const navigate = useNavigate();
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const checkUserToken = () => {
        const userToken = localStorage.getItem('admin_token');
        if (!userToken || userToken === 'undefined') {
            localStorage.removeItem('admin_token')
            setIsLoggedIn(false);
            return navigate('/admin/login');
        } else {
            setIsLoggedIn(true);
        }
    }
    useEffect(() => {
        checkUserToken()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <>
            {
                isLoggedIn ? <Outlet /> : null
            }

        </>
    );
}

export default AdminProtectedRoute;
