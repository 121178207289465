import Category from "../Admin/Page/Category/Category";
import CategoryAdd from "../Admin/Page/Category/CategoryAdd";
import CategoryEdit from "../Admin/Page/Category/CategoryEdit";
import CategoryView from "../Admin/Page/Category/CategoryView";
import AdminChangePassword from "../Admin/Page/ChangePassword";
import AdminDashboard from "../Admin/Page/Dashboard";
import EditorPicks from "../Admin/Page/EditorPicks";
import Media from "../Admin/Page/Media";
import MediaAdd from "../Admin/Page/Media/Add";
import Pages from "../Admin/Page/Pages/pages";
import PagesAdd from "../Admin/Page/Pages/pagesAdd";
import PagesEdit from "../Admin/Page/Pages/pagesEdit";
import PagesView from "../Admin/Page/Pages/pagesView";
import Post from "../Admin/Page/Post/Post";
import PostAdd from "../Admin/Page/Post/PostAdd";
import PostEdit from "../Admin/Page/Post/PostEdit";
import PostView from "../Admin/Page/Post/PostView";
import Profile from "../Admin/Page/Profile";
import MediaSettings from "../Admin/Page/Settings/Media";
import QuickLinks from "../Admin/Page/Settings/QuickLinks";
import TopMenu from "../Admin/Page/Settings/TopMenu";
import SubCategory from "../Admin/Page/SubCategory/SubCategory";
import SubCategoryAdd from "../Admin/Page/SubCategory/SubCategoryAdd";
import SubCategoryEdit from "../Admin/Page/SubCategory/SubCategoryEdit";
import SubCategoryView from "../Admin/Page/SubCategory/SubCategoryView";
import WebStory from "../Admin/Page/WebStory/WebStory";
import WebStoryAdd from "../Admin/Page/WebStory/WebStoryAdd";
import WebStoryEdit from "../Admin/Page/WebStory/WebStoryEdit";
import WebStoryView from "../Admin/Page/WebStory/WebStoryView";

const AdminRoute = [
    { path: "dashboard", element: <AdminDashboard /> },
    { path: "profile", element: <Profile /> },
    { path: "category/list", element: <Category /> },
    { path: "category/add", element: <CategoryAdd /> },
    { path: "category/edit/:slug", element: <CategoryEdit /> },
    { path: "category/view/:slug", element: <CategoryView /> },
    { path: "sub-category/list", element: <SubCategory /> },
    { path: "sub-category/add", element: <SubCategoryAdd /> },
    { path: "sub-category/edit/:slug", element: <SubCategoryEdit /> },
    { path: "sub-category/view/:slug", element: <SubCategoryView /> },
    { path: "post/list", element: <Post /> },
    { path: "post/add", element: <PostAdd /> },
    { path: "post/edit/:slug", element: <PostEdit /> },
    { path: "post/view/:slug", element: <PostView /> },
    { path: "story/list", element: <WebStory /> },
    { path: "story/add", element: <WebStoryAdd /> },
    { path: "story/edit/:slug", element: <WebStoryEdit /> },
    { path: "story/view/:slug", element: <WebStoryView /> },
    { path: "pages/list", element: <Pages /> },
    { path: "pages/add", element: <PagesAdd /> },
    { path: "pages/edit/:slug", element: <PagesEdit /> },
    { path: "pages/view/:slug", element: <PagesView /> },
    { path: "editor-pick", element: <EditorPicks /> },
    { path: "change-password", element: <AdminChangePassword /> },
    { path: "settings/media-settings", element: <MediaSettings /> },
    { path: "settings/top-menu", element: <TopMenu /> },
    { path: "settings/quick-links", element: <QuickLinks /> },
    { path: "media/list", element: <Media /> },
    { path: "media/add", element: <MediaAdd /> },
];

export default AdminRoute;