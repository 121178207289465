import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { Toaster } from 'sonner';
import { QueryClient, QueryClientProvider } from 'react-query'
import 'react-loading-skeleton/dist/skeleton.css'
import { SkeletonTheme } from 'react-loading-skeleton';
import { HelmetProvider } from 'react-helmet-async';
import Loader from './Components/Loader';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Style/index.css'
import './Style/ck-editor.css'
import './Style/ViewPost.css';

export const queryClient = new QueryClient();
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.Fragment>
    <HelmetProvider>
      <SkeletonTheme baseColor="#d9d9d9" highlightColor="#f2f2f2">
        <QueryClientProvider client={queryClient}>
          <Loader />
          <App />
          <Toaster richColors position="top-center" />
        </QueryClientProvider>
      </SkeletonTheme>
    </HelmetProvider>
  </React.Fragment>
);