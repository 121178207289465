import HttpService from "./UserInterceptors";

export const UserNavCategoryApi = async () => await HttpService.get(`/api/user/category/subcategory`);
export const HotNewsApi = async () => await HttpService.get(`/api/user/hot/news`);
export const QuickLinksApi = async () => await HttpService.get(`/api/user/quick/link`);
export const HomeTopPostApi = async () => await HttpService.get(`/api/user/category/post`);
export const EditorPicksApi = async () => await HttpService.get(`/api/user/editor/pick`);
export const MostReadApi = async () => await HttpService.get(`/api/user/most/read`);
export const DiscoverCategoryListApi = async () => await HttpService.get(`/api/user/discover/category/list`);
export const LatestPostListApi = async ({ queryKey }: any) => await HttpService.get(`/api/user/latest/post/list?order=${queryKey[1]}`);
export const WhatToWatchApi = async () => await HttpService.get('/api/user/watch/video');
export const RandomCategory = async () => await HttpService.get('/api/user/category');
export const RandomSubCategoryApi = async ({ queryKey }: any) => await HttpService.get(`/api/user/sub/category?page=${queryKey[1]}`);
export const CategoryPostList = async ({ queryKey }: any) => await HttpService.get(`/api/user/discover/category/view/${queryKey[1]}?type=${queryKey[2]}`)
export const BookmarkApi = async ({ queryKey }: any) => await HttpService.post('/api/user/bookmark', queryKey[1]);

export const UserCategoryListApi = async ({ queryKey }: any) => {
    // eslint-disable-next-line
    const [_, search, page, limit] = queryKey;
    return await HttpService.get(`/api/user/category/list?search=${search}&page=${page}&limit=${limit ? limit : 10}`)
};


export const UserSubCategoryListApi = async ({ queryKey }: any) => {
    // eslint-disable-next-line
    const [_, search, page, limit, category_slug] = queryKey;
    return await HttpService.get(`/api/user/sub_category/list?search=${search}&page=${page}&limit=${limit ? limit : 10}&category_slug=${category_slug ? category_slug : ""}`)
};

export const UserPostListApi = async ({ queryKey }: any) => {
    // eslint-disable-next-line
    const [_, search, page, limit, category_slug, sub_category_slug] = queryKey;
    return await HttpService.get(`/api/user/latest/post/view?search=${search}&page=${page}&limit=${limit ? limit : 10}&category_slug=${category_slug ? category_slug : ""}&sub_category_slug=${sub_category_slug ? sub_category_slug : ""}`)
};

export const UserPostViewApi = async ({ queryKey }: any) => {
    // eslint-disable-next-line
    const [_, slug] = queryKey;
    return await HttpService.get(`/api/user/post/view/${slug}`);
};

export const UserStoryListApi = async ({ queryKey }: any) => {
    // eslint-disable-next-line
    const [_, search, page, limit, category_slug] = queryKey;
    return await HttpService.get(`/api/user/story/list?search=${search}&page=${page}&limit=${limit ? limit : 10}&category_slug=${category_slug ? category_slug : ""}`)
};

export const UserStoryViewApi = async ({ queryKey }: any) => {
    // eslint-disable-next-line
    const [_, slug] = queryKey;
    return await HttpService.get(`/api/user/story/view/${slug}`);
};

export const UserPagesList = async () => await HttpService.get('/api/user/page/list');

// export const UserHomeApi = async () => await HttpService.get('/api/user/latest/post/list');


//Home Api All in one
export const UserHomeApi = async () => await HttpService.get('/api/user/content');

//Page view
export const UserPageApi = async ({ queryKey }: any) => await HttpService.get(`/api/user/page/view/${queryKey[1]}`) 

//User Signup
export const UserSignup = async(values:{email:string}) => await HttpService.post('/api/user/signup',values);