import React, { useState } from "react";
import ReactPaginate from "react-paginate";
import { useMutation, useQuery } from "react-query";
import { toast } from "sonner";
import Modal from "../../../Components/Modal";
import useGlobalLoader from "../../../Store/GlobalState";
import { MediaDeleteApi, MediaListApi } from "../../../Api/Admin";
import { useNavigate } from "react-router-dom";
import ImageModal from "../../../Components/ImageModal";
import { IoIosCloseCircleOutline } from "react-icons/io";

export interface mediaDataType {
    slug: string,
    media: string,
    type: string,
}

function Media() {
    const [media, setMedia] = useState<{ media_count: number, media: mediaDataType[], total_page: number } | null>();
    const [page, setPage] = useState<number>(1);
    const [showDeleteModal, setshowDeleteModal] = useState<boolean>(false);
    const [deleteId, setDeleteId] = useState<string | undefined>();
    const setIsLoading = useGlobalLoader((state: any) => state.setIsLoading);
    const [showImage, setShowImage] = useState(false);
    const [image, setImage] = useState("");

    const navigate = useNavigate();

    const query = useQuery({
        queryKey: ["media-list", "", page, 10],
        queryFn: MediaListApi,
        onSuccess: (resp) => {
            setIsLoading(false);
            setMedia(resp?.data);
            if (resp.data.total_page < page) {
                setPage(1);
            }
        },
        onError: () => setIsLoading(false),
    });
    const { mutate } = useMutation(MediaDeleteApi, {
        mutationKey: "media-remove",
        onSuccess: (data: any) => {
            setIsLoading(false);
            toast.success(data.data.message);
            query.refetch();
            setshowDeleteModal(false);
        },
        onError: (data: any) => {
            toast.error(data.response.data.message);
            setshowDeleteModal(false);
        },
    });
    return (
        <div className="content-wrapper">
            <Modal
                show={showDeleteModal}
                setShow={setshowDeleteModal}
                handleSuccess={() => {
                    if (deleteId) {
                        mutate(deleteId);
                        setIsLoading(true);
                    }
                }}
                successButton="Delete"
                description="Are you sure you want to delete?"
                header="Media Delete"
                type="danger"
            />
            <ImageModal show={showImage} setShow={setShowImage} image={image}/>
            <section className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col">
                            <h1>Media</h1>
                        </div>
                        <div className="col">
                            <ol className="breadcrumb float-right">
                                <li className="breadcrumb-item">
                                    <button className="btn btn-primary" onClick={() => navigate('/admin/media/add')}>
                                        <i className="fa fa-plus mx-1" aria-hidden="true"></i>
                                        Add
                                    </button>
                                </li>
                            </ol>
                        </div>
                    </div>
                </div>
            </section>
            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                {/* <div className="card-header">
                                    <div className="d-flex justify-content-end ">
                                    </div>
                                </div> */}

                                <div className="card-body">
                                    <div className="d-flex flex-wrap">
                                        {media && media?.media.map((file, i) => {
                                            if (file.type.split("/")[0] === 'video') return (<div className='showImages mr-3 my-3' key={i}>
                                            <IoIosCloseCircleOutline
                                                    color='black'
                                                    className="my-1 removeImageBtn cursor-pointer"
                                                    onClick={() => {setshowDeleteModal(true);setDeleteId(file?.slug)}} />
                                                <video className="w-100 h-100" controls>
                                                    <source className="w-100 h-100" src={file.media} type={file.type} />
                                                </video>
                                            </div>)
                                            if (file.type.split("/")[0] === 'image') return (<div className='showImages mr-3 my-3' key={i}>
                                                <IoIosCloseCircleOutline
                                                        color='black'
                                                        className="my-1 removeImageBtn"
                                                        onClick={() => {setshowDeleteModal(true);setDeleteId(file?.slug)}} />
                                                <img style={{ width: "100%", height: "100%" }} className="cursor-pointer" src={file.media} alt="Product img" onClick={()=>{setImage(file?.media);setShowImage(true);}}/>
                                            </div>)
                                            return (<React.Fragment key={i}></React.Fragment>)
                                        })}
                                    </div>
                                    {query.isFetching ? (
                                        <center>
                                            <i
                                                className=" my-2 fa fa-spinner fa-spin"
                                                style={{ fontSize: "30px", color: "black" }}
                                            ></i>
                                        </center>
                                    ) : null}
                                </div>

                                {media?.total_page ? (
                                    <ReactPaginate
                                        previousLabel={"previous"}
                                        nextLabel={"next"}
                                        breakLabel={"..."}
                                        pageCount={media?.total_page}
                                        marginPagesDisplayed={2}
                                        pageRangeDisplayed={3}
                                        forcePage={page - 1}
                                        onPageChange={(pageClicked) => {
                                            setPage(pageClicked.selected + 1);
                                        }}
                                        containerClassName={"pagination justify-content-center"}
                                        pageClassName={"page-item"}
                                        pageLinkClassName={"page-link"}
                                        previousClassName={"page-item"}
                                        previousLinkClassName={"page-link"}
                                        nextClassName={"page-item"}
                                        nextLinkClassName={"page-link"}
                                        breakClassName={"page-item"}
                                        breakLinkClassName={"page-link"}
                                        activeClassName={"active"}
                                    />
                                ) : null}
                            </div>
                            {/* /.card */}
                        </div>
                        {/* /.col */}
                    </div>
                </div>
                {/* /.container-fluid */}
            </section>
        </div>
    );
}
export default Media;
