import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { UserPostViewApi } from '../../Api/User';
import useGlobalLoader from '../../Store/GlobalState';
import { useNavigate, useParams } from 'react-router-dom';
import { FormateDate } from '../../Helper';
import { Container } from 'react-bootstrap';
import Track from '../Components/Track';
import PostListView4 from '../Components/PostListView4';
import { postType } from './Home';
import MetaTags from '../../Components/MetaTags';

export default function UserPostView() {

  const [post, setPost] = useState<{ post: postType, latest_post: postType[], latest_subCategory: { slug: string, name: string }[] }>();
  const setIsLoading = useGlobalLoader((state: any) => state.setIsLoading);
  const { slug } = useParams();
  const [track, setTrack] = useState([{ name: "Home", navigate: "/", slug: "" }]);
  const navigate = useNavigate();

  const { refetch } = useQuery({
    queryKey: ['user-post-list', slug],
    queryFn: UserPostViewApi,
    onSuccess: (resp) => {
      setIsLoading(false);
      setPost(resp?.data);
      setTrack([{ name: "Home", navigate: "/", slug: 0 }, {
        name: resp?.data?.post?.category?.name,
        slug: resp?.data?.post?.category?.slug,
        navigate: `/post/list/category?slug=${resp?.data?.post?.category?.slug}&type=category&category_name=${resp?.data?.post?.category?.name}`
      },
      {
        name: resp?.data?.post?.subcategory?.name,
        slug: resp?.data?.post?.subcategory?.slug,
        navigate: `/post/list/category?slug=${resp?.data?.post?.subcategory?.slug}&type=sub_category&category_name=${resp?.data?.post?.subcategory?.name}`
      },
      {
        name: resp?.data?.post?.heading,
        slug: resp?.data?.post?.slug,
        navigate: ''
      }])
    },
    onError: () => setIsLoading(false),
    enabled: false
  });

  useEffect(() => {
    setIsLoading(true);
    refetch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slug]);
  return (
    <Container>
      <MetaTags title={post?.post?.meta_title} link={post?.post?.meta_link} description={post?.post?.meta_description} keyWord={post?.post?.meta_keyword} />
      <Track track={track} />

      <div className='px-2 mx-2' style={{ backgroundColor: post?.post?.category?.color || 'green', transform: 'skew(-25deg)', width: 'fit-content', display: 'inline-block' }}>
        <p className='mb-0' style={{ color: 'var(--white-text)', transform: 'skew(25deg', fontSize: '18px' }}>{post?.post?.category?.name}</p>
      </div>
      <div className='my-2'>
        <p className='mb-0 h1 text-break'>{post?.post?.heading}</p>
        <p className='mb-0 text-break' style={{ fontSize: '20px', fontWeight: 'lighter', fontFamily: 'inherit' }}>{post?.post?.short_description}</p>
        <p className='mr-2 mb-0 description-text' style={{ fontSize: '16px' }}>{FormateDate(post?.post?.created_at ? post?.post?.created_at : "") === "Invalid Date" ? "--" : FormateDate(post?.post?.created_at ? post?.post?.created_at : "")}</p>
      </div>

      <div className='row mb-5'>
        <div className='col-lg-8 col-12'>
          <div className='w-100'>
            <img className='w-100' loading='lazy' style={{ width: '80%' }} src={post?.post?.image} alt='' />
          </div>
          <div className='ck-content text-break' dangerouslySetInnerHTML={{ __html: post?.post?.description ? post?.post?.description : "" }} />
        </div>
        <div className='col-lg-4 col-12' style={{ height: 'fit-content', position: 'sticky', top: 0, overflowY: 'auto' }}>
          <div className='px-2 mx-2  mt-4 mb-3' style={{ borderWidth: '0px 4px', borderColor: 'red', borderStyle: 'solid', transform: 'skew(-25deg)', width: 'fit-content', display: 'inline-block' }}>
            <p className='mb-0' style={{ transform: 'skew(25deg', fontSize: '18px' }}>Latest Post</p>
          </div>
          {post ? post?.latest_post.map((item, i) => {
            if (i >= 5) return (<React.Fragment key={i}></React.Fragment>)
            return (<div className='w-100 px-lg-2' key={i}>
              <PostListView4 data={{ slug: item?.slug, image: `${process.env.REACT_APP_POST_URL_SM}/${item?.image}`, title: item.heading, date: item?.created_at }}
                onClick={() => navigate(`/post/view/${item?.slug}`)} />
            </div>)
          }) : null}
          <div className='px-2 mx-2  mt-4 mb-3' style={{ borderWidth: '0px 4px', borderColor: 'red', borderStyle: 'solid', transform: 'skew(-25deg)', width: 'fit-content', display: 'inline-block' }}>
            <p className='mb-0' style={{ transform: 'skew(25deg', fontSize: '18px' }}>Categories</p>
          </div>
          <div className='row'>
            {post ? post?.latest_subCategory?.map((item, i) => (<div className='col-6' key={i}>
              <p className='mb-2 mx-3 cursor-pointer blog-titel' style={{ fontSize: '14px', width: 'fit-content' }} onClick={() => navigate(`/post/list/category?slug=${item?.slug}&type=sub_category`)}>{item?.name}</p>
            </div>)) : null}
          </div>
        </div>
      </div>
    </Container>
  );
}
