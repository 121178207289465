import React from 'react';
import { useUserStore } from '../../Store/GlobalState';
import { FaBookmark, FaRegBookmark } from 'react-icons/fa';

interface BookmarkIconType {
  slug: string
}

export default function BookmarkIcon({ slug }: BookmarkIconType) {
  const [postBookmark, addPostInBookmark, removePostFromBookmark] = useUserStore((state: any) => [state.postBookmark, state.addPostInBookmark, state.removePostFromBookmark]);
  return (
    <div>
      {postBookmark?.includes(slug) ? <FaBookmark className='cursor-pointer' onClick={() => removePostFromBookmark(slug)} /> : <FaRegBookmark className='cursor-pointer' onClick={() => addPostInBookmark(slug)} />}
    </div>
  );
}
