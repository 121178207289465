import React from 'react';
import { Helmet } from 'react-helmet-async';

interface propsType {
    title?: string,
    link?: string,
    description?: string,
    keyWord?: string

}
export default function MetaTags({ title, link, description, keyWord }: propsType) {
    return (
        <Helmet>
            <title>{title}</title>
            <link rel="canonical" href={link} />
            <meta name='description' content={description} />
            <meta name='keywords' content={keyWord} />
        </Helmet>
    );
}
