import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import AdminLogged from './Middleware/AdminLogged';
import AdminLogin from './Admin/Page/Login';
import Home from './Admin/Layout/Home';
import UserLayout from './User/Layout';
import PageNotFound from './Components/PageNotFound';
import AdminProtectedRoute from './Middleware/AdminProtectedRoute';
import UserStoryView from './User/Page/StoryView';
import UserRoute from './Routes/User';
import AdminRoute from './Routes/Admin';

function App() {
  return (
    <BrowserRouter>
      <Routes>

        {/* User */}
        <Route path="/" element={<UserLayout />}>
          {UserRoute.map((item, i) => <Route path={item?.path} element={item?.element} key={i} />)}
        </Route>
        <Route path="story/view/:slug" element={<UserStoryView />} />

        {/* Admin */}
        <Route path="/admin/login" element={<AdminLogged><AdminLogin /></AdminLogged>} />

        <Route path="admin" element={<AdminProtectedRoute />}>
          <Route path="" element={<Home />}>
            {AdminRoute.map((item, i) => <Route path={item?.path} element={item?.element} key={i} />)}
          </Route>
        </Route>

        {/* 404 Page */}
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;