import React from 'react';
import { FormateDate } from '../../Helper';
import BookmarkIcon from './BookmarkIcon';

interface propsType {
    data: {
        image: string,
        title: string,
        description: string,
        date: string,
        category?: string,
        categoryColor?: string,
        slug: string
    },
    onClick?: () => void
}

export default function PostListView2({ data, onClick }: propsType) {
    return (
        <div className='row'>
            <div className='col-5 order-2 order-lg-1 col-lg-12'>
                <div className='w-100 h-100 py-2' style={{ maxHeight: '200px', minHeight: '100px', position: 'relative', color: 'white' }}>
                    <img loading='lazy' className='w-100 h-100' style={{ borderRadius: '4px', objectFit: 'cover', }} src={data.image} alt='' />
                    <div className='px-2 mx-2 my-3 d-none d-lg-block' style={{ position: 'absolute', bottom: '0px', left: '-10px', backgroundColor: data?.categoryColor, transform: 'skew(-25deg)', width: 'fit-content', display: 'inline-block' }}>
                        <p className='mb-0' style={{ transform: 'skew(25deg', fontSize: '18px' }}>{data.category}</p>
                    </div>
                </div>
            </div>
            <div className='col-7 col-lg-12  order-1 order-lg-2 align-self-center'>
                <div className='d-flex flex-column'>
                    <h3 className='cursor-pointer blog-titel  text-break' style={{ fontSize: '16px' }} onClick={onClick}>
                        <strong>
                            {data.title.slice(0, 100) +
                                (data.title?.length > 100 ? "..." : "")}
                        </strong>
                    </h3>
                    <div className=' d-flex justify-content-between flex-wrap'>
                        <p className='description-text' style={{ fontSize: '12px' }}>{FormateDate(data.date)}</p>
                        <BookmarkIcon slug={data?.slug} />
                    </div>
                </div>
            </div>
        </div>
    );
}
