import React from 'react';
import { FormateDate } from '../../Helper';
import BookmarkIcon from './BookmarkIcon';

interface propsType {
    data: {
        image: string,
        title: string,
        description: string,
        date: string,
        category?: string,
        categoryColor?: string
        slug: string
    },
    onClick?: () => void
}
export default function PostListView1({ data, onClick }: propsType) {
    return (
        <div className='h-100 w-100'>
            <div className='w-100 py-2' style={{ height: '65%', position: 'relative', color: 'white' }}>
                <img loading='lazy' className='w-100 h-100' style={{ borderRadius: '4px', objectFit: 'cover' }} src={data.image} alt='' />
                <div className='px-2 mx-2 my-3' style={{ position: 'absolute', bottom: '0px', left: '-10px', backgroundColor: data?.categoryColor, transform: 'skew(-25deg)', width: 'fit-content', display: 'inline-block' }}>
                    <p className='mb-0' style={{ transform: 'skew(25deg', fontSize: '18px' }}>{data.category}</p>
                </div>
            </div>
            <div className='d-flex flex-column'>
                <h3 className='cursor-pointer blog-titel  text-break' style={{ fontSize: '24px' }} onClick={onClick}>
                    <strong>
                        {data.title.slice(0, 100) +
                            (data.title?.length > 100 ? "..." : "")}
                    </strong>
                </h3>
                <p className='d-none d-lg-block' style={{ color: 'var(--decription-color)' }}>
                    {data.description.slice(0, 200) +
                        (data.description?.length > 200 ? "..." : "")}
                </p>

                <div className=' d-flex justify-content-between flex-wrap'>
                    <p className='description-text' style={{ fontSize: '12px' }}>{FormateDate(data.date)}</p>
                    <BookmarkIcon slug={data?.slug} />
                </div>
            </div>
        </div>
    );
}
