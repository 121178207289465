import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import { useNavigate, useParams } from "react-router-dom";
import { useMutation, useQuery } from "react-query";
import { toast } from "sonner";
import { WebStorySchema } from "../../../Validation";
import useGlobalLoader from "../../../Store/GlobalState";
import { WebStoryUpdateApi, WebStoryViewApi } from "../../../Api/Admin";
import StoryInput from "../../../Components/StoryInput";
import { FiPlus } from 'react-icons/fi';
import { IoIosCloseCircleOutline } from "react-icons/io";
import { FileUploader } from "react-drag-drop-files";
import SlugInput from "../../../Components/SlugInput";

export interface storyDataType {
    slug: string,
    name: string,
    status: number,
    story_images: {
        id: number,
        image: string,
        description: string,
        link: string
    }[],
    meta_title: string,
    meta_description: string,
    meta_link: string,
    meta_keyword: string
}

export default function WebStoryEdit() {
    const { slug } = useParams();
    const navigate = useNavigate();
    const setIsLoading = useGlobalLoader((state: any) => state.setIsLoading);
    const [story, setStory] = useState<storyDataType>()
    const [deleteStory, setDeleteStory] = useState<number[]>([]);
    const [image, setImage] = useState<File | null>(null);
    const { refetch } = useQuery({
        queryKey: ["story-view"],
        queryFn: () => WebStoryViewApi(slug!),
        onSuccess: (resp: any) => {
            setIsLoading(false);
            setStory(resp?.data?.story);
        },
        onError: () => {
            setIsLoading(false);
        },
        enabled: false,
    });

    const { mutate } = useMutation(WebStoryUpdateApi, {
        mutationKey: "webStory-add",
        onSuccess: (data: any) => {
            setIsLoading(false);
            toast.success(data.data.message);
            navigate("/admin/story/list");
        },
        onError: (data: any) => {
            setIsLoading(false);
            toast.error(data.response.data.message);
        },
    });
    const { handleSubmit, handleChange, errors, values, touched, setFieldValue } = useFormik({
        initialValues: {
            status: story?.status,
            image: null,
            isImage: false,
            name: story?.name ? story?.name : "",
            story: story?.story_images ? story?.story_images.map(item => ({ isImage: false, image: undefined, description: item?.description || "", link: item?.link || "", id: item?.id })) : [],
            meta_title: story?.meta_title ? story?.meta_title : "",
            meta_description: story?.meta_description ? story?.meta_description : "",
            meta_keyword: story?.meta_keyword ? story?.meta_keyword : "",
            meta_link: story?.meta_link ? story?.meta_link : "",
            slug: story?.slug
        },
        enableReinitialize: true,
        validationSchema: WebStorySchema,
        onSubmit: (data) => {
            setIsLoading(true);
            mutate({
                image: data.image!,
                status: data.status!,
                slug: slug,
                newSlug: data?.slug,
                name: data.name,
                story: data.story,
                story_image_delete: deleteStory,
                meta_title: data.meta_title,
                meta_description: data.meta_description,
                meta_keyword: data.meta_keyword,
                meta_link: data.meta_link
            });
        },
    });

    const handleAdd = () => {
        setFieldValue('story', [...values.story!, { image: undefined, description: "", link: "" }]);
    }

    useEffect(() => {
        setIsLoading(true);
        refetch();
        // eslint-disable-next-line
    }, []);

    return (
        <div className="content-wrapper">
            <section className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col">
                            <h1>Edit WebStory</h1>
                        </div>
                        <div className="col">
                            <ol className="breadcrumb float-right">
                                <li className="breadcrumb-item">
                                    <button
                                        className="btn btn-info"
                                        onClick={() => navigate("/admin/story/list")}
                                    >
                                        <i className="fa fa-arrow-left mx-1" aria-hidden="true"></i>
                                        Back
                                    </button>
                                </li>
                            </ol>
                        </div>
                    </div>
                </div>
            </section>
            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <form onSubmit={handleSubmit}>
                                    <div className="card-body">
                                        <div className="row">

                                            {/* WebStory Name */}
                                            <div className="col-lg-6 col-12">
                                                <div className="form-group my-1">
                                                    <label htmlFor="name">Name</label>
                                                    <input
                                                        type="text"
                                                        value={values.name}
                                                        onChange={handleChange}
                                                        className="form-control"
                                                        id="name"
                                                        placeholder="Name"
                                                    />
                                                </div>
                                                {touched.name && errors.name ? (
                                                    <div className="mb-2 mx-1 text-danger">
                                                        {errors.name}
                                                    </div>
                                                ) : null}
                                            </div>

                                            {/* Slug */}
                                            <div className="col-lg-6 col-12">
                                                <SlugInput value={values.slug!} setValue={(e: any) => setFieldValue('slug', e.target.value)} />
                                            </div>

                                            {/* Image */}
                                            <div className="col-lg-6 col-12">
                                                {image ? (
                                                    <div className="showImages">
                                                        <IoIosCloseCircleOutline
                                                            color="black"
                                                            className="my-1 removeImageBtn"
                                                            onClick={() => {
                                                                setFieldValue("image", "");
                                                                setImage(null);
                                                            }}
                                                        />
                                                        <img
                                                            style={{ width: "100%", height: "100%" }}
                                                            src={URL.createObjectURL(image)}
                                                            alt="Category img"
                                                        />
                                                    </div>
                                                ) : (
                                                    <div className="form-group images_drag my-1 w-100">
                                                        <label htmlFor="image">Image</label>
                                                        <FileUploader
                                                            handleChange={(FileList: any) => {
                                                                setFieldValue("image", FileList);
                                                                setImage(FileList);
                                                            }}
                                                            name="image"
                                                            types={["JPG", "PNG", "JPEG"]}
                                                        />
                                                    </div>
                                                )}
                                                {touched.image && errors.image ? (
                                                    <div className="mb-2 mx-1 text-danger">
                                                        {errors.image}
                                                    </div>
                                                ) : null}
                                            </div>

                                            {/* WebStory */}
                                            <div className="col-12">
                                                <div className="form-group my-1">
                                                    <label htmlFor="story">Stroy</label>
                                                    <div className="row">
                                                        {values.story.map((_, i) => (
                                                            <div className="col-12" style={{ padding: '16px' }} key={i}>
                                                                <StoryInput
                                                                    deleteStory={deleteStory}
                                                                    setDeleteStory={setDeleteStory}
                                                                    oldImage={story?.story_images[i]?.image}
                                                                    setValue={setFieldValue}
                                                                    value={values?.story}
                                                                    index={i}
                                                                    valueKey="story"
                                                                    errors={errors.story}
                                                                    touched={touched.story} />
                                                            </div>
                                                        ))}
                                                    </div>
                                                    <button type="button" style={{ minWidth: "50px", minHeight: '40px' }} onClick={handleAdd} className="px-2 btn btn-info  p-0 mb-1"> <FiPlus /> Add story</button>
                                                </div>
                                            </div>

                                            {/* Meta title */}
                                            <div className="col-lg-6 col-12">
                                                <div className="form-group my-1">
                                                    <label htmlFor="meta_title">Meta Title</label>
                                                    <input
                                                        type="text"
                                                        value={values.meta_title}
                                                        onChange={handleChange}
                                                        className="form-control"
                                                        id="meta_title"
                                                        placeholder="Add title"
                                                    />
                                                </div>
                                                {touched.meta_title && errors.meta_title ? (
                                                    <div className="mb-2 mx-1 text-danger">
                                                        {errors.meta_title}
                                                    </div>
                                                ) : null}
                                            </div>

                                            {/* Meta description */}
                                            <div className="col-lg-6 col-12">
                                                <div className="form-group my-1">
                                                    <label htmlFor="meta_description">Meta Description</label>
                                                    <input
                                                        type="text"
                                                        value={values.meta_description}
                                                        onChange={handleChange}
                                                        className="form-control"
                                                        id="meta_description"
                                                        placeholder="Add description"
                                                    />
                                                </div>
                                                {touched.meta_description && errors.meta_description ? (
                                                    <div className="mb-2 mx-1 text-danger">
                                                        {errors.meta_description}
                                                    </div>
                                                ) : null}
                                            </div>


                                            {/* Meta Link */}
                                            <div className="col-lg-6 col-12">
                                                <div className="form-group my-1">
                                                    <label htmlFor="meta_link">Meta Link</label>
                                                    <input
                                                        type="text"
                                                        value={values.meta_link}
                                                        onChange={handleChange}
                                                        className="form-control"
                                                        id="meta_link"
                                                        placeholder="add link"
                                                    />
                                                </div>
                                                {touched.meta_link && errors.meta_link ? (
                                                    <div className="mb-2 mx-1 text-danger">
                                                        {errors.meta_link}
                                                    </div>
                                                ) : null}
                                            </div>


                                            {/* Meta Keyword */}
                                            <div className="col-lg-6 col-12">
                                                <div className="form-group my-1">
                                                    <label htmlFor="meta_keyword">Meta Keyword</label>
                                                    <input
                                                        type="text"
                                                        value={values.meta_keyword}
                                                        onChange={handleChange}
                                                        className="form-control"
                                                        id="meta_keyword"
                                                        placeholder="Add keyword"
                                                    />
                                                </div>
                                                {touched.meta_keyword && errors.meta_keyword ? (
                                                    <div className="mb-2 mx-1 text-danger">
                                                        {errors.meta_keyword}
                                                    </div>
                                                ) : null}
                                            </div>

                                            {/* Status */}
                                            <div className="col-12">
                                                <label htmlFor="status">Status</label>
                                                <div className="form-group d-flex">
                                                    <div className="custom-control custom-radio mr-2">
                                                        <input
                                                            className="custom-control-input"
                                                            checked={values.status === 1 ? true : false}
                                                            onChange={(e) =>
                                                                setFieldValue(
                                                                    "status",
                                                                    e.target.checked ? 1 : 0
                                                                )
                                                            }
                                                            type="radio"
                                                            id="customRadio1"
                                                            name="status"
                                                        />
                                                        <label
                                                            htmlFor="customRadio1"
                                                            className="custom-control-label"
                                                        >
                                                            Active
                                                        </label>
                                                    </div>
                                                    <div className="custom-control custom-radio">
                                                        <input
                                                            className="custom-control-input"
                                                            checked={values.status === 0 ? true : false}
                                                            onChange={(e) =>
                                                                setFieldValue(
                                                                    "status",
                                                                    e.target.checked ? 0 : 1
                                                                )
                                                            }
                                                            type="radio"
                                                            id="customRadio2"
                                                            name="customRadio1"
                                                        />
                                                        <label
                                                            htmlFor="customRadio2"
                                                            className="custom-control-label"
                                                        >
                                                            Deactive
                                                        </label>
                                                    </div>
                                                </div>
                                                {touched.status && errors.status ? (
                                                    <div className="mb-2 mx-1 text-danger">
                                                        {errors.status}
                                                    </div>
                                                ) : null}
                                            </div>
                                        </div>
                                    </div>
                                    {/* /.card-body */}
                                    <div className="card-footer">
                                        <button type="submit" className="btn btn-primary">
                                            Edit WebStory
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}
