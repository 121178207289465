import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useMutation } from "react-query";
import { toast } from "sonner";
import useGlobalLoader from "../../../Store/GlobalState";
import { MediaAddApi } from "../../../Api/Admin";
import { FileUploader } from "react-drag-drop-files";
import { IoIosCloseCircleOutline } from "react-icons/io";

export default function MediaAdd() {
    const setIsLoading = useGlobalLoader((state: any) => state.setIsLoading);
    const [image, setImage] = useState<File[]>([]);
    const navigate = useNavigate();

    const { mutate } = useMutation(MediaAddApi, {
        mutationKey: "media-add",
        onSuccess: (data: any) => {
            toast.success(data.data.message);
            navigate("/admin/media/list");
            setIsLoading(false);
        },
        onError: (data: any) => {
            setIsLoading(false);
            toast.error(data.response.data.message);
        },
    });

    const handleFileChange = (file: any) => {
        const newFile: any = Array.from(file || []);
        setImage([...image, ...newFile]);
    };
    const handleRemoveFile = (index: number) => {
        const newFiles = [...image];
        newFiles.splice(index, 1);
        setImage(newFiles);
    };
    return (
        <div className="content-wrapper">
            <section className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col">
                            <h1>Add Media</h1>
                        </div>
                        <div className="col">
                            <ol className="breadcrumb float-right">
                                <li className="breadcrumb-item">
                                    <button className="btn btn-info" onClick={() => navigate("/admin/media/list")} >
                                        <i className="fa fa-arrow-left mx-1" aria-hidden="true"></i>
                                        Back
                                    </button>
                                </li>
                            </ol>
                        </div>
                    </div>
                </div>
            </section>
            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-body">
                                    <div className="col-lg-6 col-12">
                                        <div className="form-group images_drag my-1 w-100">
                                            <label htmlFor="image">Image</label>
                                            <FileUploader multiple={true} handleChange={handleFileChange} name="image" types={["JPG", "PNG", "JPEG", 'MP4', 'MPEG-4', 'MOV', 'AVI', 'MKV',]} />
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="d-flex flex-wrap">
                                            {image.map((file, i) => {
                                                if (file.type.split("/")[0] === 'video') return (<div className='showImages mr-3 my-3' key={i}>
                                                    <IoIosCloseCircleOutline style={{ zIndex: 999 }} color='black' className="my-1 removeImageBtn" onClick={() => handleRemoveFile(i)} />
                                                    <video className="w-100 h-100" controls>
                                                        <source className="w-100 h-100" src={URL.createObjectURL(file)} type={file.type} />
                                                    </video>
                                                </div>)
                                                if (file.type.split("/")[0] === 'image') return (<div className='showImages mr-3 my-3' key={i}>
                                                    <IoIosCloseCircleOutline
                                                        color='black'
                                                        className="my-1 removeImageBtn"
                                                        onClick={() => handleRemoveFile(i)} />
                                                    <img
                                                        style={{ width: "100%", height: "100%" }}
                                                        src={URL.createObjectURL(file)} alt="Product img" />
                                                </div>)
                                                return (<React.Fragment key={i}></React.Fragment>)
                                            })}
                                        </div>
                                    </div>
                                </div>
                                {/* /.card-body */}
                                <div className="card-footer">
                                    <button type="submit" className="btn btn-primary" onClick={(() => { mutate(image!); setIsLoading(true); })}>
                                        Add Media
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}
