import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import { useNavigate, useParams } from "react-router-dom";
import { useMutation, useQuery } from "react-query";
import { toast } from "sonner";
import { CategorySchema } from "../../../Validation";
import useGlobalLoader from "../../../Store/GlobalState";
import { CategoryUpdateApi, CategoryViewApi } from "../../../Api/Admin";
import { categoryDataType } from "./Category";
import ColorPicker from 'react-pick-color';
import { FileUploader } from "react-drag-drop-files";
import { IoIosCloseCircleOutline } from "react-icons/io";
import SlugInput from "../../../Components/SlugInput";


export default function CategoryEdit() {
    const { slug } = useParams();
    const [category, setCategory] = useState<categoryDataType>();
    const navigate = useNavigate();
    const setIsLoading = useGlobalLoader((state: any) => state.setIsLoading);
    const [image, setImage] = useState<File | null>(null);
    const [color, setColor] = useState('#fff');

    const { refetch } = useQuery({
        queryKey: ["category-view"],
        queryFn: () => CategoryViewApi(slug!),
        onSuccess: (resp) => {
            setIsLoading(false);
            setCategory(resp?.data?.category);
            setColor(resp?.data?.category?.color)
        },
        onError: (err) => {
            setIsLoading(false);
        },
        enabled: false,
    });
    const { mutate } = useMutation(CategoryUpdateApi, {
        mutationKey: "category-add",
        onSuccess: (data: any) => {
            navigate("/admin/category/list");
            toast.success(data.data.message);
            setIsLoading(false);
        },
        onError: (data: any) => {
            setIsLoading(false);
            toast.error(data.response.data.message);
        },
    });
    const { handleSubmit, handleChange, errors, values, touched, setFieldValue } = useFormik({
        initialValues: {
            isImage: false,
            name: category?.name,
            status: category?.status,
            description: category?.description,
            image: category?.image,
            slug: category?.slug,
        },
        enableReinitialize: true,
        validationSchema: CategorySchema,
        onSubmit: (data) => {
            setIsLoading(true);
            mutate({
                slug: slug,
                newSlug: data?.slug,
                name: data.name!,
                status: data.status!,
                image: image,
                description: data.description!,
                color: color
            });
        },
    });


    useEffect(() => {
        setIsLoading(true);
        refetch();
        // eslint-disable-next-line
    }, []);
    return (
        <div className="content-wrapper">
            <section className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col">
                            <h1>Edit Category</h1>
                        </div>
                        <div className="col">
                            <ol className="breadcrumb float-right">
                                <li className="breadcrumb-item">
                                    <button
                                        className="btn btn-info"
                                        onClick={() => navigate("/admin/category/list")}
                                    >
                                        <i className="fa fa-arrow-left mx-1" aria-hidden="true"></i>
                                        Back
                                    </button>
                                </li>
                            </ol>
                        </div>
                    </div>
                </div>
            </section>
            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <form onSubmit={handleSubmit}>
                                    <div className="card-body">
                                        <div className="row">

                                            {/* Category Name */}
                                            <div className="col-lg-6 col-12">
                                                <div className="form-group my-1">
                                                    <label htmlFor="name">Category Name</label>
                                                    <input
                                                        type="text"
                                                        value={values.name}
                                                        onChange={handleChange}
                                                        className="form-control"
                                                        id="name"
                                                        placeholder="Category name"
                                                    />
                                                </div>
                                                {touched.name && errors.name ? (
                                                    <div className="mb-2 mx-1 text-danger">
                                                        {errors.name}
                                                    </div>
                                                ) : null}
                                            </div>

                                            {/* Slug */}
                                            <div className="col-lg-6 col-12">
                                                <SlugInput value={values.slug!} setValue={(e: any) => setFieldValue('slug', e.target.value)} />
                                            </div>

                                            {/* Category Description */}
                                            <div className="col-lg-6 col-12">
                                                <div className="form-group my-1">
                                                    <label htmlFor="description">Description</label>
                                                    <input
                                                        type="text"
                                                        value={values.description}
                                                        onChange={handleChange}
                                                        className="form-control"
                                                        id="description"
                                                        placeholder="Category description"
                                                    />
                                                </div>
                                                {touched.description && errors.description ? (
                                                    <div className="mb-2 mx-1 text-danger">
                                                        {errors.description}
                                                    </div>
                                                ) : null}
                                            </div>


                                            {/* Color-Picker */}
                                            <div className="col-lg-6 col-12">
                                                <div className="form-group my-1">
                                                    <label htmlFor="description">Select Color</label>
                                                    <ColorPicker color={color} onChange={(color) => setColor(color.hex)} />
                                                </div>
                                            </div>


                                            {/* Image */}
                                            <div className="col-lg-6 col-12">
                                                {image || category?.image ? (
                                                    <div className="showImages">
                                                        <IoIosCloseCircleOutline
                                                            color="black"
                                                            className="my-1 removeImageBtn"
                                                            onClick={() => {
                                                                setFieldValue("image", "");
                                                                setImage(null);
                                                                if (category) setCategory({ ...category, image: "" });
                                                            }}
                                                        />
                                                        <img
                                                            style={{ width: "100%", height: "100%" }}
                                                            src={image ? URL.createObjectURL(image) : category?.image}
                                                            alt="Category img"
                                                        />
                                                    </div>
                                                ) : (
                                                    <div className="form-group images_drag my-1 w-100">
                                                        <label htmlFor="image">Image</label>
                                                        <FileUploader
                                                            handleChange={(FileList: any) => {
                                                                setFieldValue("image", FileList);
                                                                setImage(FileList);
                                                            }}
                                                            name="image"
                                                            types={["JPG", "PNG", "JPEG"]}
                                                        />
                                                    </div>
                                                )}
                                                {touched.image && errors.image ? (
                                                    <div className="mb-2 mx-1 text-danger">
                                                        {errors.image}
                                                    </div>
                                                ) : null}
                                            </div>

                                            {/* Status */}
                                            <div className="col-lg-6 col-12">
                                                <label htmlFor="status">Status</label>
                                                <div className="form-group d-flex">
                                                    <div className="custom-control custom-radio mr-2">
                                                        <input
                                                            className="custom-control-input"
                                                            checked={values.status === 1 ? true : false}
                                                            onChange={(e) =>
                                                                setFieldValue(
                                                                    "status",
                                                                    e.target.checked ? 1 : 0
                                                                )
                                                            }
                                                            type="radio"
                                                            id="customRadio1"
                                                            name="status"
                                                        />
                                                        <label
                                                            htmlFor="customRadio1"
                                                            className="custom-control-label"
                                                        >
                                                            Active
                                                        </label>
                                                    </div>
                                                    <div className="custom-control custom-radio">
                                                        <input
                                                            className="custom-control-input"
                                                            checked={values.status === 0 ? true : false}
                                                            onChange={(e) =>
                                                                setFieldValue(
                                                                    "status",
                                                                    e.target.checked ? 0 : 1
                                                                )
                                                            }
                                                            type="radio"
                                                            id="customRadio2"
                                                            name="customRadio1"
                                                        />
                                                        <label
                                                            htmlFor="customRadio2"
                                                            className="custom-control-label"
                                                        >
                                                            Deactive
                                                        </label>
                                                    </div>
                                                </div>
                                                {touched.status && errors.status ? (
                                                    <div className="mb-2 mx-1 text-danger">
                                                        {errors.status}
                                                    </div>
                                                ) : null}
                                            </div>

                                        </div>
                                    </div>
                                    {/* /.card-body */}
                                    <div className="card-footer">
                                        <button type="submit" className="btn btn-primary">
                                            Edit Category
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}
