import React from 'react';
import Track from './Track';
import { Container } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

interface propsType {
    data: {
        name: string,
        description: string,
        image: string
    },
    subCategory?: { slug: string, name: string }[],
    track?: { name: string, navigate: string }[]
}

export default function Category({ data, subCategory, track }: propsType) {
    const navigate = useNavigate();
    return (
        <div style={{ backgroundColor: 'var(--gray)', minHeight: '200px' }}>
            <Container>
                <div className='row p-lg-5 p-2 pb-5'>
                    <div className='col-lg-8 col-md-6 col-12 align-self-center'>
                        <Track track={track || []} />
                        <p className='mb-0 first-letter-capital' style={{ fontSize: '30px', color: 'var(--primary-dark)' }}><strong>{data.name}</strong></p>
                        <p>
                            {data.description.slice(0, 150) +
                                (data.description?.length > 150 ? "..." : "")}
                        </p>
                        <div className='d-flex flex-wrap'>
                            {subCategory && subCategory[0] ?
                                <>
                                    <p className='mb-0 mx-1' style={{ color: 'var(--primary-dark)' }}>Find More:</p>
                                    {subCategory?.map((item, i) => (<p className='mx-1 cursor-pointer blog-titel' key={i} style={{ color: 'var(--dark-gray)', fontSize: '18px' }} onClick={() => navigate(`/post/list/category?slug=${item.slug}&type=sub_category`)}><strong>{item?.name}</strong></p>))}
                                </>
                                : null}
                        </div>
                    </div>
                    <div className='col-lg-4 col-md-6 col-12'>
                        <div className='image-shadow p-2' style={{ height: '240px', maxWidth: '360px', position: 'relative' }}>
                            <img loading='lazy' className="w-100 h-100" src={data.image} alt="" style={{ position: 'absolute', top: 0, left: 0, borderRadius: "16px", boxShadow: '10px 20px 20px 2px rgba(0, 0, 0, 0.2)', zIndex: 999 }} />
                            <img loading='lazy' className="w-100 h-100" src={data.image} alt="" style={{ borderRadius: "16px", position: "absolute", top: "25px", left: "25px", opacity: 0.5, maxHeight: '240px', maxWidth: '360px', zIndex: 0 }} />
                        </div>

                    </div>
                </div>
            </Container>
        </div>
    );
}
