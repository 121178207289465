import React from 'react';
import { useNavigate } from 'react-router-dom';
import { navbarDataType, useUserNavbarData, useUserStore } from '../../Store/GlobalState';

export default function SideBar() {
    const navigate = useNavigate();
    const setShowSideBar = useUserStore((state: any) => state.setShowSideBar);
    const navbarData: navbarDataType = useUserNavbarData((state: any) => state.navbarData);

    return (
        <div className='My-SideBar bg-white' style={{ minHeight: 'calc(100vh - 225px)', animation: 'opacity-up 0.5s ease-in' }}>
            <div className='p-2'>
                <div className='my-3'>
                    <label className='font-weight-bold w-100' style={{ borderBottom: '1px solid var(--gray)', color: 'var(--dark-gray)' }}>Categories</label>
                    <div className='m-2'>
                        <div className='row'>
                            {navbarData?.menu && navbarData?.menu.map((item, i) => <div className='col-6' onClick={() => {
                                navigate(`post/list/category?type=category&slug=${item?.slug}`);
                                setShowSideBar(false);
                            }}><p>{item?.name}</p></div>)}
                        </div>
                    </div>
                </div>
                <div className='my-3'>
                    <label className='font-weight-bold w-100' style={{ borderBottom: '1px solid var(--gray)', color: 'var(--dark-gray)' }}>Story</label>
                    <div className='m-2'>
                        <div className='row'>
                            <div className='col-6'><p onClick={() => {
                                navigate('story/list');
                                setShowSideBar(false);
                            }}>Stories</p></div>
                        </div>
                    </div>
                </div>
                <div className='my-3'>
                    <label className='font-weight-bold w-100' style={{ borderBottom: '1px solid var(--gray)', color: 'var(--dark-gray)' }}>Bookmarks</label>
                    <div className='m-2'>
                        <div className='row'>
                            <div className='col-6'><p onClick={() => {
                                navigate('bookmark');
                                setShowSideBar(false);
                            }}>My Bookmarks</p></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
