import React, { useState } from "react";
import ReactPaginate from "react-paginate";
import { useMutation, useQuery } from "react-query";
import { AiFillCheckCircle, AiFillCloseCircle, AiFillDelete, AiFillEye } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { toast } from "sonner";
import Modal from "../../../Components/Modal";
import useGlobalLoader from "../../../Store/GlobalState";
import { MdOutlineModeEdit } from 'react-icons/md';
import { PostDeleteApi, PostListApi } from "../../../Api/Admin";

export interface postDataType {
  slug: string,
  image: string,
  heading: string,
  status: number,
  description: string
}

function Post() {
  const [post, setPost] = useState<{ post_count: number, post: postDataType[], total_page: number }>();
  const [page, setPage] = useState<number>(1);
  const [search, setSearch] = useState<string>("");
  const [showDeleteModal, setshowDeleteModal] = useState<boolean>(false);
  const [deleteId, setDeleteId] = useState<string | undefined>();
  const setIsLoading = useGlobalLoader((state: any) => state.setIsLoading);

  const navigate = useNavigate();

  const query = useQuery({
    queryKey: ["post-list", search, page],
    queryFn: PostListApi,
    onSuccess: (resp) => {
      setPost(resp.data);
      if (resp.data.total_page < page) {
        setPage(1);
      }
    },
  });
  const { mutate } = useMutation(PostDeleteApi, {
    mutationKey: "post-remove",
    onSuccess: (data: any) => {
      setIsLoading(false);
      toast.success(data.data.message);
      query.refetch();
      setshowDeleteModal(false);
    },
    onError: (data: any) => {
      setIsLoading(false);
      toast.error(data.response.data.message);
      setshowDeleteModal(false);
    },
  });
  return (
    <div className="content-wrapper">
      <Modal
        show={showDeleteModal}
        setShow={setshowDeleteModal}
        handleSuccess={() => {
          if (deleteId) {
            mutate(deleteId);
            setIsLoading(true);
          }
        }}
        successButton="Delete"
        description="Are you sure you want to delete?"
        header="Post Delete"
        type="danger"
      />
      <section className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col">
              <h1>Post</h1>
            </div>
            <div className="col">
              <ol className="breadcrumb float-right">
                <li className="breadcrumb-item">
                  <button className="btn btn-primary" onClick={() => navigate('/admin/post/add')}>
                    <i className="fa fa-plus mx-1" aria-hidden="true"></i>
                    Add
                  </button>
                </li>
              </ol>
            </div>
          </div>
        </div>
      </section>
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-header">
                  <div className="d-flex justify-content-end ">
                    <div className="d-flex my-2 mx-1">
                      <input
                        placeholder="Search here..."
                        type="search"
                        className="form-control form-control-md"
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                      />
                    </div>
                  </div>
                </div>

                <div className="table-responsive">
                  <div className="card-body">
                    <table
                      id="example2"
                      className="table table-bordered table-hover"
                    >
                      <thead>
                        <tr>
                          <th style={{ width: "100px" }}>Sr no.</th>
                          <th style={{ minWidth: "150px" }}>Heading</th>
                          <th
                            className="text-center"
                            style={{ width: "100px" }}
                          >
                            Status
                          </th>
                          <th
                            className="text-center"
                            style={{ width: "200px" }}
                          >
                            Action
                          </th>
                        </tr>
                      </thead>
                      {post?.post ? (
                        <tbody>
                          {post?.post[0] ? (
                            post?.post.map((item, i) => (
                              <tr key={i}>
                                <td>{i + 1 + (page - 1) * 10}</td>
                                <td className="p-1">{item?.heading}</td>
                                <td className="p-1">
                                  <div className="d-flex justify-content-center ">
                                    {item.status === 1 ? (
                                      <AiFillCheckCircle className="text-success h1" />
                                    ) : (
                                      <AiFillCloseCircle className="text-danger h1" />
                                    )}
                                  </div>
                                </td>
                                <td className="p-1">
                                  <div className="d-flex flex-row justify-content-center" style={{ margin: "0px" }} >
                                      <AiFillEye className="text-info mx-2 cursor-pointer" style={{ margin: "0px", width: "30px", height: "30px", }} onClick={() => navigate(`/admin/post/view/${item.slug}`)}/>
                                      <MdOutlineModeEdit style={{ margin: "0px", width: "30px", height: "30px", }} className="text-primary mx-2 cursor-pointer"  onClick={() => navigate(`/admin/post/edit/${item.slug}`)}/>
                                      <AiFillDelete className="text-danger mx-2 cursor-pointer" style={{ margin: "0px", width: "30px", height: "30px", }} onClick={() => { setshowDeleteModal(true); setDeleteId(item.slug) }}/>
                                  </div>
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colSpan={5}>
                                <div className="d-flex justify-content-center">
                                  <p
                                    style={{
                                      fontSize: "30px",
                                      padding: "0px",
                                      margin: "0px",
                                    }}
                                  >
                                    No Data Found
                                  </p>
                                </div>
                              </td>
                            </tr>
                          )}
                        </tbody>
                      ) : null}
                    </table>
                    {!post?.post ? (
                      <center>
                        <i
                          className=" my-2 fa fa-spinner fa-spin"
                          style={{ fontSize: "30px", color: "black" }}
                        ></i>
                      </center>
                    ) : null}
                  </div>
                </div>

                {post?.total_page ? (
                  <ReactPaginate
                    previousLabel={"previous"}
                    nextLabel={"next"}
                    breakLabel={"..."}
                    pageCount={post?.total_page}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={3}
                    forcePage={page - 1}
                    onPageChange={(pageClicked) => {
                      setPage(pageClicked.selected + 1);
                    }}
                    containerClassName={"pagination justify-content-center"}
                    pageClassName={"page-item"}
                    pageLinkClassName={"page-link"}
                    previousClassName={"page-item"}
                    previousLinkClassName={"page-link"}
                    nextClassName={"page-item"}
                    nextLinkClassName={"page-link"}
                    breakClassName={"page-item"}
                    breakLinkClassName={"page-link"}
                    activeClassName={"active"}
                  />
                ) : null}
              </div>
              {/* /.card */}
            </div>
            {/* /.col */}
          </div>
        </div>
        {/* /.container-fluid */}
      </section>
    </div>
  );
}
export default Post;
