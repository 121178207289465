import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { UserPageApi } from '../../Api/User';
import MetaTags from '../../Components/MetaTags';

interface pagesDataType {
  description: string,
  meta_description: string,
  meta_keyword: string,
  meta_link: string,
  meta_title: string,
  name: string,
  slug: string,
}
export default function PageView() {
  const [data, setData] = useState<pagesDataType>();
  const { slug } = useParams();
  useQuery({
    queryKey: ['get-page', slug],
    queryFn: UserPageApi,
    onSuccess: (resp) => setData(resp?.data?.pages)
  });
  return (
    <div className='container'>
      <MetaTags title={data?.meta_title} description={data?.meta_description} link={data?.meta_link} keyWord={data?.meta_keyword} />
      {data ? <div className='mt-4'>
        <div className='d-flex justify-content-center'>
          <h1 className='text-danger'>{data?.name}</h1>
        </div>
        <div className='ck-content text-break' dangerouslySetInnerHTML={{ __html: data?.description }} />
      </div> : null}
    </div>
  );
}
