import React, { useEffect, useState } from 'react';
import { QuickLinksType } from '../../../Api/Types';
import { useMutation, useQuery } from 'react-query';
import { GetQuickLinks, UpdateQuickLinks } from '../../../Api/Admin';
import useGlobalLoader from '../../../Store/GlobalState';
import { toast } from 'sonner';

export default function QuickLinks() {

    const [links, setLinks] = useState<QuickLinksType[]>();
    const setIsLoading = useGlobalLoader((state: any) => state.setIsLoading);
    const { refetch } = useQuery({
        queryKey: ['get-quick-liks'],
        queryFn: GetQuickLinks,
        onSuccess: (resp) => {
            setIsLoading(false);
            setLinks(resp?.data?.quickLink);
        },
        enabled: false
    });

    const { mutate } = useMutation(UpdateQuickLinks, {
        mutationKey: "Update-QuickLikns",
        onSuccess: (resp) => {
            setIsLoading(false);
            toast.success(resp?.data?.message);
        },
        onError: (err: any) => {
            setIsLoading(false);
            toast.error(err?.response?.data?.message);
        }
    });

    useEffect(() => {
        setIsLoading(true)
        refetch();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="content-wrapper">
            <section className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col">
                            <h1>Quick Links</h1>
                        </div>
                    </div>
                </div>
            </section>
            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-body">

                                    <div className='mb-3'>
                                        <div className='d-flex justify-content-between flex-wrap'>
                                            <p className='font-weight-bold'>Links</p>
                                        </div>
                                        <div className='row mt-1 mb-3'>
                                            <div className='col-lg-6 col-12'>
                                                {links && links.map((item, i) =>
                                                    <div className='row mb-3' key={i}>
                                                        <label htmlFor={`name-${i + 1}`} className='ml-2'>Link - {i + 1}</label>
                                                        <div className='row '>
                                                            <p className='mb-0 ml-2'>Title</p>
                                                            <div className="form-group my-1 ">
                                                                <input
                                                                    type="text"
                                                                    value={item.name}
                                                                    onChange={(e) => setLinks([...links.slice(0, i), { ...item, name: e.target.value }, ...links.slice(i + 1)])}
                                                                    className="form-control"
                                                                    id={`title-${i}`}
                                                                    placeholder="Write Name"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className='row '>
                                                            <p className='mb-0 ml-2'>Link</p>
                                                            <div className="form-group my-1 ">
                                                                <input
                                                                    type="text"
                                                                    value={item.link}
                                                                    onChange={(e) => setLinks([...links.slice(0, i), { ...item, link: e.target.value }, ...links.slice(i + 1)])}
                                                                    className="form-control"
                                                                    id={`links-${i}`}
                                                                    placeholder="Add Link"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                                }
                                            </div>
                                        </div>
                                        <div className="my-2">
                                            <button type="submit" className="btn btn-primary" onClick={() => {
                                                setIsLoading(true);
                                                mutate(links!);
                                            }}>
                                                Save Changes
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}
